/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';
import { GlobalVar } from 'vmf';

class Exam extends Ex {
    constructor() {
        super();
        this.baseUrl += '/exam';
    }

    data({ examName, certId, examStatus, examType, isFilterAuth, isShowHomePage, currentPage, pageSize } = {}) {
        return super.get('/data', {
            examName,
            certId,
            examStatus,
            examType,
            isFilterAuth,
            isShowHomePage,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const examImage = json.data.data.exam.examImage;
            if (!examImage) {
                json.data.data.exam.examImage = [];
            } else {
                json.data.data.exam.examImage = examImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const examListImage = json.data.data.exam.examListImage;
            if (!examListImage) {
                json.data.data.exam.examListImage = [];
            } else {
                json.data.data.exam.examListImage = examListImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const examDetailImage = json.data.data.exam.examDetailImage;
            if (!examDetailImage) {
                json.data.data.exam.examDetailImage = [];
            } else {
                json.data.data.exam.examDetailImage = examDetailImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const exam = json.data.data.exam;
            if (exam.addressLatitude && exam.addressLongitude) {
                exam.latLng = exam.addressLongitude + ',' + exam.addressLatitude;
            }
            return json;
        });
    }

    save({
        id,
        certId,
        paperTemplateId,
        examName,
        examImage,
        examListImage,
        examDetailImage,
        examBeginTime,
        examEndTime,
        applyBeginTime,
        applyEndTime,
        applySuggestTime,
        examTime,
        examNum,
        isMakeUp,
        makeUpLimit,
        makeUpDesc,
        isUseExamStudent,
        isAuthInfo,
        isCourseAuthInfo,
        userIds,
        courseJson,
        totalCourseTime,
        examCourseTime,
        orgMemberCatIds,
        examType,
        examAnswerType,
        totalAmount,
        examApplyDesc,
        examTitle,
        teacherId,
        examMethod,
        latLng,
        isExamCapture,
        examCaptureRate,
        isCourseCapture,
        courseCaptureRate,
        examMinuteTime,
        isCourseQuestion,
        courseQuestionRate,
        companyPaperTemplateJson,
        orderNum,
        registerFormBatchId,
        isShowHomePage,
        isElectronicCertificate,
        isExamOrg,
    } = {}) {
        let addressLongitude = null;
        let addressLatitude = null;
        if (latLng) {
            addressLongitude = latLng.split(',')[0];
            addressLatitude = latLng.split(',')[1];
        }
        const examImageStr = examImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const examListImageStr = examListImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const examDetailImageStr = examDetailImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            certId,
            paperTemplateId,
            examName,
            examImage: examImageStr,
            examListImage: examListImageStr,
            examDetailImage: examDetailImageStr,
            examBeginTime,
            examEndTime,
            applyBeginTime,
            applyEndTime,
            applySuggestTime,
            examTime,
            examNum,
            isMakeUp,
            makeUpLimit,
            makeUpDesc,
            isUseExamStudent,
            isAuthInfo,
            isCourseAuthInfo,
            userIds,
            courseJson,
            totalCourseTime,
            examCourseTime,
            orgMemberCatIds,
            examType,
            examAnswerType,
            totalAmount,
            examApplyDesc,
            examTitle,
            teacherId,
            examMethod,
            addressLongitude,
            addressLatitude,
            isExamCapture,
            examCaptureRate,
            isCourseCapture,
            courseCaptureRate,
            examMinuteTime,
            isCourseQuestion,
            courseQuestionRate,
            companyPaperTemplateJson,
            orderNum,
            registerFormBatchId,
            isShowHomePage,
            isElectronicCertificate,
            isExamOrg,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    sendExamSuggest({ ids } = {}) {
        return super.post('/sendExamSuggest', {
            ids,
        });
    }

    changeExamStatus({ id, examStatus } = {}) {
        return super.post('/changeExamStatus', {
            id,
            examStatus,
        });
    }

    changeIsShowHomePage({ id, isShowHomePage } = {}) {
        return super.post('/changeIsShowHomePage', {
            id,
            isShowHomePage,
        });
    }

    getExamStat({ examId, orgId }) {
        return super.get('/getExamStat', { examId, orgId });
    }

    getExamCompanyStat({ examId }) {
        return super.get('/getExamCompanyStat', { examId });
    }
}

export default new Exam();
export { Exam };
