<template>
    <page back>
        <template slot="body">
            <el-row>
                <el-col :span="24">
                    <el-form
                        ref="addForm"
                        size="small"
                        :model="formModel"
                        :rules="formRules"
                    >
                        <el-card
                            size="small"
                            shadow="never"
                        >
                            <div
                                slot="header"
                                class="clearfix"
                            >
                                <span />
                            </div>
                            <div class="item">
                                <el-col :span="24">
                                    <el-form-item
                                        prop="classDate"
                                        label="开班日期"
                                        label-width="9em"
                                    >
                                        <el-date-picker
                                            v-model="formModel.classDate"
                                            type="date"
                                            placeholder="选择日期时间"
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="registerMaterials"
                                            label="报名材料"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.registerMaterials"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="appointmentNumber"
                                            label="预约考号"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.appointmentNumber"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="applicationDirection"
                                            label="报考方向"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.applicationDirection"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="recommender"
                                            label="推荐人"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.recommender"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="changeShiftName"
                                            label="换人班次名字"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.changeShiftName"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="certLevel"
                                            label="证书等级"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.certLevel"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                            </div>
                        </el-card>
                        <el-card
                            class="ma-t"
                            size="small"
                            shadow="never"
                        >
                            <div
                                slot="header"
                                class="clearfix"
                            >
                                <span />
                            </div>
                            <div class="item">
                                <el-form-item
                                    label="身份证识别:"
                                    label-width="9em"
                                >
                                    <upload
                                        action="/rs/attachment/uploadFileLibraryWithCheck"
                                        :limit="1"
                                        v-model="idCardData"
                                        check-type="idCardType1-back"
                                        return-files
                                        tips="请上传身份证头像面"
                                        @input="uploadIdCard"
                                    />
                                </el-form-item>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="fullName"
                                            label="姓名"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.fullName"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="sex"
                                            label="性别"
                                            label-width="9em"
                                        >
                                            <dictionaries-picker
                                                type="sex"
                                                v-model="formModel.sex"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="education"
                                            label="学历"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.education"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="graduatedSchool"
                                            label="毕业学校"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.graduatedSchool"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="tel"
                                            label="电话号码"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.tel"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="idCardNo"
                                            label="身份证号码"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.idCardNo"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="idCardAddress"
                                            label="身份证地址"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.idCardAddress"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="address"
                                            label="常用地址"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.address"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="companyName"
                                            label="单位名称"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.companyName"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="workRegionName"
                                            label="现就业地市"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.workRegionName"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="workStatus"
                                            label="就业状态"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.workStatus"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="theoryScore"
                                            label="理论考试成绩"
                                            label-width="9em"
                                        >
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                :precision="0"
                                                v-model.number="formModel.theoryScore"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="practicalScore"
                                            label="实操考试成绩"
                                            label-width="9em"
                                        >
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                :precision="0"
                                                v-model.number="formModel.practicalScore"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="isCertificate"
                                            label="是否持证"
                                            label-width="9em"
                                        >
                                            <el-switch
                                                v-model="formModel.isCertificate"
                                                :active-value="true"
                                                :inactive-value="false"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="certSn"
                                            label="证书编号"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.certSn"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="certDate"
                                            label="证书日期"
                                            label-width="9em"
                                        >
                                            <el-date-picker
                                                v-model="formModel.certDate"
                                                type="date"
                                                placeholder="选择日期时间"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="isMakeUp"
                                            label="是否补考"
                                            label-width="9em"
                                        >
                                            <el-switch
                                                v-model="formModel.isMakeUp"
                                                :active-value="true"
                                                :inactive-value="false"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                            </div>
                        </el-card>
                        <el-card
                            class="ma-t"
                            size="small"
                            shadow="never"
                        >
                            <div
                                slot="header"
                                class="clearfix"
                            >
                                <span />
                            </div>
                            <div class="item">
                                <el-col :span="24">
                                    <el-col :span="8">
                                        <el-form-item
                                            prop="avatar"
                                            label="头像"
                                            label-width="9em"
                                        >
                                            <upload
                                                validate-md5
                                                action="/rs/attachment/uploadFileLibrary"
                                                :limit="1"
                                                v-model="formModel.avatar"
                                                tips="请上传头像"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="8">
                                        <el-form-item
                                            prop="idCardFront"
                                            label="身份证正面"
                                            label-width="9em"
                                        >
                                            <upload
                                                validate-md5
                                                action="/rs/attachment/uploadFileLibrary"
                                                :limit="1"
                                                v-model="formModel.idCardFront"
                                                tips="请上传身份证正面（国徽面）"
                                            />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item
                                            prop="idCardBack"
                                            label="身份证反面"
                                            label-width="9em"
                                        >
                                            <upload
                                                validate-md5
                                                action="/rs/attachment/uploadFileLibrary"
                                                :limit="1"
                                                v-model="formModel.idCardBack"
                                                tips="请上传身份证反面（头像面）"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="8">
                                        <el-form-item
                                            prop="commitmentImage"
                                            label="承诺书"
                                            label-width="9em"
                                        >
                                            <upload
                                                validate-md5
                                                action="/rs/attachment/uploadFileLibrary"
                                                :limit="1"
                                                v-model="formModel.commitmentImage"
                                                tips="请上传承诺书"
                                            />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item
                                            prop="employmentImage"
                                            label="在职或待业证明"
                                            label-width="9em"
                                        >
                                            <upload
                                                validate-md5
                                                action="/rs/attachment/uploadFileLibrary"
                                                :limit="1"
                                                v-model="formModel.employmentImage"
                                                tips="请上传在职或待业证明"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="8">
                                        <el-form-item
                                            prop="xuexinImage"
                                            label="学信网图片"
                                            label-width="9em"
                                        >
                                            <upload
                                                validate-md5
                                                action="/rs/attachment/uploadFileLibrary"
                                                :limit="1"
                                                v-model="formModel.xuexinImage"
                                                tips="请上传学信网图片"
                                            />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item
                                            prop="graduationImage"
                                            label="结业证"
                                            label-width="9em"
                                        >
                                            <upload
                                                validate-md5
                                                action="/rs/attachment/uploadFileLibrary"
                                                :limit="1"
                                                v-model="formModel.graduationImage"
                                                tips="请上传结业证"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                            </div>
                        </el-card>
                        <el-card
                            class="ma-t"
                            size="small"
                            shadow="never"
                        >
                            <div
                                slot="header"
                                class="clearfix"
                            >
                                <span />
                            </div>
                            <div class="item">
                                <el-col :span="24">
                                    <el-form-item
                                        prop="amount"
                                        label="金额"
                                        label-width="9em"
                                    >
                                        <el-input-number
                                            controls-position="right"
                                            :min="0"
                                            :precision="2"
                                            v-model.number="formModel.amount"
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="billingMethod"
                                            label="开票方式"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.billingMethod"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="paymentMethod"
                                            label="付款方式"
                                            label-width="9em"
                                        >
                                            <el-input
                                                v-model="formModel.paymentMethod"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                                <el-col :span="24">
                                    <el-col :span="16">
                                        <el-form-item
                                            prop="billingName"
                                            label="开票名称"
                                            label-width="9em"
                                        >
                                            <el-input
                                                type="textarea"
                                                :autosize="{ minRows: 4}"
                                                v-model="formModel.billingName"
                                                auto-complete="off"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-col>
                            </div>
                        </el-card>
                        <el-col :span="24">
                            <el-form-item
                                label-width="13em"
                                class="ma-t"
                            >
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="onConfirm"
                                >
                                    保 存
                                </el-button>
                                <el-button
                                    size="small"
                                    @click="$router.back()"
                                >
                                    返 回
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-col>
            </el-row>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { Utils } from 'vmf';

const { validateForm } = Utils;

export default {
    name: 'RegisterFormEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                registerFormBatchId: '',
                classDate: '',
                fullName: '',
                registerMaterials: '',
                appointmentNumber: '',
                applicationDirection: '',
                sex: '',
                education: '',
                graduatedSchool: '',
                tel: '',
                idCardNo: '',
                idCardAddress: '',
                address: '',
                companyName: '',
                amount: '',
                billingMethod: '',
                paymentMethod: '',
                billingName: '',
                recommender: '',
                isCertificate: false,
                changeShiftName: '',
                workRegionName: '',
                workStatus: '',
                certLevel: '',
                theoryScore: '',
                practicalScore: '',
                isMakeUp: false,
                certSn: '',
                certDate: '',
                avatar: [], // 头像
                idCardFront: [], // 身份证正面
                idCardBack: [], // 身份证反面
                commitmentImage: [], // 承诺书
                employmentImage: [], // 在职或待业证明
                xuexinImage: [], // 学信网图片
                graduationImage: [], // 结业证
            },
            idCardData: [],
            examUserList: [],
            sexDicList: [],
            // 表单校验规则
            formRules: {
                idCardNo: [
                    {
                        required: true,
                        message: '请输入身份证号',
                        trigger: 'blur',
                    },
                    {
                        validator: this.validatorIdCardNo,
                        message: '身份证号不能重复',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    methods: {
        async onConfirm() {
            const { addForm } = this.$refs;
            await validateForm([addForm]);
            this.$api.Ex.RegisterForm.save({
                ...this.formModel,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.$router.back();
            });
        },
        init() {
            this.$api.Ex.RegisterForm.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
        validatorIdCardNo(rule, value, callback) {
            this.$api.Ex.RegisterForm.existIdCardNo({
                formBatchId: this.formModel.registerFormBatchId,
                id: this.formModel.id,
                idCardNo: this.formModel.idCardNo,
            }).then(res => {
                if (res.data.msg) {
                    callback(rule.message);
                } else {
                    callback();
                }
            });
        },
        uploadIdCard() {
            if (this.idCardData && this.idCardData.length !== 0) {
                const { initData } = this.idCardData[0].response.data;
                if (initData) {
                    this.formModel.fullName = initData.idCardName;
                    this.formModel.idCardNo = initData.idCardNo;
                    this.formModel.idCardAddress = initData.idCardAddress;
                    if (initData.idCardSex) {
                        if (this.sexDicList && this.sexDicList.length !== 0) {
                            this.sexDicList.forEach(item => {
                                if (item.name === initData.idCardSex) {
                                    this.formModel.sex = item.id;
                                }
                            });
                        } else {
                            this.formModel.sex = '';
                        }
                    } else {
                        this.formModel.sex = '';
                    }

                    this.formModel.idCardBack = this.idCardData;

                    this.$message({
                        message: '身份证识别成功',
                        type: 'success',
                    });
                }
            }
        },
        initDic4Sex() {
            this.$api.Rs.Dic.entrys({ dicCode: 'sex' }).then(json => {
                const res = json.data;
                this.sexDicList = res.data;
            });
        },
    },
    created() {
        this.initDic4Sex();
        this.formModel.registerFormBatchId = this.$route.params.formBatchId;
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
