<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="planDeliveryTime"
                    label="预约时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.planDeliveryTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="companyName"
                    label="公司名称"
                >
                    <el-input
                        v-model="queryFormModel.companyName"
                        placeholder="请输入公司名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="tractionLicensePlateNumber"
                    label="牵引车牌号"
                >
                    <el-input
                        v-model="queryFormModel.tractionLicensePlateNumber"
                        placeholder="请输入牵引车牌号"
                    />
                </el-form-item>
                <el-form-item
                    prop="trailerLicensePlateNumber"
                    label="挂车车牌号"
                >
                    <el-input
                        v-model="queryFormModel.trailerLicensePlateNumber"
                        placeholder="请输入挂车车牌号"
                    />
                </el-form-item>
                <el-form-item
                    prop="undertakeGoodsType"
                    label="承接货物类型"
                >
                    <dictionaries-picker
                        type="undertakeGoodsType"
                        v-model="queryFormModel.undertakeGoodsType"
                    />
                </el-form-item>
                <el-form-item
                    prop="driverName"
                    label="驾驶员姓名"
                >
                    <el-input
                        v-model="queryFormModel.driverName"
                        placeholder="请输入驾驶员姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="escortrName"
                    label="押运员姓名"
                >
                    <el-input
                        v-model="queryFormModel.escortrName"
                        placeholder="请输入押运员姓名"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        导出
                    </el-button>
                </el-button-group>
            </div>
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    label="预约时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.planDeliveryTime | moment('YYYY-MM-DD') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="companyName"
                    label="公司名称"
                    min-width="100"
                />
                <el-table-column
                    prop="tractionLicensePlateNumber"
                    label="牵引车牌号"
                    min-width="100"
                />
                <el-table-column
                    prop="trailerLicensePlateNumber"
                    label="挂车车牌号"
                    min-width="100"
                />
                <el-table-column
                    prop="undertakeGoodsTypeName"
                    label="承接货物类型"
                    min-width="100"
                />
                <el-table-column
                    prop="driverName"
                    label="驾驶员姓名"
                    min-width="100"
                />
                <el-table-column
                    prop="escortrName"
                    label="押运员姓名"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="60"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/memberReserve/memberReserve/detail/' + scope.row.id)"
                        >
                            查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'MemberReserve',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                planDeliveryTime: [],
                companyName: '',
                tractionLicensePlateNumber: '',
                trailerLicensePlateNumber: '',
                undertakeGoodsType: '',
                driverName: '',
                escortrName: '',
            },
            // 表格数据
            tableData: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.MemberReserve.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        exportExcel() {
            let planDeliveryTimeBegin = null;
            let planDeliveryTimeEnd = null;
            if (this.queryFormModel.planDeliveryTime != null && this.queryFormModel.planDeliveryTime.length === 2) {
                planDeliveryTimeBegin = this.queryFormModel.planDeliveryTime[0];
                planDeliveryTimeEnd = this.queryFormModel.planDeliveryTime[1];
            }
            this.$http.href('/mb/memberReserve/exportExcel', {
                ...this.queryFormModel,
                planDeliveryTimeBegin,
                planDeliveryTimeEnd,
            });
        },
    },
};
</script>

<style lang="scss">
</style>
