/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import Servant from './Servant';
import ServantPaymentConfig from './ServantPaymentConfig';
import ServantDecoration from './ServantDecoration';
import CertCat from './CertCat';
import Cert from './Cert';
import QuestionCat from './QuestionCat';
import Question from './Question';
import CourseCat from './CourseCat';
import Course from './Course';
import ModuleCat from './ModuleCat';
import Teacher from './Teacher';

export default {
    Servant,
    ServantPaymentConfig,
    ServantDecoration,
    CertCat,
    Cert,
    QuestionCat,
    Question,
    CourseCat,
    Course,
    ModuleCat,
    Teacher,
};
