/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import Member from './Member';
import MemberLevel from './MemberLevel';
import GrowthValueRule from './GrowthValueRule';
import PointRule from './PointRule';
import Feedback from './Feedback';
import MemberTemperatureReport from './MemberTemperatureReport';
import MemberReserve from './MemberReserve';
import JobRecruit from './JobRecruit';
import JobWanted from './JobWanted';
import Post from './Post';
import PostComment from './PostComment';

export default {
    Member,
    MemberLevel,
    GrowthValueRule,
    PointRule,
    Feedback,
    MemberTemperatureReport,
    MemberReserve,
    JobRecruit,
    JobWanted,
    Post,
    PostComment,
};
