<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="examScore"
                    label="考试成绩"
                    min-width="100"
                />
                <el-table-column
                    prop="examResultName"
                    label="考试结果"
                    min-width="100"
                />
                <el-table-column
                    label="开始时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.beginTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="完成时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.finishTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="创建位置"
                    min-width="150"
                    prop="addressName"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/ex/examApply/examApply/paperDetail/' + scope.row.id)"
                        >
                            查看试卷
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </page>
</template>

<script>
export default {
    name: 'ExamApplyPaper',
    data() {
        return {
            queryFormModel: {
                examApplyId: '',
            },
            // 表格数据
            tableData: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData() {
            this.$api.Ex.ExamApplyPaper.examApplyPaperList({ ...this.queryFormModel }).then(json => {
                const res = json.data;
                this.tableData = res.data;
            });
        },
    },
    created() {
        this.queryFormModel.examApplyId = this.$route.params.id;
        this.getListData();
    },
};
</script>

<style lang="scss">
</style>
