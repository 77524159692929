<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="courseName"
                    label="课程名称"
                >
                    <el-input
                        v-model="queryFormModel.courseName"
                        placeholder="请输入课程名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="courseCatId"
                    label="课程分类"
                >
                    <CascaderPicker
                        check-strictly
                        :api-class="selectApi"
                        v-model="queryFormModel.courseCatId"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/sv/course/course/edit')"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="courseCatNamePath"
                    label="课程分类"
                    min-width="100"
                />
                <el-table-column
                    prop="courseName"
                    label="课程名称"
                    min-width="100"
                />
                <!--<el-table-column
                    prop="certName"
                    label="证书"
                    min-width="100"
                />-->
                <!--                <el-table-column
                                    prop="courseCatNamePath"
                                    label="课程分类"
                                    min-width="100"
                                />-->
                <el-table-column
                    prop="courseDesc"
                    label="课程描述"
                    min-width="100"
                />
                <el-table-column
                    prop="courseHour"
                    label="课时"
                    min-width="100"
                />
                <el-table-column
                    prop="orderNum"
                    label="排序号"
                    min-width="100"
                />
                <el-table-column
                    label="是否发布"
                    width="70"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.courseStatus"
                            @input="onCourseStatusInput($event,scope)"
                            :active-value="2"
                            :inactive-value="1"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/sv/course/course/edit/' + scope.row.id)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(row.id)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Course',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                courseName: '',
                courseCatId: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            selectApi: this.$api.Sv.CourseCat,
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Sv.Course.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Sv.Course.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onCourseStatusInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Sv.Course.changeCourseStatus({
                id: row.id,
                courseStatus: row.courseStatus,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
            });
        },
    },
};
</script>
