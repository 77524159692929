/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class Feedback extends Mb {
    constructor() {
        super();
        this.baseUrl += '/feedback';
    }

    data({ feedbackType, insertTime, currentPage, pageSize } = {}) {
        let insertBeginTime = null;
        let insertEndTime = null;
        if (insertTime != null && insertTime.length === 2) {
            insertBeginTime = insertTime[0];
            insertEndTime = insertTime[1];
        }
        return super.get('/data', {
            feedbackType,
            insertBeginTime,
            insertEndTime,
            currentPage,
            pageSize,
        });
    }
}

export default new Feedback();
export { Feedback };
