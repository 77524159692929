/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sv } from './Sv';
import { GlobalVar } from '@vmf/shared';

class ModuleCat extends Sv {
    constructor() {
        super();
        this.baseUrl += '/moduleCat';
    }

    data({ pId, name, isAvailable } = {}) {
        return super.get('/data', {
            pId,
            name,
            isAvailable,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const moduleCatImage = json.data.data.moduleCatImage;
            if (!moduleCatImage) {
                json.data.data.moduleCatImage = [];
            } else {
                json.data.data.moduleCatImage = moduleCatImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({ id, parentId, name, moduleCatImage, orderNum, isAvailable, moduleConfig } = {}) {
        const moduleCatImageStr = moduleCatImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            parentId,
            name,
            moduleCatImage: moduleCatImageStr,
            orderNum,
            isAvailable,
            moduleConfig,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    exists({ id, pId, name } = {}) {
        return super.get('/exists', {
            id,
            pId,
            name,
        });
    }

    selectAll() {
        return super.get(
            '/selectAll',
            {},
            {
                isRepeat: true,
            },
        );
    }

    /**
     * select
     * @param pId
     * @param name
     * @param _cancelSource 用于取消请求
     * @returns {AxiosPromise<any>}
     */
    select({ pId, name } = {}, config = {}) {
        return super.get(
            '/select',
            {
                pId,
                name,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }

    loadSelectNode({ id } = {}, config = {}) {
        return super.get(
            '/loadSelectNode',
            {
                id,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }
}

export default new ModuleCat();
export { ModuleCat };
