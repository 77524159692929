/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class MemberReserve extends Mb {
    constructor() {
        super();
        this.baseUrl += '/memberReserve';
    }

    data({
        planDeliveryTime,
        companyName,
        tractionLicensePlateNumber,
        trailerLicensePlateNumber,
        undertakeGoodsType,
        driverName,
        escortrName,
        currentPage,
        pageSize,
    } = {}) {
        let planDeliveryTimeBegin = null;
        let planDeliveryTimeEnd = null;
        if (planDeliveryTime != null && planDeliveryTime.length === 2) {
            planDeliveryTimeBegin = planDeliveryTime[0];
            planDeliveryTimeEnd = planDeliveryTime[1];
        }
        return super.get('/data', {
            companyName,
            tractionLicensePlateNumber,
            trailerLicensePlateNumber,
            undertakeGoodsType,
            driverName,
            escortrName,
            planDeliveryTimeBegin,
            planDeliveryTimeEnd,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }
}

export default new MemberReserve();
export { MemberReserve };
