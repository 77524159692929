/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import User from './User';
import Menu from './Menu';
import Role from './Role';
import Region from './Region';
import Variable from './Variable';
import Dic from './Dic';
import DicEntry from './DicEntry';
import App from './App';
import Org from './Org';
import Express from './Express';
import Console from './Console';
import Employee from './Employee';

export default {
    User,
    Menu,
    Role,
    Region,
    Variable,
    Dic,
    DicEntry,
    App,
    Org,
    Express,
    Console,
    Employee,
};
