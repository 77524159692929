import { Ex } from '@/api/ex/Ex';

class RegisterFormBatch extends Ex {
    constructor() {
        super();
        this.baseUrl += '/registerFormBatch';
    }

    data({ batchName, batchSn, currentPage, pageSize }) {
        return super.get('/data', { batchName, batchSn, currentPage, pageSize });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, batchName, batchSn, certificationAuthority, certValidityPeriod, isAllowSearch, remarks }) {
        return super.post('/save', { id, batchName, batchSn, certificationAuthority, certValidityPeriod, isAllowSearch, remarks });
    }
}
export default new RegisterFormBatch();
export { RegisterFormBatch };
