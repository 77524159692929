<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="batchName"
                    label="批次名称"
                >
                    <el-input
                        v-model="queryFormModel.batchName"
                        placeholder="请输入批次名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="batchSn"
                    label="批次编码"
                >
                    <el-input
                        v-model="queryFormModel.batchSn"
                        placeholder="请输入批次编码"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="batchName"
                    label="发证名称"
                    min-width="100"
                />
                <el-table-column
                    prop="batchSn"
                    label="批次编码"
                    min-width="100"
                />
                <el-table-column
                    prop="certificationAuthority"
                    label="发证单位"
                    min-width="100"
                />
                <el-table-column
                    prop="certValidityPeriod"
                    label="证书有效期（年）"
                    min-width="100"
                />
                <el-table-column
                    prop="isAllowSearch"
                    label="是否允许查询"
                    min-width="100"
                >
                    <template #default="scope">
                        {{ scope.row.isAllowSearch?'是':'否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="备注"
                    min-width="100"
                />
                <el-table-column
                    prop="operatorName"
                    label="创建人名称"
                    min-width="100"
                />
                <el-table-column
                    prop="insertTime"
                    label="创建时间"
                    min-width="100"
                >
                    <template v-slot="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120"
                >
                    <template v-slot="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/ex/registerForm/registerForm/' +
                                scope.row.id)"
                        >
                            登记表
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!--   新增/修改   -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑批次' : '新增批次'"
            center
            width="600px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="batchName"
                    label="发证名称"
                    label-width="9em"
                >
                    <el-input
                        v-model="addDialog.formModel.batchName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="batchSn"
                    label="批次编码"
                    label-width="9em"
                >
                    <el-input
                        v-model="addDialog.formModel.batchSn"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="certificationAuthority"
                    label="发证单位"
                    label-width="9em"
                >
                    <el-input
                        v-model="addDialog.formModel.certificationAuthority"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="certValidityPeriod"
                    label="证书有效期（年）"
                    label-width="9em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        v-model="addDialog.formModel.certValidityPeriod"
                    />
                    <span class="ma-l">0表示长期有效</span>
                </el-form-item>
                <el-form-item
                    prop="isAllowSearch"
                    label="是否允许查询"
                    label-width="9em"
                >
                    <el-radio
                        v-model="addDialog.formModel.isAllowSearch"
                        :label="true"
                    >
                        是
                    </el-radio>
                    <el-radio
                        v-model="addDialog.formModel.isAllowSearch"
                        :label="false"
                    >
                        否
                    </el-radio>
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="备注"
                    label-width="9em"
                >
                    <el-input
                        v-model="addDialog.formModel.remarks"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'RegisterFormBatch',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                batchName: '',
                batchSn: '',
            },
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    batchName: '',
                    batchSn: '',
                    certificationAuthority: '',
                    certValidityPeriod: 0,
                    isAllowSearch: false,
                    remarks: '',
                },
                // 表单校验规则
                formRules: {
                    batchName: {
                        required: true,
                        message: '请输入发证名称',
                        trigger: 'blur',
                    },
                    batchSn: {
                        required: true,
                        message: '请输入批次编码',
                        trigger: 'blur',
                    },
                    certificationAuthority: {
                        required: true,
                        message: '请输入发证单位',
                        trigger: 'blur',
                    },
                },
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.RegisterFormBatch.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /**
         * 新增
         */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { formModel } = this.addDialog;
                const { addForm } = this.$refs;
                addForm.resetFields();
                formModel.id = '';
            });
        },
        /**
         * 编辑
         */
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Ex.RegisterFormBatch.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
            });
            this.addDialog.isVisible = true;
        },
        /**
         * 确定
         */
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            const { formModel } = this.addDialog;
            addForm.validate().then(() => {
                this.$api.Ex.RegisterFormBatch.save({
                    ...formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.addDialog.isVisible = false;
                    this.onQuery(this.pagination);
                });
            });
        },
        /**
         * 取消
         */
        onAddDialogCancel() {
            const { formModel } = this.addDialog;
            const { addForm } = this.$refs;
            addForm.resetFields();
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
    },
};
</script>

<style lang="scss">
</style>
