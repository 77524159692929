/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class ExamCheck extends Ex {
    constructor() {
        super();
        this.baseUrl += '/examCheck';
    }

    data({ examName, certId, currentPage, pageSize } = {}) {
        return super.get('/data', {
            examName,
            certId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, certId, paperTemplateId, examName, orderNum, isAvailable } = {}) {
        return super.post('/save', {
            id,
            certId,
            paperTemplateId,
            examName,
            orderNum,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new ExamCheck();
export { ExamCheck };
