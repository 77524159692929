import { Mb } from '@/api/mb/Mb';

class Post extends Mb {
    constructor() {
        super();
        this.baseUrl += '/post';
    }

    data({ memberName, postCatId, title, isAvailable, approvalStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            memberName,
            postCatId,
            title,
            isAvailable,
            approvalStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', {
            id,
        });
    }

    auditPost({ id, auditStatus, auditRemarks }) {
        return super.post('/auditPost', {
            id,
            auditStatus,
            auditRemarks,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}
export default new Post();
export { Post };
