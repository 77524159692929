/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';
import { GlobalVar } from '@vmf/shared';

class PointGoods extends Gd {
    constructor() {
        super();
        this.baseUrl += '/pointGoods';
    }

    data({ goodsName, goodsSn, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            goodsName,
            goodsSn,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const goodsImage = json.data.data.goodsImage;
            if (!goodsImage) {
                json.data.data.goodsImage = [];
            } else {
                json.data.data.goodsImage = goodsImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const goodsDetailImage = json.data.data.goodsDetailImage;
            if (!goodsDetailImage) {
                json.data.data.goodsDetailImage = [];
            } else {
                json.data.data.goodsDetailImage = goodsDetailImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        id,
        pointGoodsCatId,
        goodsName,
        goodsSn,
        pointNum,
        goodsImage,
        goodsDetailImage,
        stock,
        minPerOrderQuantity,
        maxPerOrderQuantity,
        maxMbOrderQuantity,
        orderNum,
        isAvailable,
    } = {}) {
        const goodsImageStr = goodsImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const goodsDetailImageStr = goodsDetailImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            pointGoodsCatId,
            goodsName,
            goodsSn,
            pointNum,
            goodsImage: goodsImageStr,
            goodsDetailImage: goodsDetailImageStr,
            stock,
            minPerOrderQuantity,
            maxPerOrderQuantity,
            maxMbOrderQuantity,
            orderNum,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable }) {
        return super.post('/status', { id, isAvailable });
    }
}

export default new PointGoods();
export { PointGoods };
