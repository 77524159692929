<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <template slot="body">
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-card>
                    <!--<el-form-item
prop="examType"
label="考试类型"
label-width="10em"
>
<dictionaries-picker
type="examType"
v-model="formModel.examType"
/>
</el-form-item>-->
                    <el-form-item
                        prop="examMethod"
                        label="考试方式"
                        label-width="10em"
                    >
                        <dictionaries-picker
                            type="examMethod"
                            v-model="formModel.examMethod"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="latLng"
                        label="统一考试坐标"
                        label-width="10em"
                        v-if="formModel.examMethod == 2"
                    >
                        <LatLngPicker v-model="formModel.latLng" />
                    </el-form-item>
                    <el-form-item
                        prop="certId"
                        label="证书"
                        label-width="10em"
                    >
                        <RemoteSelect
                            remote="/sv/cert/select"
                            placeholder="请输入"
                            :props="{id:'id',name:'certName'}"
                            v-model="formModel.certId"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="paperTemplateId"
                        label="试卷模板"
                        label-width="10em"
                    >
                        <RemoteSelect
                            remote="/ex/paperTemplate/select"
                            placeholder="请输入"
                            :props="{id:'id',name:'templateName'}"
                            v-model="formModel.paperTemplateId"
                            :remote-params="{certId:formModel.certId,certIdReq:1}"
                            v-if="formModel.certId"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="examName"
                        label="考试名称"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.examName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="examTitle"
                        label="考试描述"
                        label-width="10em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.examTitle"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="examImage"
                        label="考试图标"
                        label-width="10em"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.examImage"
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="examListImage"
                        label="考试列表图"
                        label-width="10em"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.examListImage"
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="examDetailImage"
                        label="考试详情图片"
                        label-width="10em"
                        v-if="+formModel.examType === 2"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="20"
                            v-model="formModel.examDetailImage"
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="examBeginTime"
                        label="考试开始时间"
                        label-width="10em"
                    >
                        <el-date-picker
                            v-model="formModel.examBeginTime"
                            type="datetime"
                            placeholder="选择日期时间"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="examEndTime"
                        label="考试截止时间"
                        label-width="10em"
                    >
                        <el-date-picker
                            v-model="formModel.examEndTime"
                            type="datetime"
                            placeholder="选择日期时间"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="applyBeginTime"
                        label="报名开始时间"
                        label-width="10em"
                    >
                        <el-date-picker
                            v-model="formModel.applyBeginTime"
                            type="datetime"
                            placeholder="选择日期时间"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="applyEndTime"
                        label="报名截止时间"
                        label-width="10em"
                    >
                        <el-date-picker
                            v-model="formModel.applyEndTime"
                            type="datetime"
                            placeholder="选择日期时间"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="examTime"
                        label="考试时长"
                        label-width="10em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="1"
                            v-model.number="formModel.examTime"
                        />
                        分钟
                    </el-form-item>
                    <el-form-item
                        prop="examNum"
                        label="考试次数"
                        label-width="10em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="1"
                            v-model.number="formModel.examNum"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="examAnswerType"
                        label="答题模式"
                        label-width="10em"
                    >
                        <dictionaries-picker
                            type="examAnswerType"
                            v-model="formModel.examAnswerType"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="totalAmount"
                        label="考试费用"
                        label-width="10em"
                        v-if="+formModel.examType === 2"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.totalAmount"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="examApplyDesc"
                        label="报考须知"
                        label-width="10em"
                        v-if="+formModel.examType === 2"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.examApplyDesc"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <!--<el-form-item
prop="isMakeUp"
label="是否允许补考"
label-width="10em"
>
<el-radio
v-model="formModel.isMakeUp"
:label="true"
>
是
</el-radio>
<el-radio
v-model="formModel.isMakeUp"
:label="false"
>
否
</el-radio>
</el-form-item>
<el-form-item
prop="makeUpLimit"
label="补考天数限制"
label-width="10em"
v-if="formModel.isMakeUp"
>
<el-input-number
controls-position="right"
:min="0"
v-model.number="formModel.makeUpLimit"
/>
</el-form-item>
<el-form-item
prop="makeUpDesc"
label="补考描述"
label-width="10em"
v-if="formModel.isMakeUp"
>
<el-input
v-model="formModel.makeUpDesc"
auto-complete="off"
/>
</el-form-item>-->
                    <el-form-item
                        prop="isUseExamStudent"
                        label="是否使用考试名单"
                        label-width="10em"
                    >
                        <el-radio
                            v-model="formModel.isUseExamStudent"
                            :label="true"
                        >
                            是
                        </el-radio>
                        <el-radio
                            v-model="formModel.isUseExamStudent"
                            :label="false"
                        >
                            否
                        </el-radio>
                    </el-form-item>
                    <el-form-item
                        prop="applySuggestTime"
                        label="报名提醒时间"
                        label-width="10em"
                        v-if="formModel.isUseExamStudent"
                    >
                        <el-date-picker
                            v-model="formModel.applySuggestTime"
                            type="datetime"
                            placeholder="选择日期时间"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isAuthInfo"
                        label="是否认证信息"
                        label-width="10em"
                    >
                        <el-radio
                            v-model="formModel.isAuthInfo"
                            :label="true"
                        >
                            是
                        </el-radio>
                        <el-radio
                            v-model="formModel.isAuthInfo"
                            :label="false"
                        >
                            否
                        </el-radio>
                    </el-form-item>
                    <el-form-item
                        prop="isCourseAuthInfo"
                        label="学习是否认证信息"
                        label-width="10em"
                        v-if="formModel.isAuthInfo"
                    >
                        <el-radio
                            v-model="formModel.isCourseAuthInfo"
                            :label="true"
                        >
                            是
                        </el-radio>
                        <el-radio
                            v-model="formModel.isCourseAuthInfo"
                            :label="false"
                        >
                            否
                        </el-radio>
                    </el-form-item>
                    <el-form-item
                        prop="teacherId"
                        label="授课教师"
                        label-width="10em"
                    >
                        <RemoteSelect
                            remote="/sv/teacher/data?isAvailable=1"
                            placeholder="请选择"
                            :props="{id:'id',name:'teacherName'}"
                            v-model="formModel.teacherId"
                            clearable
                        />
                    </el-form-item>
                    <!--<el-form-item
prop="isExamCapture"
label="是否考试抓拍"
label-width="10em"
>
<el-radio
v-model="formModel.isExamCapture"
:label="true"
>
是
</el-radio>
<el-radio
v-model="formModel.isExamCapture"
:label="false"
>
否
</el-radio>
</el-form-item>
<el-form-item
prop="examCaptureRate"
label="考试抓拍频率"
label-width="10em"
v-if="formModel.isExamCapture"
>
<el-input-number
controls-position="right"
:min="1"
v-model.number="formModel.examCaptureRate"
/>
每？分钟抓拍一次
</el-form-item>-->
                    <el-form-item
                        prop="isCourseCapture"
                        label="是否学习抓拍"
                        label-width="10em"
                    >
                        <el-radio
                            v-model="formModel.isCourseCapture"
                            :label="true"
                        >
                            是
                        </el-radio>
                        <el-radio
                            v-model="formModel.isCourseCapture"
                            :label="false"
                        >
                            否
                        </el-radio>
                    </el-form-item>
                    <el-form-item
                        prop="courseCaptureRate"
                        label="学习抓拍频率"
                        label-width="10em"
                        v-if="formModel.isCourseCapture"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="1"
                            v-model.number="formModel.courseCaptureRate"
                        />
                        每？分钟抓拍一次
                    </el-form-item>
                    <el-form-item
                        prop="isCourseQuestion"
                        label="是否学习提问"
                        label-width="10em"
                    >
                        <el-radio
                            v-model="formModel.isCourseQuestion"
                            :label="true"
                        >
                            是
                        </el-radio>
                        <el-radio
                            v-model="formModel.isCourseQuestion"
                            :label="false"
                        >
                            否
                        </el-radio>
                    </el-form-item>
                    <el-form-item
                        prop="courseQuestionRate"
                        label="学习提问频率"
                        label-width="10em"
                        v-if="formModel.isCourseQuestion"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="1"
                            v-model.number="formModel.courseQuestionRate"
                        />
                        每？分钟抓拍一次
                    </el-form-item>
                    <!--                    <el-form-item
                        prop="isShowHomePage"
                        label="是否首页展示"
                        label-width="10em"
                    >
                        <el-radio
                            v-model="formModel.isShowHomePage"
                            :label="true"
                        >
                            是
                        </el-radio>
                        <el-radio
                            v-model="formModel.isShowHomePage"
                            :label="false"
                        >
                            否
                        </el-radio>
                    </el-form-item>-->
                    <el-form-item
                        prop="isElectronicCertificate"
                        label="是否显示电子证书"
                        label-width="10em"
                    >
                        <el-radio
                            v-model="formModel.isElectronicCertificate"
                            :label="true"
                        >
                            是
                        </el-radio>
                        <el-radio
                            v-model="formModel.isElectronicCertificate"
                            :label="false"
                        >
                            否
                        </el-radio>
                    </el-form-item>
                    <el-form-item
                        prop="isExamOrg"
                        label="是否区域采集"
                        label-width="10em"
                    >
                        <el-radio
                            v-model="formModel.isExamOrg"
                            :label="true"
                        >
                            是
                        </el-radio>
                        <el-radio
                            v-model="formModel.isExamOrg"
                            :label="false"
                        >
                            否
                        </el-radio>
                    </el-form-item>
                    <el-form-item
                        prop="registerFormBatchId"
                        label="登记表批次"
                        label-width="10em"
                    >
                        <RemoteSelect
                            remote="/ex/registerFormBatch/data"
                            filterable
                            placeholder="请输入"
                            :props="{id:'id',name:'batchName'}"
                            v-model="formModel.registerFormBatchId"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="orderNum"
                        label="排序号"
                        label-width="10em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="0"
                            v-model.number="formModel.orderNum"
                        />
                    </el-form-item>
                </el-card>
                <!--<el-card class="ma-t">
<el-form-item
label="管理人员分类"
label-width="10em"
>
<el-tree
    ref="tree"
    node-key="id"
    :data="orgMemberCatList"
    :props="defaultProps"
    show-checkbox
/>
</el-form-item>
</el-card>-->
                <el-card class="ma-t">
                    <el-form-item
                        label="课程列表"
                        label-width="10em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onAddCourseCat"
                        >
                            添加分类
                        </el-button>
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="examCourseList"
                            style="width: 100%"
                            class="ma-t"
                            default-expand-all
                        >
                            <el-table-column type="expand">
                                <template slot-scope="scope">
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="onAddSelectCourse(scope.row)"
                                    >
                                        添加课程
                                    </el-button>
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="scope.row.courseList"
                                        style="width: 100%"
                                        class="ma-t"
                                    >
                                        <el-table-column
                                            prop="courseCatNamePath"
                                            label="课程分类"
                                            min-width="100"
                                        />
                                        <el-table-column
                                            prop="courseName"
                                            label="课程名称"
                                            min-width="100"
                                        />
                                        <el-table-column
                                            prop="courseDesc"
                                            label="课程描述"
                                            min-width="100"
                                        />
                                        <el-table-column
                                            prop="courseHour"
                                            label="课时"
                                            min-width="100"
                                        />
                                        <el-table-column
                                            fixed="right"
                                            label="操作"
                                            width="120"
                                        >
                                            <template slot-scope="scopeItem">
                                                <el-button
                                                    type="text"
                                                    size="small"
                                                    @click="onDeleteSelectCourse(scope.row.courseList,scopeItem.row)"
                                                >
                                                    删除
                                                </el-button>
                                                <el-button
                                                    type="text"
                                                    size="small"
                                                    @click="onUpItem(scope.row.courseList, scopeItem.row, scopeItem.$index)"
                                                    v-if="scopeItem.$index !== 0"
                                                >
                                                    上移
                                                </el-button>
                                                <el-button
                                                    type="text"
                                                    size="small"
                                                    @click="onDownItem(scope.row.courseList, scopeItem.row, scopeItem.$index)"
                                                    v-if="(scope.row.courseList.length - 1) !== scopeItem.$index"
                                                >
                                                    下移
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="分类名称"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    <el-input
                                        v-model="scope.row.tagName"
                                        auto-complete="off"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="120"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDeleteCourseCat(scope.row)"
                                    >
                                        删除
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onUpItem(examCourseList, scope.row, scope.$index)"
                                        v-if="scope.$index !== 0"
                                    >
                                        上移
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDownItem(examCourseList, scope.row, scope.$index)"
                                        v-if="(examCourseList.length - 1) !== scope.$index"
                                    >
                                        下移
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item
                        prop="totalCourseTime"
                        label="课程总课时"
                        label-width="10em"
                    >
                        <el-input-number
                            controls-position="right"
                            v-model.number="totalCourseTime"
                            disabled
                        />
                    </el-form-item>
                    <el-form-item
                        prop="examCourseTime"
                        label="考试所需课时"
                        label-width="10em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :max="totalCourseTime"
                            v-model.number="formModel.examCourseTime"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="examMinuteTime"
                        label="考试所需分钟"
                        label-width="10em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            v-model.number="formModel.examMinuteTime"
                        />
                    </el-form-item>
                </el-card>
<!--                <el-card class="ma-t">
                    <el-form-item
                        label="公司试卷模板"
                        label-width="10em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onAddCompanyPaperTemplate"
                        >
                            添加
                        </el-button>
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="companyPaperTemplateList"
                            style="width: 100%"
                            class="ma-t"
                        >
                            <el-table-column
                                label="公司名称"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    <el-input
                                        v-model="scope.row.companyName"
                                        auto-complete="off"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="试卷模板"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    <RemoteSelect
                                        remote="/ex/paperTemplate/select"
                                        placeholder="请输入"
                                        :props="{id:'id',name:'templateName'}"
                                        v-model="scope.row.paperTemplateId"
                                        :remote-params="{certId:formModel.certId,certIdReq:1}"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="60"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDeleteCompanyPaperTemplate(scope.row)"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-card>-->
                <el-card class="ma-t">
                    <el-form-item
                        label="管理用户"
                        label-width="10em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onAddSelect"
                        >
                            添加用户
                        </el-button>
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="examUserList"
                            style="width: 100%"
                            class="ma-t"
                        >
                            <el-table-column
                                prop="userName"
                                label="用户名"
                                min-width="100"
                            />
                            <el-table-column
                                prop="fullName"
                                label="姓名"
                                min-width="100"
                            />
                            <el-table-column
                                prop="orgName"
                                label="部门"
                                min-width="100"
                            />
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="45"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDeleteSelect(scope.row)"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item
                        label-width="13em"
                        class="ma-t"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-card>
            </el-form>
        </template>

        <!--选择用户-->
        <el-dialog
            title="选择用户"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="userName"
                    label="用户名"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.userName"
                        placeholder="请输入用户名"
                    />
                </el-form-item>
                <el-form-item
                    prop="fullName"
                    label="姓名"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.fullName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="userName"
                        label="用户名"
                        min-width="100"
                    />
                    <el-table-column
                        prop="fullName"
                        label="姓名"
                        min-width="100"
                    />
                    <el-table-column
                        prop="orgName"
                        label="部门"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!--选择课程-->
        <el-dialog
            title="选择课程"
            center
            width="1200px"
            :visible.sync="selectCourseDialog.isVisible"
            @closed="onSelectCourseCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectCourseDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectCourseDialog.queryFormModel"
            >
                <el-form-item
                    prop="courseName"
                    label="课程名称"
                >
                    <el-input
                        v-model="selectCourseDialog.queryFormModel.courseName"
                        placeholder="请输入课程名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="courseCatId"
                    label="课程分类"
                >
                    <CascaderPicker
                        check-strictly
                        :api-class="selectApi"
                        v-model="selectCourseDialog.queryFormModel.courseCatId"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectCourseDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectCourseDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectCourseDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectCourseDialogTable"
                    @selection-change="onSelectCourseDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="courseCatNamePath"
                        label="课程分类"
                        min-width="100"
                    />
                    <el-table-column
                        prop="courseName"
                        label="课程名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="courseDesc"
                        label="课程描述"
                        min-width="100"
                    />
                    <el-table-column
                        prop="courseHour"
                        label="课时"
                        min-width="100"
                    />
                    <el-table-column
                        prop="orderNum"
                        label="排序号"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectCourseDialog.pagination"
                        @input="onSelectCourseDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectCourseConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCourseCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ExamEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                certId: '',
                paperTemplateId: '',
                examName: '',
                examImage: [],
                examListImage: [],

                examBeginTime: '',
                examEndTime: '',
                applyBeginTime: '',
                applyEndTime: '',
                applySuggestTime: '',
                examTime: 1,
                examNum: 1,
                isMakeUp: false,
                makeUpLimit: 0,
                makeUpDesc: '',
                isUseExamStudent: false,
                isAuthInfo: false,
                isCourseAuthInfo: true,
                examCourseTime: '',
                examType: 1,
                examAnswerType: 1,
                totalAmount: 0,
                examApplyDesc: '',

                orgMemberCatIdList: [],

                examTitle: '',
                examDetailImage: [],
                teacherId: '',

                examMethod: '1',
                latLng: '',
                isExamCapture: false,
                examCaptureRate: '',
                isCourseCapture: false,
                courseCaptureRate: '',
                examMinuteTime: '',
                isCourseQuestion: false,
                courseQuestionRate: '',
                orderNum: '',
                registerFormBatchId: '',

                isShowHomePage: false,
                isElectronicCertificate: false,
                isExamOrg: false,
            },
            examUserList: [],
            examCourseList: [],
            // 表单校验规则
            formRules: {
                examType: {
                    required: true,
                    message: '请选择考试类型',
                },
                examAnswerType: {
                    required: true,
                    message: '请选择答题模式',
                },
                certId: {
                    required: true,
                    message: '请选择证书',
                },
                paperTemplateId: {
                    required: true,
                    message: '请选择试卷模板',
                },
                examName: {
                    required: true,
                    message: '请输入考试名称',
                    trigger: 'blur',
                },
                examBeginTime: {
                    required: true,
                    message: '请选择考试开始时间',
                },
                examEndTime: {
                    required: true,
                    message: '请选择考试截止时间',
                },
                applyBeginTime: {
                    required: true,
                    message: '请选择报名开始时间',
                },
                applyEndTime: {
                    required: true,
                    message: '请选择报名截止时间',
                },
                examTime: {
                    required: true,
                    message: '请输入考试时间',
                    trigger: 'blur',
                },
                examNum: {
                    required: true,
                    message: '请输入考试次数',
                    trigger: 'blur',
                },
                isUseExamStudent: {
                    required: true,
                    message: '请选择是否使用考试名单',
                    trigger: 'blur',
                },
                isAuthInfo: {
                    required: true,
                    message: '请选择是否认证信息',
                    trigger: 'blur',
                },
                isCourseAuthInfo: {
                    required: true,
                    message: '请选择学习是否认证信息',
                    trigger: 'blur',
                },
                isMakeUp: {
                    required: true,
                    message: '请选择是否允许补考',
                    trigger: 'blur',
                },
                makeUpLimit: {
                    required: true,
                    message: '请输入补考限制',
                },
                totalAmount: {
                    required: true,
                    message: '请输入考试费用',
                },
                examApplyDesc: {
                    required: true,
                    message: '请输入报考须知',
                },
                examMethod: {
                    required: true,
                    message: '请选择考试方式',
                },
                latLng: {
                    required: true,
                    message: '请选择坐标',
                    trigger: 'blur',
                },
                isExamCapture: {
                    required: true,
                    message: '请选择是否考试抓拍',
                    trigger: 'blur',
                },
                isCourseCapture: {
                    required: true,
                    message: '请选择是否学习抓拍',
                    trigger: 'blur',
                },
                examCaptureRate: {
                    required: true,
                    message: '请输入考试抓拍频率',
                    trigger: 'blur',
                },
                courseCaptureRate: {
                    required: true,
                    message: '请输入学习抓拍频率',
                    trigger: 'blur',
                },
                orderNum: {
                    required: true,
                    message: '请输入排序号',
                    trigger: 'blur',
                },
            },
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    userName: '',
                    fullName: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            selectCourseDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    courseName: '',
                    courseCatId: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
                row: {},
                ignoreIds: '',
            },
            defaultProps: {
                children: 'children',
                label: 'name',
            },
            orgMemberCatList: [],
            selectApi: this.$api.Sv.CourseCat,
            companyPaperTemplateList: [],
        };
    },
    computed: {
        totalCourseTime() {
            let result = 0;
            this.examCourseList.forEach(item => {
                item.courseList.forEach(course => {
                    result = result + course.courseHour;
                });
            });
            return result;
        },
    },
    watch: {
        'formModel.certId': function(newVal, oldVal) {
            if (oldVal && oldVal !== newVal) {
                this.formModel.paperTemplateId = undefined;
            }
        },
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                const userIdList = [];
                this.examUserList.forEach(item => {
                    userIdList.push(item.id);
                });
                this.$api.Ex.Exam.save({
                    ...this.formModel,
                    userIds: userIdList.join(','),
                    courseJson: JSON.stringify(this.examCourseList),
                    orgMemberCatIds: this.$refs.tree ? this.$refs.tree.getCheckedKeys().join(',') : '',
                    totalCourseTime: this.totalCourseTime,
                    companyPaperTemplateJson: JSON.stringify(this.companyPaperTemplateList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Ex.Exam.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res.exam);
                this.formModel.orgMemberCatIdList = res.examOrgMemberCatIdList;
                this.examUserList = res.examUserList || [];
                this.examCourseList = res.examCourseList || [];
                this.companyPaperTemplateList = res.companyPaperTemplateList || [];
                // this.initOrgMemberCatList();
            });
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectDialogTable) {
                    this.$refs.selectDialogTable.clearSelection();
                }
                this.examUserList.forEach(item => {
                    this.$refs.selectDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Rs.User.selectByPage({
                ...this.selectDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            // 插入新数据
            const list = [];
            this.selectDialog.currentTableSelect.forEach(item => {
                let isHave = false;
                this.examUserList.forEach(oldItem => {
                    if (item.id === oldItem.id) {
                        list.push({ ...oldItem });
                        isHave = true;
                    }
                });
                if (!isHave) {
                    //添加新数据
                    list.push({ ...item });
                }
            });
            this.examUserList = [...list];
            this.selectDialog.isVisible = false;
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteSelect(row) {
            this.examUserList.splice(this.examUserList.indexOf(row), 1);
        },
        onSelectCourseDialogSelectionChange(val) {
            this.selectCourseDialog.currentTableSelect = val;
        },
        onAddSelectCourse(row) {
            this.selectCourseDialog.row = row;
            this.selectCourseDialog.isVisible = true;

            const idList = [];
            row.courseList.forEach(item => {
                idList.push(item.id);
            });
            const ignoreIdList = [];
            this.examCourseList.forEach(item => {
                item.courseList.forEach(item1 => {
                    if (idList.indexOf(item1.id) === -1) {
                        ignoreIdList.push(item1.id);
                    }
                });
            });
            this.selectCourseDialog.ignoreIds = ignoreIdList.join(',');
            this.$nextTick(() => {
                this.onSelectCourseDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectCourseDialogTable) {
                    this.$refs.selectCourseDialogTable.clearSelection();
                }
                this.selectCourseDialog.row.courseList.forEach(item => {
                    this.$refs.selectCourseDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectCourseDialogQuery(pagination) {
            return this.$api.Sv.Course.data({
                ...this.selectCourseDialog.queryFormModel,
                ...pagination,
                ignoreIds: this.selectCourseDialog.ignoreIds,
            }).then(json => {
                const res = json.data;
                this.selectCourseDialog.tableData = res.data;
                this.selectCourseDialog.pagination = res.pagination;
            });
        },
        onResetSelectCourseDialog() {
            this.$refs.selectCourseDialogQueryForm.resetFields();
        },
        onSelectCourseConfirm() {
            // 插入新数据
            const list = [];
            this.selectCourseDialog.currentTableSelect.forEach(item => {
                let isHave = false;
                this.selectCourseDialog.row.courseList.forEach(oldItem => {
                    if (item.id === oldItem.id) {
                        list.push({ ...oldItem });
                        isHave = true;
                    }
                });
                if (!isHave) {
                    //添加新数据
                    list.push({ ...item });
                }
            });
            this.$set(this.selectCourseDialog.row, 'courseList', [...list]);
            this.selectCourseDialog.isVisible = false;
        },
        onSelectCourseCancel() {
            this.selectCourseDialog.isVisible = false;
        },
        onDeleteSelectCourse(list, row) {
            list.splice(list.indexOf(row), 1);
        },
        initOrgMemberCatList() {
            this.$api.Ex.OrgMemberCat.selectAll().then(json => {
                this.orgMemberCatList = this.$utils.Convert.listToTree(json.data.data);
                if (this.formModel.orgMemberCatIdList) {
                    this.$refs.tree.setCheckedKeys(this.formModel.orgMemberCatIdList);
                }
            });
        },
        onAddCourseCat() {
            this.examCourseList.push({
                tagName: '分类' + (this.examCourseList.length + 1),
                courseList: [],
            });
        },
        onDeleteCourseCat(row) {
            this.examCourseList.splice(this.examCourseList.indexOf(row), 1);
        },
        onUpItem(list, row, index) {
            if (index != 0) {
                list[index] = list.splice(index - 1, 1, list[index])[0];
            } else {
                list.push(list.shift());
            }
        },
        onDownItem(list, row, index) {
            if (index != list.length - 1) {
                list[index] = list.splice(index + 1, 1, list[index])[0];
            } else {
                list.unshift(list.splice(index, 1)[0]);
            }
        },
        onAddCompanyPaperTemplate() {
            this.companyPaperTemplateList.push({});
        },
        onDeleteCompanyPaperTemplate(row) {
            this.companyPaperTemplateList.splice(this.companyPaperTemplateList.indexOf(row), 1);
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.$route.name === 'ex-exam-examEdit') {
            //定制商品
            this.formModel.examType = 1;
        } else {
            this.formModel.examType = 2;
        }
        if (this.formModel.id) {
            this.init();
        } else {
            // this.initOrgMemberCatList();
        }
    },
};
</script>

<style lang="scss">
</style>
