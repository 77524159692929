<!-- Created by henian.xu on 2020/1/8. -->

<template>
    <Page>
        <template v-slot:body>
            <div class="body">
                <div class="demo-app-top">
                    <el-button @click="toggleWeekends">
                        切换周末
                    </el-button>
                    <el-button @click="gotoPast">
                        过去的某个日期
                    </el-button>
                    (另外，单击日期/时间以添加事件)
                    <Linker
                        class="tc-primary"
                        to="https://fullcalendar.io/docs"
                    >
                        fullCalendar 文档
                    </Linker>
                </div>
                <FullCalendar
                    class="demo-app-calendar"
                    ref="fullCalendar"
                    default-view="dayGridMonth"
                    :locale="locale"
                    :header="{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                    }"
                    :plugins="calendarPlugins"
                    :weekends="calendarWeekends"
                    :events="calendarEvents"
                    :editable="true"
                    :event-limit="true"
                    @dateClick="handleDateClick"
                    @eventClick="handleEventClick"
                />
            </div>
        </template>
    </Page>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import zhCnLocale from '@fullcalendar/core/locales/zh-cn';

export default {
    name: 'FullCalendarDemo',
    components: { FullCalendar },
    data() {
        return {
            calendarPlugins: [
                // plugins must be defined in the JS
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin, // needed for dateClick
                listPlugin,
            ],
            calendarWeekends: true,
            calendarEvents: [
                // initial event data, 也可以是一个返回 json 数组的接口地址 https://fullcalendar.io/demo-events.json?overload-day
                { title: 'Event Now', start: new Date() },
            ],
            // calendarEvents: 'https://fullcalendar.io/demo-events.json?overload-day',
            locale: zhCnLocale,
        };
    },
    methods: {
        toggleWeekends() {
            this.calendarWeekends = !this.calendarWeekends; // update a property
        },
        gotoPast() {
            const calendarApi = this.$refs.fullCalendar.getApi(); // from the ref="..."
            calendarApi.gotoDate('2000-01-01'); // call a method on the Calendar object
        },
        handleDateClick(arg) {
            console.log(arg);
            // eslint-disable-next-line no-alert
            if (window.confirm(`Would you like to add an event to ${arg.dateStr} ?`)) {
                this.calendarEvents.push({
                    // add new event data
                    title: 'New Event',
                    start: arg.date,
                    allDay: arg.allDay,
                });
            }
        },
        handleEventClick(info) {
            console.log(info);
        },
    },
    mounted() {
        const calendarApi = this.$refs.fullCalendar.getApi(); // from the ref="..."
        setTimeout(() => {
            calendarApi.updateSize();
        }, 200);
    },
};
</script>

<style lang="scss">
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/list/main.css';

.demo-app {
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
}

.demo-app-top {
    margin: 0 0 3em;
}

.demo-app-calendar {
    margin: 0 auto;
    max-width: 900px;
}
</style>
