/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sv } from './Sv';
import { GlobalVar } from '@vmf/shared';

class Teacher extends Sv {
    constructor() {
        super();
        this.baseUrl += '/teacher';
    }

    data({ teacherName, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            teacherName,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const teacherAvatar = json.data.data.teacherAvatar;
            if (!teacherAvatar) {
                json.data.data.teacherAvatar = [];
            } else {
                json.data.data.teacherAvatar = teacherAvatar.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({ id, teacherName, teacherAvatar, isAvailable } = {}) {
        const teacherAvatarStr = teacherAvatar
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            teacherName,
            teacherAvatar: teacherAvatarStr,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable }) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}

export default new Teacher();
export { Teacher };
