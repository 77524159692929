<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="fullName"
                    label="姓名"
                >
                    <el-input
                        v-model="queryFormModel.fullName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="电话"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="examOrgId"
                    label="组织机构"
                >
                    <CascaderPicker
                        check-strictly
                        :api-class="selectApi"
                        v-model="queryFormModel.examOrgId"
                        :query-params="cascaderPickerQueryParams"
                    />
                </el-form-item>
                <el-form-item
                    prop="tags"
                    label="标签"
                >
                    <el-input
                        v-model="queryFormModel.tags"
                        placeholder="请输入标签"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        class="ma-l"
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        导出
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="$http.href('/static/excel/考试名单.xlsx')"
                    >
                        下载模板
                    </el-button>
                    <el-upload
                        class="upload-demo dp-ib"
                        action="/ex/examMember/uploadExamMemberByExcel"
                        :show-file-list="false"
                        accept=".xls,.xlsx"
                        :on-success="onOrderImportSuccess"
                        :on-error="onOrderImportError"
                        :data="{type: 1,examId:this.queryFormModel.examId}"
                    >
                        <el-button
                            type="success"
                            icon="el-icon-upload2"
                            size="small"
                        >
                            覆盖导入名单
                        </el-button>
                    </el-upload>
                    <el-upload
                        class="upload-demo dp-ib"
                        action="/ex/examMember/uploadExamMemberByExcel"
                        :show-file-list="false"
                        accept=".xls,.xlsx"
                        :on-success="onOrderImportSuccess"
                        :on-error="onOrderImportError"
                        :data="{type: 2,examId:this.queryFormModel.examId}"
                    >
                        <el-button
                            type="success"
                            icon="el-icon-upload2"
                            size="small"
                        >
                            新增导入名单
                        </el-button>
                    </el-upload>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="examOrgNamePath"
                    label="组织机构"
                    min-width="100"
                />
                <el-table-column
                    prop="fullName"
                    label="姓名"
                    width="70"
                />
                <el-table-column
                    prop="mobile"
                    label="电话"
                    width="100"
                />
                <el-table-column
                    prop="applyStatusName"
                    label="报名状态"
                    width="100"
                />
                <el-table-column
                    prop="examScore"
                    label="考试成绩"
                    width="100"
                />
                <el-table-column
                    prop="tags"
                    label="标签"
                    min-width="100"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'ExamMember',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                examId: '',
                fullName: '',
                mobile: '',
                examOrgId: '',
                tags: '',
            },
            // 表格数据
            tableData: [],
            selectApi: this.$api.Rs.Org,
            cascaderPickerQueryParams: {
                isHideTop: 1,
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.ExamMember.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onOrderImportSuccess(response) {
            if (response.success) {
                this.$message({
                    message: response.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
            } else {
                this.$alert(response.msg, '错误提示', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                });
            }
        },
        onOrderImportError(err) {
            this.$message({
                message: err,
                type: 'error',
            });
        },
        exportExcel() {
            this.$http.href('/ex/examMember/exportExcel', {
                ...this.queryFormModel,
            });
        },
    },
    created() {
        this.queryFormModel.examId = this.$route.params.id;
    },
};
</script>

<style lang="scss">
</style>
