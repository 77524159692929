/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Rs } from './Rs';

class App extends Rs {
    constructor() {
        super();
        this.baseUrl += '/console';
    }

    index() {
        return super.get('/index');
    }

    delete({ ids } = {}) {
        return super.post('/delete', { ids });
    }
}

export default new App();
export { App };
