<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.orderStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="待发货"
                    name="10"
                />
                <el-tab-pane
                    label="待收货"
                    name="20"
                />
                <el-tab-pane
                    label="已完成"
                    name="100"
                />
                <el-tab-pane
                    label="已取消"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="orderSn"
                    label="订单编号"
                >
                    <el-input
                        v-model="queryFormModel.orderSn"
                        placeholder="请输入订单编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryConsignee"
                    label="收件人"
                >
                    <el-input
                        v-model="queryFormModel.deliveryConsignee"
                        placeholder="请输入收件人"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryPhone"
                    label="收件人电话"
                >
                    <el-input
                        v-model="queryFormModel.deliveryPhone"
                        placeholder="请输入收件人电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderTime"
                    label="下单时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.orderTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="trackingNumber"
                    label="快递单号"
                >
                    <el-input
                        v-model="queryFormModel.trackingNumber"
                        placeholder="请输入快递单号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportOrderExcel"
                    >
                        订单导出
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-upload2"
                        size="small"
                        @click="onBatchShippingOrder"
                        v-show="queryFormModel.orderStatus == '10'"
                    >
                        导入批量发货
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="orderSn"
                    label="订单编号"
                    min-width="100"
                />
                <el-table-column
                    label="下单时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="用户信息"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.fullName }}
                        <div>{{ scope.row.mobile }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsSn"
                    label="商品编号"
                    min-width="100"
                />
                <el-table-column
                    prop="quantity"
                    label="购买数量"
                    min-width="100"
                />
                <el-table-column
                    prop="deliveryConsignee"
                    label="收货人姓名"
                    min-width="100"
                />
                <el-table-column
                    prop="deliveryPhone"
                    label="收货人电话"
                    min-width="100"
                />
                <el-table-column
                    label="收货人地址"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.deliveryRegionName }} {{ scope.row.deliveryAddress }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="物流信息"
                    width="300"
                >
                    <template slot-scope="order">
                        <el-popover
                            trigger="hover"
                        >
                            <div class="dp-fx fx-row fx-jc-sb fx-ai-c">
                                <div class="w-50per">
                                    物流公司:{{ order.row.expressName }}
                                </div>
                                <div class="w-50per">
                                    物流单号:{{ order.row.trackingNumber }}
                                </div>
                            </div>
                            <el-table
                                :show-header="false"
                                :data="order.row.trackingInfoList"
                            >
                                <el-table-column
                                    width="150px"
                                    property="ftime"
                                />
                                <el-table-column
                                    width="600px"
                                    property="context"
                                />
                            </el-table>
                            <div
                                slot="reference"
                                style="height: 100px"
                                class="tc-blue"
                            >
                                <div v-if="order.row.trackingInfoList && order.row.trackingInfoList.length !== 0">
                                    <div
                                        class="dp-fx fx-jc-sb fx-ai-c"
                                        v-if="order.row.expressName"
                                    >
                                        <div class="w-50per">
                                            物流公司:{{ order.row.expressName }}
                                        </div>
                                        <div class="ta-r w-50per">
                                            物流单号:{{ order.row.trackingNumber }}
                                        </div>
                                    </div>
                                    <div>{{ order.row.trackingInfoList[0].context }}</div>
                                    <div>{{ order.row.trackingInfoList[0].ftime }}</div>
                                </div>
                                <div v-if="!order.row.trackingInfoList">
                                    暂无物流信息
                                </div>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="100"
                >
                    <template slot-scope="scope">
                        <div class="table-ctrl">
                            <el-button
                                type="text"
                                size="small"
                                @click="cancelOrder(scope.row)"
                                v-if="scope.row.orderStatus === 10"
                            >
                                取消订单
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!--取消订单-->
        <el-dialog
            title="取消订单"
            center
            width="600px"
            :visible.sync="cancelDialog.isVisible"
            @closed="onCancelDialogCancel"
        >
            <el-form
                ref="cancelForm"
                size="small"
                :model="cancelDialog.formModel"
                :rules="cancelDialog.formRules"
            >
                <el-form-item
                    prop="cancelDesc"
                    label="取消原因"
                    label-width="6em"
                >
                    <el-input
                        type="textarea"
                        v-model="cancelDialog.formModel.cancelDesc"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onCancelDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onCancelDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
        <!--批量发货-->
        <el-dialog
            title="批量发货"
            center
            width="600px"
            :visible.sync="batchShippingOrderDialog.isVisible"
            @closed="onBatchShippingOrderDialogCancel"
        >
            <div
                slot="footer"
                class="dialog-footer"
            >
                <!--<el-button
                    type="primary"
                    size="small"
                    disabled
                >
                    模板下载
                </el-button>-->
                <el-upload
                    class="upload-demo dp-ib ma-l"
                    action="/gd/pointGoodsOrder/batchShippingOrderByExcel"
                    :show-file-list="false"
                    accept=".xls,.xlsx"
                    :on-success="onBatchShippingOrderSuccess"
                    :on-error="onBatchShippingOrderError"
                >
                    <el-button
                        size="small"
                        type="primary"
                    >
                        点击上传
                    </el-button>
                </el-upload>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Variable',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                orderStatus: '10',
                orderSn: '',
                deliveryConsignee: '',
                deliveryPhone: '',
                goodsName: '',
                orderTime: [],
                trackingNumber: '',
            },
            // 表格数据
            tableData: [],
            // 取消订单
            cancelDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    cancelDesc: '',
                },
                // 表单校验规则
                formRules: {
                    cancelDesc: {
                        required: true,
                        message: '请输入取消原因',
                        trigger: 'blur',
                    },
                },
            },
            //批量发货
            batchShippingOrderDialog: {
                // 对话框显示
                isVisible: false,
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.PointGoodsOrder.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        //取消订单
        cancelOrder(row) {
            this.cancelDialog.formModel.id = row.id;
            this.cancelDialog.isVisible = true;
        },
        onCancelDialogConfirm() {
            const { cancelForm } = this.$refs;
            cancelForm.validate().then(() => {
                this.$api.Gd.PointGoodsOrder.cancelOrder({
                    ...this.cancelDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.cancelDialog.isVisible = false;
                });
            });
        },
        onCancelDialogCancel() {
            const { cancelForm } = this.$refs;
            cancelForm.resetFields();
            const { formModel } = this.cancelDialog;
            formModel.id = '';
            this.cancelDialog.isVisible = false;
        },
        //批量发货
        onBatchShippingOrder() {
            this.batchShippingOrderDialog.isVisible = true;
        },
        onBatchShippingOrderDialogCancel() {
            this.batchShippingOrderDialog.isVisible = false;
        },
        onBatchShippingOrderSuccess(response) {
            if (response.success) {
                this.$message({
                    message: response.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
            } else {
                this.$alert(response.msg, '错误提示', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                });
            }
        },
        onBatchShippingOrderError(err) {
            this.$message({
                message: err,
                type: 'error',
            });
        },
    },
};
</script>

<style lang="scss">
</style>
