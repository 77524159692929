/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sv } from './Sv';
import { GlobalVar } from '@vmf/shared';

class CertCat extends Sv {
    constructor() {
        super();
        this.baseUrl += '/certCat';
    }

    data({ pId, name, regionCode, isAvailable } = {}) {
        return super.get('/data', {
            pId,
            name,
            regionCode,
            isAvailable,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const certCatImage = json.data.data.certCatImage;
            if (!certCatImage) {
                json.data.data.certCatImage = [];
            } else {
                json.data.data.certCatImage = certCatImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({ id, parentId, name, certCatImage, orderNum, isAvailable } = {}) {
        const certCatImageStr = certCatImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            parentId,
            name,
            certCatImage: certCatImageStr,
            orderNum,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    exists({ id, pId, name } = {}) {
        return super.get('/exists', {
            id,
            pId,
            name,
        });
    }

    selectAll() {
        return super.get(
            '/selectAll',
            {},
            {
                isRepeat: true,
            },
        );
    }

    /**
     * select
     * @param pId
     * @param name
     * @param _cancelSource 用于取消请求
     * @returns {AxiosPromise<any>}
     */
    select({ pId, name } = {}, config = {}) {
        return super.get(
            '/select',
            {
                pId,
                name,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }

    loadSelectNode({ id } = {}, config = {}) {
        return super.get(
            '/loadSelectNode',
            {
                id,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }
}

export default new CertCat();
export { CertCat };
