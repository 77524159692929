/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ms } from './Ms';

class UserMessageSend extends Ms {
    constructor() {
        super();
        this.baseUrl += '/userMessageSend';
    }

    data({ messageCase, messageTitle, currentPage, pageSize } = {}) {
        return super.get('/data', {
            messageCase,
            messageTitle,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then();
    }

    save({ messageCase, messageTitle, messageContent, remarks, userMessageSendType, userIds } = {}) {
        return super.post('/save', {
            messageCase,
            messageTitle,
            messageContent,
            remarks,
            userMessageSendType,
            userIds,
        });
    }
}

export default new UserMessageSend();
export { UserMessageSend };
