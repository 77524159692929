<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="name"
                    label="模块名称"
                >
                    <el-input
                        v-model="queryFormModel.name"
                        placeholder="请输入模块名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="状态"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="启用"
                            value="1"
                        />
                        <el-option
                            label="禁用"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <tree-table
                :lazy-load="getListData"
                init-not-load
                :expand-props="{
                    prop:'name',
                    label:'模块名称',
                    minWidth:200,
                }"
                ref="treeTable"
                stripe
                border
                size="small"
                style="width: 100%"
            >
                <el-table-column
                    label="是否启用"
                    width="80"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @input="onIsAvailableInput($event,scope)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderNum"
                    label="排序"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="135"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableAdd(scope.row)"
                        >
                            新增
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id,scope.row)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </tree-table>
        </div>

        <!-- 弹框 -->
        <!-- 变量新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑模块' : '新增模块'"
            center
            width="900px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    v-if="addDialog.formModel.parentId"
                    prop="parentName"
                    label="上级模块"
                    label-width="7em"
                >
                    <el-input
                        v-model="addDialog.formModel.parentName"
                        disabled
                    />
                </el-form-item>
                <el-form-item
                    prop="name"
                    label="模块名称"
                    label-width="7em"
                >
                    <el-input
                        v-model="addDialog.formModel.name"
                    />
                </el-form-item>
                <el-form-item
                    prop="moduleCatImage"
                    label="模块图标"
                    label-width="7em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="1"
                        v-model="addDialog.formModel.moduleCatImage"
                        :oss-action="$globalVar.appConfig.OSS_ACTION"
                    />
                </el-form-item>
                <el-form-item
                    prop="moduleCatType"
                    label="模块类型"
                    label-width="7em"
                >
                    <dictionaries-picker
                        type="moduleCatType"
                        v-model="addDialog.formModel.moduleCatType"
                    />
                </el-form-item>
                <el-form-item
                    prop="moduleKey"
                    label="自定义key"
                    label-width="7em"
                    v-if="addDialog.formModel.moduleCatType == 1"
                >
                    <el-input
                        v-model="addDialog.formModel.moduleKey"
                    />
                </el-form-item>
                <el-form-item
                    prop="cmsCatalogId"
                    label="cms类目"
                    label-width="7em"
                    v-if="addDialog.formModel.moduleCatType == 2"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="addDialog.formModel.cmsCatalogId"
                        :min-lv="1"
                        :p-id="0"
                        filterable
                        change-on-select
                    />
                </el-form-item>
                <el-form-item
                    prop="cmsArticleId"
                    label="cms文章"
                    label-width="7em"
                    v-if="addDialog.formModel.moduleCatType == 3"
                >
                    <el-button
                        type="primary"
                        size="small"
                        @click="onAddSelect"
                    >
                        选择cms文章
                    </el-button>
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="cmsArticleList"
                        style="width: 100%"
                        class="ma-t"
                    >
                        <el-table-column
                            prop="title"
                            label="标题"
                            min-width="100"
                        />
                        <el-table-column
                            prop="cmsCatalogName"
                            label="分类"
                            min-width="100"
                        />
                        <el-table-column
                            label="发布时间"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            width="45"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onDeleteSelect(scope.row)"
                                >
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item
                    prop="decorationId"
                    label="活动装修"
                    label-width="7em"
                    v-if="addDialog.formModel.moduleCatType == 4"
                >
                    <RemoteSelect
                        remote="/sv/servantDecoration/data"
                        placeholder="请输入"
                        :props="{id:'id',name:'pageName'}"
                        v-model="addDialog.formModel.decorationId"
                        :remote-params="{
                            pageType:'1',
                        }"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderNum"
                    label="排序"
                    label-width="7em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        :max="255"
                        v-model.number="addDialog.formModel.orderNum"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="选择文章"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="title"
                    label="标题"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.title"
                        placeholder="请输入标题"
                    />
                </el-form-item>
                <el-form-item
                    prop="cmsCatalogId"
                    label="分类"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="selectDialog.queryFormModel.cmsCatalogId"
                        :min-lv="1"
                        :p-id="0"
                        filterable
                        change-on-select
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                >
                    <el-table-column
                        prop="title"
                        label="标题"
                        min-width="100"
                    />
                    <el-table-column
                        prop="cmsCatalogName"
                        label="分类"
                        min-width="100"
                    />
                    <el-table-column
                        label="发布时间"
                        min-width="150"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="45"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="onSelectConfirm(scope.row)"
                            >
                                选择
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <!-- <el-button
                     type="primary"
                     size="small"
                     @click="onSelectConfirm"
                 >
                     确 定
                 </el-button>-->
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormTree } from 'vmf/src/mixins';

export default {
    name: 'ModuleCat',
    mixins: [pagesMixin, queryFormTree],
    data() {
        return {
            queryFormModel: {
                name: '', // 模块名称
                isAvailable: '', // 状态
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    parentId: '',
                    parentName: '',
                    name: '',
                    moduleCatImage: [],
                    orderNum: 1,
                    isAvailable: '',

                    moduleCatType: '',
                    moduleKey: '',
                    cmsCatalogId: '',
                    cmsArticleId: '',
                    decorationId: '',
                },
                // 表单校验规则
                formRules: {
                    name: [
                        {
                            required: true,
                            message: '请输入模块名称',
                            trigger: 'blur',
                        },
                        {
                            validator: this.validatorName,
                            message: '模块名称不能重复',
                            trigger: 'blur',
                        },
                    ],
                    moduleKey: {
                        required: true,
                        message: '请输入自定义key',
                        trigger: 'blur',
                    },
                    cmsCatalogId: {
                        required: true,
                        message: '请选择cms类目',
                    },
                    cmsArticleId: {
                        required: true,
                        message: '请选择cms文章',
                    },
                    decorationId: {
                        required: true,
                        message: '请选择活动装修页',
                    },
                },
            },
            // 表格数据
            // tableData: [],
            // 表格当前行
            currentRow: {},
            // 表格当前选中行
            currentTableSelect: [],
            selectApi: this.$api.Pt.CmsCatalog,
            cmsArticleList: [],
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    title: '',
                    cmsCatalogId: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData({ row = {} } = {}) {
            this.querying = true;
            return this.$api.Sv.ModuleCat.data({
                pId: row ? row.id : null,
                ...this.queryFormModel,
            }).then(json => {
                this.querying = false;
                const res = json.data.data.sort((prev, next) => {
                    return prev.orderNum - next.orderNum;
                });
                return Promise.resolve(res);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { formModel } = this.addDialog;
                const { addForm } = this.$refs;
                addForm.resetFields();
                // id/parentId 不在表单之内所以要手机重置
                formModel.id = '';
                formModel.parentId = '';
                formModel.parentName = '';
                formModel.moduleCatType = '';
                formModel.moduleKey = '';
                formModel.cmsCatalogId = '';
                formModel.cmsArticleId = '';
                formModel.decorationId = '';
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableAdd(row) {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { formModel } = this.addDialog;
                const { addForm } = this.$refs;
                addForm.resetFields();
                formModel.id = '';
                formModel.parentId = row.id;
                formModel.parentName = row.name;
                formModel.moduleCatType = '';
                formModel.moduleKey = '';
                formModel.cmsCatalogId = '';
                formModel.cmsArticleId = '';
                formModel.decorationId = '';
            });
            this.currentRow = row;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Sv.ModuleCat.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
                if (res.cmsArticle) {
                    this.cmsArticleList = [{ ...res.cmsArticle }];
                }
                console.log(res);
            });
            this.addDialog.isVisible = true;
            this.currentRow = row;
        },
        onTableDelete(ids, row) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Sv.ModuleCat.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    // this.onQuery();
                    const { treeTable } = this.$refs;
                    treeTable.removeNode(row);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            const { formModel } = this.addDialog;
            addForm.validate().then(() => {
                this.$api.Sv.ModuleCat.save({
                    ...formModel,
                    moduleConfig: JSON.stringify({
                        moduleCatType: this.addDialog.formModel.moduleCatType,
                        moduleKey: this.addDialog.formModel.moduleKey,
                        cmsCatalogId: this.addDialog.formModel.cmsCatalogId,
                        cmsArticleId: this.addDialog.formModel.cmsArticleId,
                        decorationId: this.addDialog.formModel.decorationId,
                    }),
                }).then(json => {
                    const { msg, data } = json.data;
                    this.$message({
                        message: msg,
                        type: 'success',
                    });
                    const { treeTable } = this.$refs;
                    const { currentRow } = this;
                    if (!formModel.parentId && !formModel.id) {
                        // 新增根节点
                        treeTable.loadNode();
                    } else if (formModel.id) {
                        // 更新节点
                        Object.keys(currentRow).forEach(key => {
                            if (this.$utils.hasOwn(currentRow, key) && this.$utils.isDef(data[key])) {
                                currentRow[key] = data[key];
                            }
                        });
                        // 重新排序
                        currentRow._parent.children = currentRow._parent.children.sort((prev, next) => {
                            return prev.orderNum - next.orderNum;
                        });
                    } else if (formModel.parentId) {
                        // 新增子节点
                        treeTable.loadNode(currentRow);
                    }
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            this.addDialog.isVisible = false;
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Sv.ModuleCat.status({
                id: row.id,
                isAvailable: row.isAvailable,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    const { treeTable } = this.$refs;
                    treeTable.refreshChildren(row);
                })
                .catch(() => {
                    this.$message({
                        message: '操作失败请重试！',
                        type: 'error',
                    });
                    row.isAvailable = !row.isAvailable;
                });
        },
        /* -- 自定义校验 -- */
        validatorName(rule, value, callback) {
            this.$api.Sv.ModuleCat.exists({
                pId: this.addDialog.formModel.parentId,
                id: this.addDialog.formModel.id,
                name: this.addDialog.formModel.name,
            }).then(res => {
                if (res.data.data) {
                    callback(rule.message);
                } else {
                    callback();
                }
            });
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
                // 初始化已选择数据
                /*if (this.$refs.selectDialogTable) {
                    this.$refs.selectDialogTable.clearSelection();
                }
                this.examUserList.forEach(item => {
                    this.$refs.selectDialogTable.toggleRowSelection(item, true);
                });*/
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Pt.CmsArticle.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm(row) {
            // 插入新数据
            // const list = [];
            // this.selectDialog.currentTableSelect.forEach(item => {
            //     let isHave = false;
            //     this.examUserList.forEach(oldItem => {
            //         if (item.id === oldItem.id) {
            //             list.push({ ...oldItem });
            //             isHave = true;
            //         }
            //     });
            //     if (!isHave) {
            //         //添加新数据
            //         list.push({ ...item });
            //     }
            // });
            // this.examUserList = [...list];
            this.cmsArticleList = [{ ...row }];
            this.addDialog.formModel.cmsArticleId = row.id;
            this.selectDialog.isVisible = false;
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteSelect(row) {
            this.cmsArticleList.splice(this.cmsArticleList.indexOf(row), 1);
            this.addDialog.formModel.cmsArticleId = undefined;
        },
    },
};
</script>

<style lang="scss">
</style>
