/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';
import { GlobalVar } from '@vmf/shared';

class DocumentCat extends Gd {
    constructor() {
        super();
        this.baseUrl += '/documentCat';
    }

    data({ pId, name, isAvailable } = {}) {
        return super.get('/data', {
            pId,
            name,
            isAvailable,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const documentCatImage = json.data.data.documentCatImage;
            if (!documentCatImage) {
                json.data.data.documentCatImage = [];
            } else {
                json.data.data.documentCatImage = documentCatImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({ id, parentId, name, documentCatImage, orderNum, isAvailable } = {}) {
        const documentCatImageStr = documentCatImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            parentId,
            name,
            documentCatImage: documentCatImageStr,
            orderNum,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }

    exists({ id, pId, name } = {}) {
        return super.get('/exists', {
            id,
            pId,
            name,
        });
    }

    selectAll() {
        return super.get(
            '/selectAll',
            {},
            {
                isRepeat: true,
            },
        );
    }

    /**
     * select
     * @param pId
     * @param name
     * @param _cancelSource 用于取消请求
     * @returns {AxiosPromise<any>}
     */
    select({ pId, name } = {}, config = {}) {
        return super.get(
            '/select',
            {
                pId,
                name,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }

    loadSelectNode({ id } = {}, config = {}) {
        return super.get(
            '/loadSelectNode',
            {
                id,
            },
            {
                isRepeat: true,
                ...config,
            },
        );
    }
}

export default new DocumentCat();
export { DocumentCat };
