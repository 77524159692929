/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class ExamGame extends Ex {
    constructor() {
        super();
        this.baseUrl += '/examGame';
    }

    data({ examGameName, examGameType, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            examGameName,
            examGameType,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, examGameType, examGameName, examGameConfig, isAvailable } = {}) {
        return super.post('/save', {
            id,
            examGameType,
            examGameName,
            examGameConfig,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}

export default new ExamGame();
export { ExamGame };
