/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class MemberTemperatureReport extends Mb {
    constructor() {
        super();
        this.baseUrl += '/memberTemperatureReport';
    }

    data({ orgId, fullName, reportTime, currentPage, pageSize } = {}) {
        let reportTimeBegin = null;
        let reportTimeEnd = null;
        if (reportTime != null && reportTime.length === 2) {
            reportTimeBegin = reportTime[0];
            reportTimeEnd = reportTime[1];
        }
        return super.get('/data', {
            orgId,
            fullName,
            reportTimeBegin,
            reportTimeEnd,
            currentPage,
            pageSize,
        });
    }
}

export default new MemberTemperatureReport();
export { MemberTemperatureReport };
