<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-col :span="12">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        prop="cmsCatalogId"
                        label="分类"
                        label-width="8em"
                    >
                        <CascaderPicker
                            :api-class="selectApi"
                            v-model="formModel.cmsCatalogId"
                            :min-lv="1"
                            :p-id="0"
                            filterable
                            change-on-select
                        />
                    </el-form-item>
                    <!--<el-form-item
                        prop="showType"
                        label="展示类型"
                        label-width="8em"
                    >
                        <dictionaries-picker
                            type="cmsArticleShowType"
                            v-model="formModel.showType"
                        />
                    </el-form-item>-->
                    <el-form-item
                        prop="title"
                        label="标题"
                        label-width="8em"
                    >
                        <el-input
                            v-model="formModel.title"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="content"
                        label="正文"
                        label-width="8em"
                        style="width: 800px;"
                        key="content"
                        v-if="formModel.cmsArticleType == 1"
                    >
                        <Tinymce
                            :height="500"
                            image-action="/rs/attachment/uploadCmsArticleImage"
                            v-model="formModel.content"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="imageFileList"
                        label="图片列表"
                        label-width="8em"
                        v-if="formModel.cmsArticleType == 2"
                        key="imageFileList"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="20"
                            v-model="formModel.allImageFileList"
                            tips=""
                            @input="uploadImage"
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="formModel.imageFileList"
                            style="width: 100%"
                            class="ma-t"
                        >
                            <el-table-column
                                prop="image"
                                label="图片"
                                width="150"
                            >
                                <template slot-scope="scope">
                                    <ImageList
                                        :data="scope.row.address"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="desc"
                                label="描述"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    <el-input
                                        type="textarea"
                                        v-model="scope.row.desc"
                                        auto-complete="off"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="90"
                            >
                                <template slot-scope="scope">
                                    <!--<el-button
                                        type="text"
                                        size="small"
                                        @click="onDeleteItem(scope.row)"
                                    >
                                        删除
                                    </el-button>-->
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onUpItem(scope.row, scope.$index)"
                                        v-if="scope.$index !== 0"
                                    >
                                        上移
                                    </el-button>
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDownItem(scope.row, scope.$index)"
                                        v-if="(formModel.imageFileList.length - 1) !== scope.$index"
                                    >
                                        下移
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                    <el-form-item
                        prop="videoFileList"
                        label="视频文件"
                        label-width="8em"
                        v-if="formModel.cmsArticleType == 3"
                        key="videoFileList"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.videoFileList"
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="titleImage"
                        label="列表图片"
                        label-width="8em"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.titleImage"
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="contentImage"
                        label="正文头图"
                        label-width="8em"
                        v-if="formModel.cmsArticleType == 1"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.contentImage"
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="voiceFile"
                        label="语音文件"
                        label-width="8em"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.voiceFile"
                            tips=""
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="subtitle"
                        label="副标题"
                        label-width="8em"
                    >
                        <el-input
                            v-model="formModel.subtitle"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="digest"
                        label="摘要"
                        label-width="8em"
                    >
                        <el-input
                            v-model="formModel.digest"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="author"
                        label="作者"
                        label-width="8em"
                    >
                        <el-input
                            v-model="formModel.author"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="source"
                        label="来源"
                        label-width="8em"
                    >
                        <el-input
                            v-model="formModel.source"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isShowReleaseTime"
                        label="显示发布时间"
                        label-width="8em"
                    >
                        <el-switch
                            v-model="formModel.isShowReleaseTime"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="releaseTime"
                        label="发布时间"
                        label-width="8em"
                    >
                        <el-date-picker
                            v-model="formModel.releaseTime"
                            type="datetime"
                            placeholder="选择发布时间"
                        />
                    </el-form-item>
                    <el-form-item
                        label-width="8em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { GlobalVar } from '@vmf/shared';

export default {
    name: 'CmsArticleEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                showType: 1,
                title: '',
                subtitle: '',
                cmsCatalogId: '',
                titleImageType: '1',
                titleImage: [],
                contentImageType: '1',
                contentImage: [],
                digest: '',
                author: '',
                source: '',
                isShowReleaseTime: false,
                releaseTime: '',
                content: '',
                imageFileList: [],
                videoFileList: [],
                voiceFile: [],
                cmsArticleType: '',

                imageList: [],
                allImageFileList: [],
            },
            // 表单校验规则
            formRules: {
                cmsCatalogId: {
                    required: true,
                    message: '请选择cms分类',
                },
                title: {
                    required: true,
                    message: '请输入标题',
                    trigger: 'blur',
                },
                titleImage: {
                    type: 'array',
                    required: true,
                    message: '请上传标题图片',
                },
                isAllowMoreLevel: {
                    required: true,
                    message: '请选择是否显示发布时间',
                    trigger: 'blur',
                },
                content: {
                    required: true,
                    message: '请输入内容',
                    trigger: 'blur',
                },
                imageFileList: {
                    type: 'array',
                    required: true,
                    message: '请上传图片',
                },
                videoFileList: {
                    type: 'array',
                    required: true,
                    message: '请上传视频',
                },
            },
            selectApi: this.$api.Pt.CmsCatalog,
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                let content = '';
                if (this.formModel.cmsArticleType == 1) {
                    content = this.formModel.content;
                    const res = [];
                    content.replace(/<img.*?src=["|']?(.*?)["|']?\s.*?>/gi, (match, g1) => {
                        if (g1) res.push(g1);
                        return match;
                    });
                    this.formModel.imageList = res;
                } else if (this.formModel.cmsArticleType == 2) {
                    content = JSON.stringify(this.formModel.imageFileList);
                } else {
                    content = this.formModel.videoFileList
                        .reduce((prev, curr) => {
                            prev.push(curr.name);
                            return prev;
                        }, [])
                        .join(',');
                }
                this.$api.Pt.CmsArticle.save({
                    ...this.formModel,
                    imageList: this.formModel.imageList ? this.formModel.imageList.join(',') : '',
                    content: content,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Pt.CmsArticle.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
                if (this.formModel.cmsArticleType == 1) {
                    this.formModel.content = res.content;
                } else if (this.formModel.cmsArticleType == 2) {
                    this.formModel.imageFileList = JSON.parse(res.content) || [];
                    this.formModel.imageFileList.forEach(item => {
                        item.address = GlobalVar.fileBasePath + item.name;
                        this.formModel.allImageFileList.push({
                            name: item.name,
                            address: item.address,
                        });
                    });
                } else {
                    this.formModel.videoFileList = res.content.split(',').reduce((prev, curr) => {
                        prev.push({
                            name: curr,
                            address: GlobalVar.fileBasePath + curr,
                        });
                        return prev;
                    }, []);
                }
            });
        },
        onAddItem() {
            this.formModel.imageFileList.push({});
        },
        onDeleteItem(row) {
            this.formModel.imageFileList.splice(this.formModel.imageFileList.indexOf(row), 1);
        },
        onUpItem(row, index) {
            if (index != 0) {
                this.formModel.imageFileList[index] = this.formModel.imageFileList.splice(
                    index - 1,
                    1,
                    this.formModel.imageFileList[index],
                )[0];
            } else {
                this.formModel.imageFileList.push(this.formModel.imageFileList.shift());
            }
        },
        onDownItem(row, index) {
            if (index != this.formModel.imageFileList.length - 1) {
                this.formModel.imageFileList[index] = this.formModel.imageFileList.splice(
                    index + 1,
                    1,
                    this.formModel.imageFileList[index],
                )[0];
            } else {
                this.formModel.imageFileList.unshift(this.formModel.imageFileList.splice(index, 1)[0]);
            }
        },
        uploadImage() {
            this.formModel.allImageFileList.forEach(item => {
                let isHave = false;
                this.formModel.imageFileList.forEach(oldItem => {
                    if (oldItem.name == item.name) {
                        isHave = true;
                    }
                });
                if (!isHave) {
                    this.formModel.imageFileList.push({
                        name: item.name,
                        address: item.address,
                        desc: '',
                    });
                }
            });
            this.formModel.imageFileList.forEach(oldItem => {
                let isHave = false;
                this.formModel.allImageFileList.forEach(item => {
                    if (oldItem.name == item.name) {
                        isHave = true;
                    }
                });
                if (!isHave) {
                    this.onDeleteItem(oldItem);
                }
            });
        },
    },
    created() {
        this.formModel.cmsArticleType = this.$route.params.cmsArticleType;
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
