/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import PaperTemplate from './PaperTemplate';
import Exam from './Exam';
import ExamMember from './ExamMember';
import ExamCheck from './ExamCheck';
import ExamGame from './ExamGame';
import ExamApply from './ExamApply';
import ExamApplyPaper from './ExamApplyPaper';
import OrgMemberCat from './OrgMemberCat';
import OrgMember from './OrgMember';
import RegisterFormBatch from './RegisterFormBatch';
import RegisterForm from './RegisterForm';

export default {
    PaperTemplate,
    Exam,
    ExamMember,
    ExamCheck,
    ExamGame,
    ExamApply,
    ExamApplyPaper,
    OrgMemberCat,
    OrgMember,
    RegisterFormBatch,
    RegisterForm,
};
