<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-form-item
                prop="questionCatId"
                label="题目分类"
                label-width="8em"
            >
                <CascaderPicker
                    check-strictly
                    :api-class="selectApi"
                    v-model="formModel.questionCatId"
                />
            </el-form-item>
            <el-form-item
                prop="questionType"
                label="题目类型"
                label-width="8em"
            >
                <dictionaries-picker
                    type="questionType"
                    v-model="formModel.questionType"
                    @input="changeQuestionType"
                />
            </el-form-item>
            <el-form-item
                prop="questionOptionType"
                label="题目选项类型"
                label-width="8em"
                v-if="formModel.questionType != 2"
            >
                <dictionaries-picker
                    type="questionOptionType"
                    v-model="formModel.questionOptionType"
                />
            </el-form-item>
            <el-form-item
                prop="title"
                label="题干内容"
                label-width="8em"
            >
                <el-input
                    type="textarea"
                    v-model="formModel.title"
                    auto-complete="off"
                />
            </el-form-item>
            <el-form-item
                label="选项"
                label-width="8em"
                v-if="!!formModel.questionType"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddOption"
                >
                    新增
                </el-button>
                <el-button
                    size="small"
                    @click="onDelOption"
                >
                    删除
                </el-button>
                <el-checkbox-group
                    class="ma-t"
                    v-model="formModel.questionAnswer"
                >
                    <div
                        v-for="(item,index) in formModel.optionConfigList"
                        :key="item.option"
                    >
                        <template
                            v-if="formModel.questionType != 3"
                        >
                            <el-radio
                                v-model="formModel.questionAnswer"
                                :label="item.option"
                            >
                                {{ item.text }}
                                <template v-if="formModel.questionType == 2">
                                    {{ item.content }}
                                </template>
                            </el-radio>
                        </template>
                        <template
                            v-if="formModel.questionType == 3"
                        >
                            <el-checkbox :label="item.option">
                                {{ item.text }}
                            </el-checkbox>
                        </template>
                        <template v-if="formModel.questionType != 2">
                            <div>
                                <el-form-item
                                    :prop="`optionConfigList[` + index + `].content`"
                                    :rules="formRules.content"
                                    label=""
                                    v-if="formModel.questionOptionType == 1"
                                >
                                    <el-input
                                        type="textarea"
                                        v-model="formModel.optionConfigList[index].content"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    :prop="`optionConfigList[` + index + `].image`"
                                    :rules="formRules.image"
                                    label=""
                                    v-if="formModel.questionOptionType == 2"
                                >
                                    <upload
                                        validate-md5
                                        action="/rs/attachment/uploadFileLibrary"
                                        :limit="1"
                                        v-model="formModel.optionConfigList[index].image"
                                        :oss-action="$globalVar.appConfig.OSS_ACTION"
                                    />
                                </el-form-item>
                            </div>
                        </template>
                    </div>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item
                prop="answerAnalysis"
                label="答案解析"
                label-width="8em"
            >
                <el-input
                    type="textarea"
                    v-model="formModel.answerAnalysis"
                    auto-complete="off"
                />
            </el-form-item>
            <el-form-item
                label-width="13em"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onConfirm"
                >
                    保 存
                </el-button>
                <el-button
                    size="small"
                    @click="$router.back()"
                >
                    返 回
                </el-button>
            </el-form-item>
        </el-form>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'QuestionEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                questionCatId: '',
                title: '',
                answerAnalysis: '',
                questionType: 1,
                questionOptionType: 1,

                questionAnswer: 1,
                optionConfigList: [
                    { option: 1, text: 'A', content: '', image: [] },
                    { option: 2, text: 'B', content: '', image: [] },
                ],
            },
            // 表单校验规则
            formRules: {
                questionCatId: {
                    required: true,
                    message: '请选择题目分类',
                },
                title: {
                    required: true,
                    message: '请输入题干内容',
                    trigger: 'blur',
                },
                answerAnalysis: {
                    required: true,
                    message: '请输入答案解析',
                    trigger: 'blur',
                },
                questionType: {
                    required: true,
                    message: '请选择题目类型',
                },
                questionOptionType: {
                    required: true,
                    message: '请选择题目选项类型',
                },
                content: {
                    required: true,
                    message: '请输入选项',
                    trigger: 'blur',
                },
                image: {
                    type: 'array',
                    required: true,
                    message: '请上传选项图片',
                },
            },
            selectApi: this.$api.Sv.QuestionCat,
            optionMap: [
                {
                    option: 1,
                    text: 'A',
                },
                {
                    option: 2,
                    text: 'B',
                },
                {
                    option: 4,
                    text: 'C',
                },
                {
                    option: 8,
                    text: 'D',
                },
                {
                    option: 16,
                    text: 'E',
                },
                {
                    option: 32,
                    text: 'F',
                },
                {
                    option: 64,
                    text: 'G',
                },
                {
                    option: 128,
                    text: 'H',
                },
            ],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                let questionAnswer = this.formModel.questionAnswer;
                if (this.formModel.questionType == 3) {
                    if (this.formModel.questionAnswer.length === 0) {
                        this.$message({
                            message: '多选题答案不能为空',
                            type: 'error',
                        });
                        return;
                    }
                    let result = 0;
                    this.formModel.questionAnswer.forEach(item => {
                        result = result | item;
                    });
                    questionAnswer = result;
                }
                this.$api.Sv.Question.save({
                    ...this.formModel,
                    optionConfig: JSON.stringify(this.formModel.optionConfigList),
                    questionAnswer: questionAnswer,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Sv.Question.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
                this.formModel.optionConfigList = res.optionConfigList;
                if (this.formModel.questionType == 3) {
                    let questionAnswer = this.formModel.questionAnswer;
                    this.formModel.questionAnswer = [];
                    this.formModel.optionConfigList.forEach(item => {
                        if ((item.option & questionAnswer) != 0) {
                            this.formModel.questionAnswer.push(item.option);
                        }
                    });
                }
            });
        },
        onAddOption() {
            if (this.formModel.optionConfigList.length < 8) {
                const data = this.optionMap[this.formModel.optionConfigList.length];
                this.formModel.optionConfigList.push({
                    option: data.option,
                    text: data.text,
                    content: '',
                });
            }
        },
        onDelOption() {
            if (this.formModel.optionConfigList.length > 2) {
                this.formModel.optionConfigList.splice(this.formModel.optionConfigList.length - 1, 1);
            }
        },
        changeQuestionType() {
            if (this.formModel.questionType == 1 || this.formModel.questionType == 4) {
                this.formModel.optionConfigList = [
                    { option: 1, text: 'A', content: '', image: [] },
                    { option: 2, text: 'B', content: '', image: [] },
                ];
                this.formModel.questionAnswer = 1;
            } else if (this.formModel.questionType == 2) {
                this.formModel.optionConfigList = [
                    { option: 1, text: 'A', content: '正确' },
                    { option: 2, text: 'B', content: '错误' },
                ];
                this.formModel.questionAnswer = 1;
            } else if (this.formModel.questionType == 3) {
                this.formModel.optionConfigList = [
                    { option: 1, text: 'A', content: '', image: [] },
                    { option: 2, text: 'B', content: '', image: [] },
                ];
                this.formModel.questionAnswer = [1];
            }
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
