/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class GrowthValueRule extends Mb {
    constructor() {
        super();
        this.baseUrl += '/growthValueRule';
    }

    data() {
        return super.get('/data', {});
    }

    getDetail({ growthValueCaseId } = {}) {
        return super.get('/getDetail', { growthValueCaseId });
    }

    save({ id, growthValueCaseId, ruleConfig, isAvailable } = {}) {
        return super.post('/save', {
            id,
            growthValueCaseId,
            ruleConfig,
            isAvailable,
        });
    }
}

export default new GrowthValueRule();
export { GrowthValueRule };
