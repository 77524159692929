/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sv } from './Sv';
import { GlobalVar } from '@vmf/shared';

class Cert extends Sv {
    constructor() {
        super();
        this.baseUrl += '/cert';
    }

    data({ certName, certCatId, currentPage, pageSize } = {}) {
        return super.get('/data', {
            certName,
            certCatId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const certImage = json.data.data.certImage;
            if (!certImage) {
                json.data.data.certImage = [];
            } else {
                json.data.data.certImage = certImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({ id, certName, certCatId, businessScope, applicationConditions, certImage, isShowHomePage, orderNum } = {}) {
        const certImageStr = certImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            certName,
            certCatId,
            businessScope,
            applicationConditions,
            certImage: certImageStr,
            isShowHomePage,
            orderNum,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    changeIsShowHomePage({ id, isShowHomePage } = {}) {
        return super.post('/changeIsShowHomePage', {
            id,
            isShowHomePage,
        });
    }
}

export default new Cert();
export { Cert };
