/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sv } from './Sv';

class ServantPaymentConfig extends Sv {
    constructor() {
        super();
        this.baseUrl += '/servantPaymentConfig';
    }

    data({ servantId } = {}) {
        if (servantId) {
            return super.get('/servantPaymentConfigList', {
                servantId,
            });
        } else {
            return super.get('/currServantPaymentConfigList', {});
        }
    }

    save({ servantId, configJson } = {}) {
        if (servantId) {
            return super.post('/save', {
                servantId,
                configJson,
            });
        } else {
            return super.post('/saveCurr', {
                configJson,
            });
        }
    }
}

export default new ServantPaymentConfig();
export { ServantPaymentConfig };
