/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';
import { GlobalVar } from '@vmf/shared';

class Document extends Gd {
    constructor() {
        super();
        this.baseUrl += '/document';
    }

    data({ documentName, documentSn, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            documentName,
            documentSn,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const documentFile = json.data.data.documentFile;
            const courseFileData = JSON.parse(documentFile);
            json.data.data.documentFile = courseFileData.pdfFile.split(',').reduce((prev, curr) => {
                prev.push({
                    name: curr,
                    address: GlobalVar.fileBasePath + curr,
                });
                return prev;
            }, []);
            return json;
        });
    }

    save({
        id,
        documentCatId,
        documentName,
        documentSn,
        documentTime,
        documentFile,
        isCharge,
        documentPrice,
        orderNum,
        isAvailable,
    } = {}) {
        const documentFileStr = documentFile
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post(
            '/save',
            {
                id,
                documentCatId,
                documentName,
                documentSn,
                documentTime,
                documentFile: documentFileStr,
                isCharge,
                documentPrice,
                orderNum,
                isAvailable,
            },
            {
                isLoading: true,
                timeout: 0,
            },
        );
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable }) {
        return super.post('/status', { id, isAvailable });
    }
}

export default new Document();
export { Document };
