<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <template slot="body">
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-card>
                    <!--<el-form-item
                        prop="certId"
                        label="证书"
                        label-width="6em"
                    >
                        <RemoteSelect
                            remote="/sv/cert/select"
                            placeholder="请输入"
                            :props="{id:'id',name:'certName'}"
                            v-model="formModel.certId"
                        />
                    </el-form-item>-->
                    <el-form-item
                        prop="courseName"
                        label="课程名称"
                        label-width="6em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.courseName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="courseImage"
                        label="课程图片"
                        label-width="6em"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.courseImage"
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="courseType"
                        label="课程类型"
                        label-width="6em"
                    >
                        <dictionaries-picker
                            type="courseType"
                            v-model="formModel.courseType"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="courseContent"
                        label="课程文件"
                        label-width="6em"
                        v-if="formModel.courseType == 1"
                    >
                        <Tinymce
                            :height="500"
                            image-action="/rs/attachment/uploadCmsArticleImage"
                            v-model="formModel.courseContent"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="courseImageFile"
                        label="课程文件"
                        label-width="6em"
                        v-if="formModel.courseType == 2"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="20"
                            v-model="formModel.courseImageFile"
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="courseVideoFile"
                        label="课程文件"
                        label-width="6em"
                        v-if="formModel.courseType == 3"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.courseVideoFile"
                            tips="(注：请上传SCORM1.2标准、FLV、MP4视频文件)"
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="coursePdfFile"
                        label="课程文件"
                        label-width="6em"
                        v-if="formModel.courseType == 4"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.coursePdfFile"
                            tips="请上传PDF文件"
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="courseUrlFile"
                        label="外部链接"
                        label-width="6em"
                        v-if="formModel.courseType == 100"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.courseUrlFile"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="courseVoiceFile"
                        label="课程语音"
                        label-width="6em"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.courseVoiceFile"
                            tips="(注：请上传语音文件)"
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="courseHour"
                        label="课时"
                        label-width="6em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="1"
                            v-model.number="formModel.courseHour"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="orderNum"
                        label="排序号"
                        label-width="6em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="1"
                            v-model.number="formModel.orderNum"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="courseDesc"
                        label="课程描述"
                        label-width="6em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.courseDesc"
                            auto-complete="off"
                        />
                    </el-form-item>
                </el-card>
                <el-card class="ma-t">
                    <el-form-item
                        prop="courseCatId"
                        label="课程分类"
                        label-width="6em"
                    >
                        <el-tree
                            ref="tree"
                            node-key="id"
                            :data="courseCatList"
                            :props="defaultProps"
                            show-checkbox
                        />
                    </el-form-item>
                </el-card>
                <el-card class="ma-t">
                    <el-form-item
                        prop="questionCatId"
                        label="题目分类"
                        label-width="6em"
                    >
                        <el-tree
                            ref="tree1"
                            node-key="id"
                            :data="questionCatList"
                            :props="defaultProps"
                            show-checkbox
                        />
                    </el-form-item>
                    <el-form-item
                        label-width="13em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-card>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { GlobalVar } from '@vmf/shared';

export default {
    name: 'CourseEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                courseCatId: '',
                certId: '',
                courseName: '',
                courseImage: [],
                courseVoiceFile: [],
                orderNum: 1,
                courseDesc: '',

                courseType: 1,
                courseImageFile: [],
                courseVideoFile: [],
                coursePdfFile: [],
                courseContent: '',
                courseUrlFile: '',
                courseHour: '',

                courseCatIdList: [],
                questionCatIdList: [],
            },
            // 表单校验规则
            formRules: {
                courseType: {
                    required: true,
                    message: '请选择课程类型',
                },
                courseName: {
                    required: true,
                    message: '请输入课程名称',
                    trigger: 'blur',
                },
                orderNum: {
                    required: true,
                    message: '请输入排序号',
                    trigger: 'blur',
                },
                courseHour: {
                    required: true,
                    message: '请输入课时',
                    trigger: 'blur',
                },
                courseImage: {
                    type: 'array',
                    required: true,
                    message: '请上传课程图片',
                },
                courseImageFile: {
                    type: 'array',
                    required: true,
                    message: '请上传课程文件',
                },
                courseVideoFile: {
                    type: 'array',
                    required: true,
                    message: '请上传课程文件',
                },
                coursePdfFile: {
                    type: 'array',
                    required: true,
                    message: '请上传课程文件',
                },
                courseContent: {
                    required: true,
                    message: '请输入课程内容',
                    trigger: 'blur',
                },
                courseUrlFile: {
                    required: true,
                    message: '请输入外部链接',
                    trigger: 'blur',
                },
            },
            selectApi: this.$api.Sv.CourseCat,
            defaultProps: {
                children: 'children',
                label: 'name',
            },
            courseCatList: [],
            questionCatList: [],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                let courseFile = null;
                if (this.formModel.courseType == 1) {
                    courseFile = this.formModel.courseContent;
                } else if (this.formModel.courseType == 2) {
                    courseFile = this.formModel.courseImageFile
                        .reduce((prev, curr) => {
                            prev.push(curr.name);
                            return prev;
                        }, [])
                        .join(',');
                } else if (this.formModel.courseType == 3) {
                    courseFile = this.formModel.courseVideoFile
                        .reduce((prev, curr) => {
                            prev.push(curr.name);
                            return prev;
                        }, [])
                        .join(',');
                } else if (this.formModel.courseType == 4) {
                    courseFile = this.formModel.coursePdfFile
                        .reduce((prev, curr) => {
                            prev.push(curr.name);
                            return prev;
                        }, [])
                        .join(',');
                } else if (this.formModel.courseType == 100) {
                    courseFile = this.formModel.courseUrlFile;
                }
                this.$api.Sv.Course.save({
                    ...this.formModel,
                    courseFile: courseFile,
                    courseCatIds: this.$refs.tree.getCheckedKeys().join(','),
                    questionCatIds: this.$refs.tree1.getCheckedKeys().join(','),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Sv.Course.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data.course;
                this.$utils.formModelMerge(this.formModel, res);
                if (this.formModel.courseType == 1) {
                    this.formModel.courseContent = res.courseFile;
                } else if (this.formModel.courseType == 2) {
                    const courseFile = res.courseFile;
                    if (!courseFile) {
                        this.formModel.courseImageFile = [];
                    } else {
                        this.formModel.courseImageFile = courseFile.split(',').reduce((prev, curr) => {
                            prev.push({
                                name: curr,
                                address: GlobalVar.fileBasePath + curr,
                            });
                            return prev;
                        }, []);
                    }
                } else if (this.formModel.courseType == 3) {
                    const courseFile = res.courseFile;
                    if (!courseFile) {
                        this.formModel.courseVideoFile = [];
                    } else {
                        this.formModel.courseVideoFile = courseFile.split(',').reduce((prev, curr) => {
                            prev.push({
                                name: curr,
                                address: GlobalVar.fileBasePath + curr,
                            });
                            return prev;
                        }, []);
                    }
                } else if (this.formModel.courseType == 4) {
                    const courseFile = res.courseFile;
                    if (!courseFile) {
                        this.formModel.coursePdfFile = [];
                    } else {
                        this.formModel.coursePdfFile = courseFile.split(',').reduce((prev, curr) => {
                            prev.push({
                                name: curr,
                                address: GlobalVar.fileBasePath + curr,
                            });
                            return prev;
                        }, []);
                    }
                } else if (this.formModel.courseType == 100) {
                    this.formModel.courseUrlFile = res.courseFile;
                }
                this.formModel.courseCatIdList = json.data.data.courseCatIdList;
                this.formModel.questionCatIdList = json.data.data.questionCatIdList;
                this.initCourseCatList();
                this.initQuestionCatList();
            });
        },
        initCourseCatList() {
            this.$api.Sv.CourseCat.selectAll().then(json => {
                this.courseCatList = this.$utils.Convert.listToTree(json.data.data);
                if (this.formModel.courseCatIdList) {
                    this.$refs.tree.setCheckedKeys(this.formModel.courseCatIdList);
                }
            });
        },
        initQuestionCatList() {
            this.$api.Sv.QuestionCat.selectAll().then(json => {
                this.questionCatList = this.$utils.Convert.listToTree(json.data.data);
                if (this.formModel.questionCatIdList) {
                    this.$refs.tree1.setCheckedKeys(this.formModel.questionCatIdList);
                }
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        } else {
            this.initCourseCatList();
            this.initQuestionCatList();
        }
    },
};
</script>

<style lang="scss">
</style>
