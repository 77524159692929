import { Mb } from '@/api/mb/Mb';

class PostComment extends Mb {
    constructor() {
        super();
        this.baseUrl += '/postComment';
    }

    data({ postId, memberName, content, isAvailable, approvalStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            postId,
            memberName,
            content,
            isAvailable,
            approvalStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', {
            id,
        });
    }

    auditPostComment({ id, auditStatus, auditRemarks }) {
        return super.post('/auditPostComment', {
            id,
            auditStatus,
            auditRemarks,
        });
    }

    status({ id, isAvailable } = {}) {
        return super.post('/status', {
            id,
            isAvailable,
        });
    }
}
export default new PostComment();
export { PostComment };
