<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <template>
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-form-item
                    prop="examGameType"
                    label="游戏类型"
                    label-width="8em"
                >
                    <dictionaries-picker
                        type="examGameType"
                        v-model="formModel.examGameType"
                    />
                </el-form-item>
                <el-form-item
                    prop="examGameName"
                    label="游戏名称"
                    label-width="8em"
                >
                    <el-input
                        v-model="formModel.examGameName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="paperTemplateId"
                    label="试卷模板"
                    label-width="8em"
                >
                    <RemoteSelect
                        remote="/ex/paperTemplate/select"
                        placeholder="请输入"
                        :props="{id:'id',name:'templateName'}"
                        v-model="formModel.paperTemplateId"
                    />
                </el-form-item>
                <el-form-item
                    prop="gameTime"
                    label="游戏时间"
                    label-width="8em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        v-model.number="formModel.gameTime"
                    />
                    秒
                </el-form-item>
                <el-form-item
                    prop="score"
                    label="答题得分"
                    label-width="8em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        v-model.number="formModel.score"
                    />
                    (每题?分)
                </el-form-item>
                <el-form-item
                    prop="gameNumDay"
                    label="每天参加次数"
                    label-width="8em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        v-model.number="formModel.gameNumDay"
                    />
                    为0不限制
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="是否可用"
                    label-width="8em"
                >
                    <el-switch
                        v-model="formModel.isAvailable"
                    />
                </el-form-item>
                <el-form-item
                    label-width="13em"
                    class="ma-t"
                >
                    <el-button
                        type="primary"
                        size="small"
                        @click="onConfirm"
                    >
                        保 存
                    </el-button>
                    <el-button
                        size="small"
                        @click="$router.back()"
                    >
                        返 回
                    </el-button>
                </el-form-item>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ExamCheckEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                examGameType: '',
                examGameName: '',
                isAvailable: true,

                paperTemplateId: '',
                gameTime: '',
                score: '',
                gameNumDay: '',
            },
            examUserList: [],
            // 表单校验规则
            formRules: {
                examGameType: {
                    required: true,
                    message: '请选择游戏类型',
                },
                examGameName: {
                    required: true,
                    message: '请输入游戏名称',
                    trigger: 'blur',
                },
                paperTemplateId: {
                    required: true,
                    message: '请选择试卷模板',
                },
                gameTime: {
                    required: true,
                    message: '请输入游戏时间',
                    trigger: 'blur',
                },
                score: {
                    required: true,
                    message: '请输入答题得分',
                    trigger: 'blur',
                },
                gameNumDay: {
                    required: true,
                    message: '请输入每天参加次数',
                    trigger: 'blur',
                },
                isAvailable: {
                    required: true,
                    message: '请选择是否可用',
                },
            },
        };
    },
    watch: {
        'formModel.certId': function(newVal, oldVal) {
            if (oldVal && oldVal !== newVal) {
                this.formModel.paperTemplateId = undefined;
            }
        },
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Ex.ExamGame.save({
                    ...this.formModel,
                    examGameConfig: JSON.stringify({
                        paperTemplateId: this.formModel.paperTemplateId,
                        gameTime: this.formModel.gameTime,
                        score: this.formModel.score,
                        gameNumDay: this.formModel.gameNumDay,
                    }),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Ex.ExamGame.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
                this.$utils.formModelMerge(this.formModel, JSON.parse(res.examGameConfig));
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
