<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="applyNo"
                    label="报名编号"
                >
                    <el-input
                        v-model="queryFormModel.applyNo"
                        placeholder="请输入报名编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="examId"
                    label="考试项目"
                >
                    <RemoteSelect
                        remote="/ex/exam/selectByPage"
                        placeholder="请选择"
                        :props="{id:'id',name:'examName'}"
                        v-model="queryFormModel.examId"
                    />
                </el-form-item>
                <el-form-item
                    prop="fullName"
                    label="姓名"
                >
                    <el-input
                        v-model="queryFormModel.fullName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="手机号"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入手机号"
                    />
                </el-form-item>
                <el-form-item
                    prop="companyName"
                    label="原公司名称"
                >
                    <el-input
                        v-model="queryFormModel.companyName"
                        placeholder="请输入原公司名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="currentCompanyName"
                    label="现公司名称"
                >
                    <el-input
                        v-model="queryFormModel.currentCompanyName"
                        placeholder="请输入现公司名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="tags"
                    label="标签"
                >
                    <el-input
                        v-model="queryFormModel.tags"
                        placeholder="请输入标签"
                    />
                </el-form-item>
                <el-form-item
                    prop="payAuditUserName"
                    label="审核人"
                >
                    <el-input
                        v-model="queryFormModel.payAuditUserName"
                        placeholder="请输入审核人"
                    />
                </el-form-item>
                <el-form-item
                    prop="examOrgId"
                    label="组织机构"
                >
                    <CascaderPicker
                        check-strictly
                        :api-class="selectApi"
                        v-model="queryFormModel.examOrgId"
                        :query-params="cascaderPickerQueryParams"
                    />
                </el-form-item>
                <el-form-item
                    prop="applyStatus"
                    label="报名状态"
                >
                    <dictionaries-picker
                        type="applyStatus"
                        v-model="queryFormModel.applyStatus"
                    />
                </el-form-item>
                <el-form-item
                    prop="examApplyTime"
                    label="报名时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.examApplyTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="examApplyPaperTime"
                    label="考试时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.examApplyPaperTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        导出
                    </el-button>
                    <el-button
                        type="primary"
                        size="small"
                        @click="onBatchSetViewCourse(true)"
                        :disabled="!currentTableSelect.length"
                        v-if="$power(['examApply:setViewCourse'])"
                    >
                        批量设置允许查看课程
                    </el-button>
                    <el-button
                        type="primary"
                        size="small"
                        @click="onBatchSetViewCourse(false)"
                        :disabled="!currentTableSelect.length"
                        v-if="$power(['examApply:setViewCourse'])"
                    >
                        批量设置禁止查看课程
                    </el-button>
                    <el-button
                        type="primary"
                        size="small"
                        @click="onBatchSetTags()"
                        :disabled="!currentTableSelect.length"
                        v-if="$power(['examApply:setTags'])"
                    >
                        批量设置标签
                    </el-button>
                    <el-button
                        type="primary"
                        size="small"
                        @click="onBatchFreeCourse()"
                        :disabled="!currentTableSelect.length"
                        v-if="$power(['examApply:freeCourse'])"
                    >
                        批量免学时
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="applyNo"
                    label="报名编号"
                    width="175"
                />
                <el-table-column
                    prop="examName"
                    label="考试项目"
                    min-width="100"
                />
                <el-table-column
                    prop="fullName"
                    label="姓名"
                    width="80"
                />
                <el-table-column
                    prop="mobile"
                    label="电话"
                    width="100"
                />
                <el-table-column
                    prop="sexName"
                    label="性别"
                    width="50"
                />
                <el-table-column
                    prop="idNumber"
                    label="身份证"
                    width="145"
                />
                <el-table-column
                    prop="companyName"
                    label="原公司名称"
                    min-width="100"
                />
                <el-table-column
                    prop="currentCompanyName"
                    label="现公司名称"
                    min-width="100"
                />
                <el-table-column
                    prop="examOrgNamePath"
                    label="组织机构"
                    min-width="100"
                />
                <el-table-column
                    label="查看课程"
                    width="80"
                >
                    <template slot-scope="scope">
                        {{ scope.row.isViewCourse ? '允许' : '禁止' }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="applyStatusName"
                    label="报名状态"
                    width="80"
                />
                <el-table-column
                    label="报名时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.examApplyTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="completionRatio"
                    label="学习进度"
                    width="80"
                >
                    <template slot-scope="scope">
                        {{ scope.row.completionRatio }}%
                    </template>
                </el-table-column>
                <el-table-column
                    prop="examApplyPaperNum"
                    label="考试次数"
                    width="80"
                />
                <el-table-column
                    prop="examScore"
                    label="考试成绩"
                    width="80"
                />
                <el-table-column
                    label="考试时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.examApplyPaperTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="tags"
                    label="标签"
                    min-width="100"
                />
                <el-table-column
                    prop="payAuditUserName"
                    label="审核人"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="240"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onEdit(scope.row)"
                            v-if="$power(['examApply:edit'])"
                        >
                            修改信息
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/ex/examApply/examApply/paperList/' + scope.row.id)"
                            v-if="scope.row.applyStatus !== 10 && $power(['examApply:viewPaper'])"
                        >
                            查看试卷
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onPay(scope.row)"
                            v-if="scope.row.applyStatus === 10 && $power(['examApply:onPay'])"
                        >
                            付款
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onSetTags(scope.row)"
                            v-if="$power(['examApply:setTags'])"
                        >
                            设置标签
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onFreeCourse(scope.row.id)"
                            v-if="$power(['examApply:freeCourse']) && scope.row.applyStatus === 20"
                        >
                            免学时
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <el-dialog
            title="线下付款"
            center
            width="1200px"
            :visible.sync="payDialog.isVisible"
            @closed="onPayDialogCancel"
        >
            <el-form
                ref="payForm"
                size="small"
                :model="payDialog.formModel"
                :rules="payDialog.formRules"
            >
                <el-form-item
                    prop="payVoucher"
                    label="凭证"
                    label-width="8em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="10"
                        v-model="payDialog.formModel.payVoucher"
                        :oss-action="$globalVar.appConfig.OSS_ACTION"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentComments"
                    label="备注"
                    label-width="8em"
                >
                    <el-input
                        type="textarea"
                        v-model="payDialog.formModel.paymentComments"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onPayDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onPayDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!-- 修改信息 -->
        <el-dialog
            title="修改信息"
            center
            width="600px"
            :visible.sync="editDialog.isVisible"
            @closed="onEditDialogCancel"
        >
            <el-form
                ref="editForm"
                size="small"
                :model="editDialog.formModel"
                :rules="editDialog.formRules"
            >
                <el-form-item
                    prop="fullName"
                    label="姓名"
                    label-width="8em"
                >
                    <el-input
                        v-model="editDialog.formModel.fullName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="sex"
                    label="性别"
                    label-width="8em"
                >
                    <dictionaries-picker
                        type="sex"
                        v-model="editDialog.formModel.sex"
                    />
                </el-form-item>
                <el-form-item
                    prop="idNumber"
                    label="身份证号"
                    label-width="8em"
                >
                    <el-input
                        v-model="editDialog.formModel.idNumber"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="companyName"
                    label="原公司名称"
                    label-width="8em"
                >
                    <el-input
                        v-model="editDialog.formModel.companyName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="currentCompanyName"
                    label="现公司名称"
                    label-width="8em"
                >
                    <el-input
                        v-model="editDialog.formModel.currentCompanyName"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onEditDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onEditDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!-- 设置标签 -->
        <el-dialog
            title="设置标签"
            center
            width="600px"
            :visible.sync="tagsDialog.isVisible"
            @closed="onTagsDialogCancel"
        >
            <el-form
                ref="tagsForm"
                size="small"
                :model="tagsDialog.formModel"
                :rules="tagsDialog.formRules"
            >
                <el-form-item
                    prop="tags"
                    label="标签"
                    label-width="6em"
                >
                    <el-input
                        v-model="tagsDialog.formModel.tags"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onTagsDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onTagsDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'ExamApply',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                applyNo: '',
                examId: '',
                fullName: '',
                mobile: '',
                examOrgId: '',
                applyStatus: '',
                tags: '',
                examApplyTime: [],
                examApplyPaperTime: [],
                payAuditUserName: '',
                companyName: '',
                currentCompanyName: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            selectApi: this.$api.Rs.Org,
            cascaderPickerQueryParams: {
                isHideTop: 1,
            },
            payDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    payVoucher: [],
                    paymentComments: '',
                },
                // 表单校验规则
                formRules: {
                    payVoucher: {
                        type: 'array',
                        required: true,
                        message: '请上传单据',
                    },
                },
            },
            editDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    fullName: '',
                    sex: '',
                    idNumber: '',
                    companyName: '',
                    currentCompanyName: '',
                },
                // 表单校验规则
                formRules: {
                    fullName: {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur',
                    },
                    sex: {
                        required: true,
                        message: '请选择性别',
                        trigger: 'blur',
                    },
                    idNumber: {
                        required: true,
                        message: '请输入身份证号',
                        trigger: 'blur',
                    },
                    companyName: {
                        required: true,
                        message: '请输入原公司名称',
                        trigger: 'blur',
                    },
                    currentCompanyName: {
                        required: true,
                        message: '请输入现公司名称',
                        trigger: 'blur',
                    },
                },
            },
            // 设置标签
            tagsDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    ids: '',
                    tags: '',
                },
                // 表单校验规则
                formRules: {},
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.ExamApply.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        exportExcel() {
            let examApplyTimeBegin = null;
            let examApplyTimeEnd = null;
            if (this.queryFormModel.examApplyTime != null && this.queryFormModel.examApplyTime.length === 2) {
                examApplyTimeBegin = this.queryFormModel.examApplyTime[0];
                examApplyTimeEnd = this.queryFormModel.examApplyTime[1];
            }
            let examApplyPaperTimeBegin = null;
            let examApplyPaperTimeEnd = null;
            if (this.queryFormModel.examApplyPaperTime != null && this.queryFormModel.examApplyPaperTime.length === 2) {
                examApplyPaperTimeBegin = this.queryFormModel.examApplyPaperTime[0];
                examApplyPaperTimeEnd = this.queryFormModel.examApplyPaperTime[1];
            }
            this.$http.href('/ex/examApply/exportExcel', {
                ...this.queryFormModel,
                examApplyTimeBegin,
                examApplyTimeEnd,
                examApplyPaperTimeBegin,
                examApplyPaperTimeEnd,
            });
        }, //付款
        onPay(row) {
            this.payDialog.formModel.id = row.id;
            this.payDialog.isVisible = true;
        },
        onPayDialogConfirm() {
            const { payForm } = this.$refs;
            payForm.validate().then(() => {
                this.$api.Ex.ExamApply.payExamApply({
                    ...this.payDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    if (res.success) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                        this.payDialog.isVisible = false;
                    }
                });
            });
        },
        onPayDialogCancel() {
            const { payForm } = this.$refs;
            payForm.resetFields();
            const { formModel } = this.payDialog;
            formModel.id = '';
            this.payDialog.isVisible = false;
        },
        onEdit(row) {
            const { formModel } = this.editDialog;
            this.$api.Ex.ExamApply.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
            });
            this.editDialog.isVisible = true;
        },
        onEditDialogConfirm() {
            const { editForm } = this.$refs;
            editForm.validate().then(() => {
                this.$api.Ex.ExamApply.editExamApply({
                    ...this.editDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.editDialog.isVisible = false;
                });
            });
        },
        onEditDialogCancel() {
            const { editForm } = this.$refs;
            editForm.resetFields();
            const { formModel } = this.editDialog;
            formModel.id = '';
            this.editDialog.isVisible = false;
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onBatchSetViewCourse(val) {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: '请选择数据',
                    type: 'error',
                });
                return;
            }
            this.$confirm('是否批量设置' + (val ? '允许' : '禁止') + '查看课程！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.ExamApply.batchSetViewCourse({ ids: ids.join(','), isViewCourse: val }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onBatchSetTags() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: '请选择数据',
                    type: 'error',
                });
                return;
            }
            this.tagsDialog.isVisible = true;
            this.$nextTick(() => {
                const { tagsForm } = this.$refs;
                tagsForm.resetFields();
                const { formModel } = this.tagsDialog;
                formModel.ids = ids.join(',');
            });
        },
        onSetTags(row) {
            const { formModel } = this.tagsDialog;
            formModel.tags = row.tags;
            formModel.ids = row.id;
            this.tagsDialog.isVisible = true;
        },
        onTagsDialogConfirm() {
            const { tagsForm } = this.$refs;
            tagsForm.validate().then(() => {
                this.$api.Ex.ExamApply.saveTags({
                    ...this.tagsDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.tagsDialog.isVisible = false;
                });
            });
        },
        onTagsDialogCancel() {
            const { tagsForm } = this.$refs;
            tagsForm.resetFields();
            const { formModel } = this.tagsDialog;
            formModel.ids = '';
            this.tagsDialog.isVisible = false;
        },
        onFreeCourse(ids) {
            this.$confirm('是否确定要免学时！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.ExamApply.freeCourse({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onBatchFreeCourse() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onFreeCourse(ids.join(','));
        },
    },
};
</script>

<style lang="scss">
</style>
