/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import CouponGive from './CouponGive';

export default {
    CouponGive,
};
