/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sv } from './Sv';
import { GlobalVar } from '@vmf/shared';

class Course extends Sv {
    constructor() {
        super();
        this.baseUrl += '/course';
    }

    data({ courseName, courseCatId, ignoreIds, currentPage, pageSize } = {}) {
        return super.get('/data', {
            courseName,
            courseCatId,
            ignoreIds,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const courseImage = json.data.data.course.courseImage;
            if (!courseImage) {
                json.data.data.course.courseImage = [];
            } else {
                json.data.data.course.courseImage = courseImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const courseVoiceFile = json.data.data.course.courseVoiceFile;
            if (!courseVoiceFile) {
                json.data.data.course.courseVoiceFile = [];
            } else {
                json.data.data.course.courseVoiceFile = courseVoiceFile.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        id,
        courseCatId,
        certId,
        courseName,
        courseImage,
        courseType,
        courseFile,
        courseVoiceFile,
        orderNum,
        courseHour,
        courseDesc,
        courseCatIds,
        questionCatIds,
    } = {}) {
        const courseImageStr = courseImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const courseVoiceFileStr = courseVoiceFile
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            courseCatId,
            certId,
            courseName,
            courseImage: courseImageStr,
            courseType,
            courseFile,
            courseVoiceFile: courseVoiceFileStr,
            orderNum,
            courseHour,
            courseDesc,
            courseCatIds,
            questionCatIds,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    changeCourseStatus({ id, courseStatus } = {}) {
        return super.post('/changeCourseStatus', {
            id,
            courseStatus,
        });
    }
}

export default new Course();
export { Course };
