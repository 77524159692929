/**
 * Created by henian.xu on 2019/10/31.
 *
 */

import Api from './index';

export default vue => {
    // Api
    /*Object.defineProperty(vue, '$api', {
        get() {
            return Api;
        },
    });
    Object.defineProperty(vue.prototype, '$api', {
        get() {
            return Api;
        },
    });*/
    vue.prototype.$api = Api;
};
