<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区1 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="examName"
                    label="考试名称"
                >
                    <el-input
                        v-model="queryFormModel.examName"
                        placeholder="请输入考试名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="certId"
                    label="证书"
                >
                    <RemoteSelect
                        remote="/sv/cert/select"
                        placeholder="请选择"
                        :props="{id:'id',name:'certName'}"
                        v-model="queryFormModel.certId"
                    />
                </el-form-item>
                <el-form-item
                    prop="examStatus"
                    label="考试状态"
                >
                    <dictionaries-picker
                        type="examStatus"
                        v-model="queryFormModel.examStatus"
                    />
                </el-form-item>
                <el-form-item
                    prop="isShowHomePage"
                    label="是否首页展示"
                >
                    <el-select
                        v-model="queryFormModel.isShowHomePage"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="是"
                            value="1"
                        />
                        <el-option
                            label="否"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/ex/exam/exam/edit')"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <!--<el-table-column
                    prop="examTypeName"
                    label="考试类型"
                    min-width="100"
                />-->
                <el-table-column
                    prop="examName"
                    label="考试名称"
                    min-width="100"
                />
                <el-table-column
                    prop="certName"
                    label="证书名称"
                    min-width="100"
                />
                <el-table-column
                    label="报名时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.applyBeginTime | moment('YYYY-MM-DD HH:mm:ss') }}至
                        {{ scope.row.applyEndTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="考试时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.examBeginTime | moment('YYYY-MM-DD HH:mm:ss') }}至
                        {{ scope.row.examEndTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="examApplyNum"
                    label="报名人数"
                    width="70"
                />
                <el-table-column
                    prop="totalExamNum"
                    label="考试人数"
                    width="70"
                />
                <el-table-column
                    prop="totalExamPassNum"
                    label="考试通过人数"
                    width="100"
                />
                <el-table-column
                    prop="totalExamNotPassNum"
                    label="考试不通过人数"
                    width="110"
                />
                <el-table-column
                    label="是否首页展示"
                    width="100"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isShowHomePage"
                            @input="onIsShowHomePageInput($event,scope)"
                            :active-value="true"
                            :inactive-value="false"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    label="是否发布"
                    width="70"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.examStatus"
                            @input="onExamStatusInput($event,scope)"
                            :active-value="2"
                            :inactive-value="1"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="orderNum"
                    label="排序号"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="210"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/ex/exam/exam/edit/' + scope.row.id)"
                            v-if="scope.row.examStatus == 1"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/ex/examApply/examApply?examId=' + scope.row.id)"
                            v-if="scope.row.examStatus !== 1"
                        >
                            报名列表
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                            v-if="scope.row.examStatus == 1 && scope.row.examApplyNum == 0"
                        >
                            删除
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/ex/exam/exam/examMember/' + scope.row.id)"
                            v-if="scope.row.isUseExamStudent"
                        >
                            考试名单
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onSendExamSuggest(scope.row.id)"
                            v-if="scope.row.examStatus == 1 && scope.row.isUseExamStudent && !scope.row.isSendExamSuggest == 0"
                        >
                            发送考试提醒
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/ex/exam/exam/examStat/' + scope.row.id)"
                            v-if="scope.row.isExamOrg"
                        >
                            统计报表
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/ex/exam/exam/examCompanyStat/' + scope.row.id)"
                            v-if="!scope.row.isExamOrg"
                        >
                            统计报表
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Exam',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                examName: '',
                certId: '',
                examStatus: '',
                examType: '1',
                isShowHomePage: '',
            },
            // 表格数据
            tableData: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.Exam.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.Exam.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onSendExamSuggest(ids) {
            this.$confirm('是否确定要发送考试提醒！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.Exam.sendExamSuggest({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onExamStatusInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Ex.Exam.changeExamStatus({
                id: row.id,
                examStatus: row.examStatus,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
            });
        },
        onIsShowHomePageInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Ex.Exam.changeIsShowHomePage({
                id: row.id,
                isShowHomePage: row.isShowHomePage,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
            });
        },
    },
};
</script>

<style lang="scss">
</style>
