<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <!-- 内容 -->
        <div
            class="body"
            slot="body"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="exportExcel"
                    >
                        导出报表
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="companyName"
                    label="单位名称"
                    min-width="200"
                />
                <el-table-column
                    prop="totalApplyNum"
                    label="报名人数"
                    min-width="65"
                />
                <el-table-column
                    prop="totalWaitApplyNum"
                    label="未报名人数"
                    min-width="70"
                />
                <el-table-column
                    prop="totalExamNum"
                    label="考试人数"
                    min-width="65"
                />
                <el-table-column
                    prop="totalExamPassNum"
                    label="考试通过人数"
                    min-width="75"
                />
                <el-table-column
                    prop="totalExamNotPassNum"
                    label="考试不通过人数"
                    min-width="85"
                />
                <el-table-column
                    prop="totalExamPer"
                    label="到考率"
                    min-width="65"
                >
                    <template slot-scope="scope">
                        {{ scope.row.totalExamPer }}%
                    </template>
                </el-table-column>
                <el-table-column
                    prop="totalExamPassPer"
                    label="考试通过率"
                    min-width="70"
                >
                    <template slot-scope="scope">
                        {{ scope.row.totalExamPassPer }}%
                    </template>
                </el-table-column>
                <el-table-column
                    prop="totalAvgExamScore"
                    label="成绩平均值"
                    min-width="70"
                >
                    <template slot-scope="scope">
                        {{ scope.row.totalAvgExamScore }}
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </page>
</template>

<script>
export default {
    name: 'ExamCompanyStat',
    data() {
        return {
            // 表格数据
            tableData: [],
            examId: '',
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        init() {
            return this.$api.Ex.Exam.getExamCompanyStat({ examId: this.examId }).then(json => {
                const res = json.data;
                this.tableData = res.data.result;
            });
        },
        exportExcel() {
            this.$http.href('/ex/exam/exportExamCompanyStatExcel', { examId: this.examId });
        },
    },
    created() {
        this.examId = this.$route.params.examId;
        this.init();
    },
};
</script>

<style lang="scss">
</style>
<style lang="scss">
.out-in-warehouse-echarts {
    width: 100%;
}

.out-in-warehouse {
    background-color: #fff;
}
</style>
