<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.jobWantedStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"
                />
                <el-tab-pane
                    label="未提交"
                    name="10"
                />
                <el-tab-pane
                    label="待审核"
                    name="20"
                />
                <el-tab-pane
                    label="上架展示"
                    name="100"
                />
                <el-tab-pane
                    label="下架隐藏"
                    name="98"
                />
                <el-tab-pane
                    label="审核不通过"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="memberName"
                    label="发布者"
                >
                    <el-input
                        v-model="queryFormModel.memberName"
                        placeholder="请输入发布者"
                    />
                </el-form-item>
                <el-form-item
                    prop="fullName"
                    label="姓名"
                >
                    <el-input
                        v-model="queryFormModel.fullName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="sex"
                    label="性别"
                >
                    <dictionaries-picker
                        type="sex"
                        v-model="queryFormModel.sex"
                    />
                </el-form-item>
                <el-form-item
                    prop="tel"
                    label="联系电话"
                >
                    <el-input
                        v-model="queryFormModel.tel"
                        placeholder="请输入联系电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="regionId"
                    label="省市区"
                >
                    <CascaderPicker
                        check-strictly
                        :api-class="regionApi"
                        v-model="queryFormModel.regionId"
                        :p-id="2"
                        :min-lv="2"
                    />
                </el-form-item>
                <el-form-item
                    prop="jobIntention"
                    label="求职意向"
                >
                    <el-input
                        v-model="queryFormModel.jobIntention"
                        placeholder="请输入求职意向"
                    />
                </el-form-item>
                <el-form-item
                    prop="salaryExpectation"
                    label="期望薪资"
                >
                    <el-input
                        v-model="queryFormModel.salaryExpectation"
                        placeholder="请输入期望薪资"
                    />
                </el-form-item>
                <el-form-item
                    prop="professionalSkills"
                    label="专业技能"
                >
                    <el-input
                        v-model="queryFormModel.professionalSkills"
                        placeholder="请输入专业技能"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAdministrator"
                >
                    <el-checkbox
                        v-model="queryFormModel.isAdministrator"
                        @change="()=>{ if (this.queryFormModel.isAdministrator) {
                            this.queryFormModel.memberName = '';
                        } }"
                    >
                        是否管理员发布
                    </el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/mb/jobWanted/jobWanted/edit')"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="memberName"
                    label="发布者"
                    min-width="100"
                />
                <el-table-column
                    prop="fullName"
                    label="名称"
                    min-width="100"
                />
                <el-table-column
                    prop="sexName"
                    label="性别"
                    min-width="100"
                />
                <el-table-column
                    prop="tel"
                    label="联系电话"
                    min-width="100"
                />
                <el-table-column
                    prop="regionName"
                    label="省市区"
                    min-width="120"
                />
                <el-table-column
                    prop="jobIntention"
                    label="求职意向"
                    min-width="100"
                />
                <el-table-column
                    prop="salaryExpectation"
                    label="期望薪资"
                    min-width="100"
                />
                <el-table-column
                    prop="professionalSkills"
                    label="专业技能"
                    min-width="200"
                />
                <el-table-column
                    prop="jobWantedStatusName"
                    label="求职信息状态"
                    min-width="100"
                    v-if="queryFormModel.jobWantedStatus === '0'"
                />
                <el-table-column
                    label="创建时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/jobWanted/jobWanted/view/' +
                                scope.row.id)"
                        >
                            查看
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/jobWanted/jobWanted/edit/' +
                                scope.row.id)"
                            v-if="+scope.row.jobWantedStatus !== 20 && !scope.row.memberId"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onShelf(scope.row.id)"
                            v-if="+scope.row.jobWantedStatus === 98"
                        >
                            上架
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="offShelf(scope.row.id)"
                            v-if="+scope.row.jobWantedStatus === 100"
                        >
                            下架
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                            v-if="+scope.row.jobWantedStatus === 10 && !scope.row.memberId"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'JobWanted',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                isAdministrator: false,
                memberName: '',
                fullName: '',
                sex: '',
                tel: '',
                jobIntention: '',
                salaryExpectation: '',
                professionalSkills: '',
                jobWantedStatus: '0',
                regionId: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            regionApi: this.$api.Rs.Region,
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.JobWanted.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onShelf(id) {
            this.$confirm('是否上架！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '上架中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Mb.JobWanted.onShelf({
                    id,
                })
                    .then(json => {
                        this.loading.close();
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    })
                    .catch(() => {
                        this.loading.close();
                    });
            });
        },

        offShelf(id) {
            this.$confirm('是否下架！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '下架中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Mb.JobWanted.offShelf({
                    id,
                })
                    .then(json => {
                        this.loading.close();
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    })
                    .catch(() => {
                        this.loading.close();
                    });
            });
        },

        onTableDelete(id) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Mb.JobWanted.delete({ id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
