/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class OrgMember extends Ex {
    constructor() {
        super();
        this.baseUrl += '/orgMember';
    }

    data({ fullName, mobile, idCard, examOrgId, orgMemberCatId, currentPage, pageSize } = {}) {
        return super.get('/data', {
            fullName,
            mobile,
            idCard,
            examOrgId,
            orgMemberCatId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, mobile, fullName, idCard, examOrgId, orgMemberCatId } = {}) {
        return super.post('/save', {
            id,
            mobile,
            fullName,
            idCard,
            examOrgId,
            orgMemberCatId,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new OrgMember();
export { OrgMember };
