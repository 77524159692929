import { Mb } from '@/api/mb/Mb';

class JobWanted extends Mb {
    constructor() {
        super();
        this.baseUrl += '/jobWanted';
    }

    data({
        isAdministrator,
        fullName,
        sex,
        tel,
        jobIntention,
        salaryExpectation,
        professionalSkills,
        jobWantedStatus,
        regionId,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            isAdministrator,
            fullName,
            sex,
            tel,
            jobIntention,
            salaryExpectation,
            professionalSkills,
            jobWantedStatus,
            regionId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id, isLog }) {
        return super.get('/get', {
            id,
            isLog,
        });
    }

    save({
        id,
        fullName,
        sex,
        tel,
        jobIntention,
        salaryExpectation,
        workExperience,
        selfEvaluation,
        professionalSkills,
        regionId,
        isSubmit,
    }) {
        return super.post('/save', {
            id,
            fullName,
            sex,
            tel,
            jobIntention,
            salaryExpectation,
            workExperience,
            selfEvaluation,
            professionalSkills,
            regionId,
            isSubmit,
        });
    }

    auditJobWanted({ id, auditStatus, auditRemarks }) {
        return super.post('/auditJobWanted', {
            id,
            auditStatus,
            auditRemarks,
        });
    }

    onShelf({ id }) {
        return super.post('/onShelf', { id });
    }

    offShelf({ id }) {
        return super.post('/offShelf', { id });
    }

    delete({ id }) {
        return super.post('/delete', { id });
    }
}
export default new JobWanted();
export { JobWanted };
