<!-- Created by henian.xu on 2018/7/31. -->

<template>
    <page>
        <div>
            {{ formModel.remotePricker }}
            <RemoteSelect
                remote="/gd/prop/selectByPage"
                :props="{id:'id',name:'propName'}"
                :remote-params="remoteParamsData"
                filterable
                :allow-create="onAllowCreateMethod"
                default-first-option
                :exclude="[16,14,36]"
                :init-data="initData"
                v-model="formModel.remotePricker"
            />
            <RemoteSelect
                remote="/rs/dic/entry/orderType"
                filterable
                :init-data="orderTypeInitData"
                v-model="formModel.orderType"
            />
            <RemoteSelect
                remote="/rs/dic/entry/orderType"
                filterable
                expand-all
                show-all-item
                :init-data="orderTypeInitData"
                v-model="formModel.orderType"
            />
        </div>
    </page>
</template>

<script>
export default {
    name: 'FormDemo',
    data() {
        return {
            formModel: {
                goodsType: '',
                remotePricker: '',
                orderType: '',
            },
            initData: [],
            orderTypeInitData: [],
            remoteParamsData: {},
        };
    },
    methods: {
        async onAllowCreateMethod(name) {
            console.log(name);
            // return 123123;
            const {
                data: { data },
            } = await this.$api.Gd.Prop.saveProp({ propName: name });
            return data;
        },
    },
    mounted() {
        setTimeout(() => {
            // this.initData = [{ id: 11, propName: '颜色' }];
            // this.orderTypeInitData = [{ id: 100, name: '普通订单' }];
            setTimeout(() => {
                this.formModel.remotePricker = 11;
                this.formModel.orderType = 100;
                this.remoteParamsData = { adf: 123 };
            }, 2000);
        }, 2000);
    },
};
</script>

<style lang="scss">
</style>
