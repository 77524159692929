/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class ExamMember extends Ex {
    constructor() {
        super();
        this.baseUrl += '/examMember';
    }

    data({ examId, fullName, mobile, examOrgId, tags, currentPage, pageSize } = {}) {
        return super.get('/data', {
            examId,
            fullName,
            mobile,
            examOrgId,
            tags,
            currentPage,
            pageSize,
        });
    }
}

export default new ExamMember();
export { ExamMember };
