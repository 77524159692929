<template>
    <page back>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="classDate"
                    label="开班日期"
                >
                    <el-date-picker
                        v-model="queryFormModel.classDate"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="fullName"
                    label="姓名"
                >
                    <el-input
                        v-model="queryFormModel.fullName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="sex"
                    label="性别"
                >
                    <dictionaries-picker
                        type="sex"
                        v-model="queryFormModel.sex"
                    />
                </el-form-item>
                <el-form-item
                    prop="tel"
                    label="电话号码"
                >
                    <el-input
                        v-model="queryFormModel.tel"
                        placeholder="请输入电话号码"
                    />
                </el-form-item>
                <el-form-item
                    prop="idCardNo"
                    label="身份证号码"
                >
                    <el-input
                        v-model="queryFormModel.idCardNo"
                        placeholder="请输入身份证号码"
                    />
                </el-form-item>
                <el-form-item
                    prop="companyName"
                    label="单位名称"
                >
                    <el-input
                        v-model="queryFormModel.companyName"
                        placeholder="请输入单位名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="workRegionName"
                    label="现就业地市"
                >
                    <el-input
                        v-model="queryFormModel.workRegionName"
                        placeholder="请输入现就业地市"
                    />
                </el-form-item>
                <el-form-item
                    prop="workStatus"
                    label="就业状态"
                >
                    <el-input
                        v-model="queryFormModel.workStatus"
                        placeholder="请输入就业状态"
                    />
                </el-form-item>
                <el-form-item
                    prop="isCertificate"
                    label="是否持证"
                >
                    <el-select
                        v-model="queryFormModel.isCertificate"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="是"
                            value="1"
                        />
                        <el-option
                            label="否"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="isMakeUp"
                    label="是否补考"
                >
                    <el-select
                        v-model="queryFormModel.isMakeUp"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="是"
                            value="1"
                        />
                        <el-option
                            label="否"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="isWarning"
                    label="是否预警"
                >
                    <el-select
                        v-model="queryFormModel.isWarning"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="是"
                            value="1"
                        />
                        <el-option
                            label="否"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push(`/ex/registerForm/registerForm/edit/${queryFormModel.formBatchId}`)"
                    >
                        新增
                    </el-button>
                    <el-button
                        class="ma-l"
                        type="success"
                        icon="el-icon-upload2"
                        size="small"
                        @click="onImport"
                    >
                        导入
                    </el-button>
                    <el-button
                        class="ma-l"
                        type="primary"
                        icon="el-icon-download"
                        size="small"
                        @click="exportAllRegisterForm"
                    >
                        导出全部登记表
                    </el-button>
                    <el-button
                        class="ma-l"
                        type="primary"
                        icon="el-icon-download"
                        size="small"
                        @click="exportRegisterForm"
                        :disabled="currentTableSelect.length===0"
                    >
                        导出登记表
                    </el-button>
                </el-button-group>
            </div>

            <el-upload
                v-show="false"
                ref="importUpload"
                class="upload-demo dp-ib ma-l"
                action="/ex/registerForm/importExcel"
                :show-file-list="false"
                accept=".xls,.xlsx"
                :on-success="onImportSuccess"
                :on-error="onImportError"
                :on-progress="onImportProgress"
                :data="{ formBatchId: queryFormModel.formBatchId }"
            />

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                row-key="id"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    :reserve-selection="true"
                    width="60"
                />
                <el-table-column
                    prop="isWarning"
                    label="预警情况"
                    width="80"
                >
                    <template v-slot="scope">
                        <span
                            style="color: red"
                            v-if="+scope.row.isWarning === 1"
                        >
                            信息缺失
                        </span>
                        <span v-else>
                            正常
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="batchName"
                    label="批次名称"
                    min-width="150"
                />
                <el-table-column
                    prop="batchSn"
                    label="批次编码"
                    min-width="150"
                />
                <el-table-column
                    prop="classDate"
                    label="开班日期"
                    width="90"
                >
                    <template v-slot="scope">
                        {{ scope.row.classDate | moment('YYYY-MM-DD') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="fullName"
                    label="姓名"
                    min-width="150"
                />
                <el-table-column
                    prop="registerMaterials"
                    label="报名材料"
                    min-width="150"
                />
                <el-table-column
                    prop="appointmentNumber"
                    label="预约考号"
                    min-width="150"
                />
                <el-table-column
                    prop="applicationDirection"
                    label="报考方向"
                    min-width="150"
                />
                <el-table-column
                    prop="sexName"
                    label="性别"
                    width="70"
                />
                <el-table-column
                    prop="education"
                    label="学历"
                    min-width="150"
                />
                <el-table-column
                    prop="graduatedSchool"
                    label="毕业学校"
                    min-width="150"
                />
                <el-table-column
                    prop="tel"
                    label="电话号码"
                    width="110"
                />
                <el-table-column
                    prop="idCardNo"
                    label="身份证号码"
                    min-width="150"
                />
                <el-table-column
                    prop="idCardAddress"
                    label="身份证地址"
                    min-width="150"
                />
                <el-table-column
                    prop="address"
                    label="常用地址"
                    min-width="150"
                />
                <el-table-column
                    prop="amount"
                    label="金额"
                    width="100"
                />
                <el-table-column
                    prop="billingMethod"
                    label="开票方式"
                    min-width="150"
                />
                <el-table-column
                    prop="paymentMethod"
                    label="付款方式"
                    min-width="150"
                />
                <el-table-column
                    prop="billingName"
                    label="开票名称"
                    min-width="150"
                />
                <el-table-column
                    prop="recommender"
                    label="推荐人"
                    min-width="150"
                />
                <el-table-column
                    prop="isCertificateName"
                    label="是否持证"
                    width="70"
                />
                <el-table-column
                    prop="changeShiftName"
                    label="换人班次名字"
                    min-width="150"
                />
                <el-table-column
                    prop="workRegionName"
                    label="现就业地市"
                    min-width="100"
                />
                <el-table-column
                    prop="workStatus"
                    label="就业状态"
                    min-width="100"
                />
                <el-table-column
                    prop="certLevel"
                    label="证书等级"
                    min-width="100"
                />
                <el-table-column
                    prop="certLevel"
                    label="证书等级"
                    min-width="100"
                />
                <el-table-column
                    prop="theoryScore"
                    label="理论考试成绩"
                    min-width="100"
                />
                <el-table-column
                    prop="practicalScore"
                    label="实操考试成绩"
                    min-width="100"
                />
                <el-table-column
                    prop="isMakeUpName"
                    label="是否补考"
                    width="70"
                />
                <el-table-column
                    prop="certSn"
                    label="证书编号"
                    min-width="150"
                />
                <el-table-column
                    prop="certDate"
                    label="证书日期"
                    width="90"
                >
                    <template v-slot="scope">
                        {{ scope.row.certDate | moment('YYYY-MM-DD') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="avatarUrl"
                    label="头像"
                    width="120"
                >
                    <template v-slot="scope">
                        <ImageList
                            :data="scope.row.avatarUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="idCardFrontUrl"
                    label="身份证正面"
                    width="120"
                >
                    <template v-slot="scope">
                        <ImageList
                            :data="scope.row.idCardFrontUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="idCardBackUrl"
                    label="身份证反面"
                    width="120"
                >
                    <template v-slot="scope">
                        <ImageList
                            :data="scope.row.idCardBackUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="commitmentImageUrl"
                    label="承诺书"
                    width="120"
                >
                    <template v-slot="scope">
                        <ImageList
                            :data="scope.row.commitmentImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="employmentImageUrl"
                    label="在职或待业证明"
                    width="120"
                >
                    <template v-slot="scope">
                        <ImageList
                            :data="scope.row.employmentImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="xuexinImageUrl"
                    label="学信网图片"
                    width="120"
                >
                    <template v-slot="scope">
                        <ImageList
                            :data="scope.row.xuexinImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="graduationImageUrl"
                    label="结业证"
                    width="120"
                >
                    <template v-slot="scope">
                        <ImageList
                            :data="scope.row.graduationImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120"
                >
                    <template v-slot="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/ex/registerForm/registerForm/view/' +
                                scope.row.id)"
                        >
                            查看
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push(`/ex/registerForm/registerForm/edit/${queryFormModel.formBatchId}/${scope.row.id}`)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'RegisterForm',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                formBatchId: '',
                fullName: '',
                sex: '',
                tel: '',
                idCardNo: '',
                isCertificate: '',
                isMakeUp: '',
                companyName: '',
                workRegionName: '',
                workStatus: '',
                classDate: [],
                isWarning: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            loading: '',
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.RegisterForm.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableDelete(id) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.RegisterForm.delete({
                    ids: id,
                    formBatchId: this.queryFormModel.formBatchId,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onImportSuccess(response) {
            this.loading.close();
            this.$alert(response.msg, '导入成功', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
            });
            this.onQuery(this.pagination);
        },
        onImportError(response) {
            this.loading.close();
            this.$alert(response.msg, '错误提示', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
            });
        },
        onImportProgress() {
            this.loading = this.$loading({
                lock: true,
                text: '导入中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
        },
        exportAllRegisterForm() {
            this.$http.href('/ex/registerForm/exportRegisterForm', {
                isAllExport: true,
                formBatchId: this.queryFormModel.formBatchId,
            });
        },
        exportRegisterForm() {
            if (this.currentTableSelect) {
                const ids = this.currentTableSelect.reduce((prev, next) => {
                    prev.push(next.id);
                    return prev;
                }, []);
                this.$http.href('/ex/registerForm/exportRegisterForm', {
                    isAllExport: false,
                    ids: ids.join(','),
                });
            }
        },
        onImport() {
            const { importUpload } = this.$refs;
            if (!importUpload) return;
            const uploadInput = importUpload.$refs['upload-inner'].$refs.input;
            uploadInput.click();
        },
    },
    created() {
        this.queryFormModel.formBatchId = this.$route.params.formBatchId;
    },
};
</script>

<style lang="scss">
</style>
