import { render, staticRenderFns } from "./goodsCat.vue?vue&type=template&id=6994be05&"
import script from "./goodsCat.vue?vue&type=script&lang=js&"
export * from "./goodsCat.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports