<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <div
            class="body"
            slot="body"
        >
            <div class="exam-result-wrap">
                <dl>
                    <dt>考试序号:</dt>
                    <dd>{{ examApply.applyNo }}</dd>
                </dl>
                <dl>
                    <dt>姓名:</dt>
                    <dd>{{ examApply.fullName }}</dd>
                </dl>
                <dl>
                    <dt>证件号:</dt>
                    <dd>{{ examApply.idNumber }}</dd>
                </dl>
                <dl>
                    <dt>手机号码:</dt>
                    <dd>{{ examApply.mobile }}</dd>
                </dl>
                <dl>
                    <dt>成绩:</dt>
                    <dd>{{ examApplyPaper.examScore }}</dd>
                </dl>
                <dl>
                    <dt>考试结果:</dt>
                    <dd>{{ examApplyPaper.examResultName }}</dd>
                </dl>
            </div>

            <QuestionBox
                v-for="(item,index) in examApplyPaper.questionList"
                :data="item"
                :index="index"
                :key="index"
                :is-edit="isEdit"
            />

            <div class="ma-t">
                <el-button
                    type="success"
                    size="small"
                    @click="onEditExamApplyPaper()"
                    v-if="!isEdit && $power(['examApply:editPaper'])"
                >
                    修改试卷
                </el-button>
                <el-button
                    type="success"
                    size="small"
                    @click="onSaveExamApplyPaper()"
                    v-if="isEdit"
                >
                    保存
                </el-button>
                <el-button
                    size="small"
                    @click="onCancelEdit"
                    v-if="isEdit"
                >
                    取 消
                </el-button>
            </div>
        </div>
    </page>
</template>

<script>
import QuestionBox from './_QuestionBox';

export default {
    name: 'ExamApplyPaperDetail',
    components: { QuestionBox },
    data() {
        return {
            examApplyPaperId: '',
            examApplyPaper: {},
            examApply: {},
            isEdit: false,
        };
    },
    methods: {
        init() {
            this.$api.Ex.ExamApplyPaper.getExamApplyPaperDetail({
                examApplyPaperId: this.examApplyPaperId,
            }).then(json => {
                this.examApplyPaper = json.data.data.examApplyPaper;
                this.examApply = json.data.data.examApply;
            });
        },
        onEditExamApplyPaper() {
            this.isEdit = !this.isEdit;
            if (!this.isEdit) {
                this.init();
            }
        },
        onSaveExamApplyPaper() {
            this.$confirm('是否确定修改成绩！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                const questionList = [];
                this.examApplyPaper.questionList.forEach(item => {
                    const questionItem = {};
                    questionItem.id = item.id;
                    if (item.questionType == 3) {
                        let result = 0;
                        item.selectOptionIdList.forEach(item => {
                            result = result | item;
                        });
                        questionItem.selectOption = result;
                    } else {
                        questionItem.selectOption = item.selectOption;
                    }
                    questionList.push(questionItem);
                });
                this.$api.Ex.ExamApplyPaper.editExamApplyPaper({
                    examApplyPaperId: this.examApplyPaperId,
                    questionJson: JSON.stringify(questionList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.isEdit = false;
                    this.init();
                });
            });
        },
        onCancelEdit() {
            this.isEdit = false;
            this.init();
        },
    },
    created() {
        this.examApplyPaperId = this.$route.params.id;
        this.init();
    },
};
</script>

<style lang="scss">
.exam-result-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: $color-primary;
    color: #fff;

    > dl {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: $padding;

        > dt {
            flex: 0 0 auto;
            margin-right: $margin-small;
        }
    }
}

.exam-result-label {
    padding: $padding;
    background-color: mix($color-warning, #fff, 20%);
    margin: $margin 0;
}
</style>
