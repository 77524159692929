/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import PointGoodsCat from './PointGoodsCat';
import PointGoods from './PointGoods';
import PointGoodsOrder from './PointGoodsOrder';
import GoodsCat from './GoodsCat';
import Goods from './Goods';
import DocumentCat from './DocumentCat';
import Document from './Document';
import Brand from './Brand';

export default {
    PointGoodsCat,
    PointGoods,
    PointGoodsOrder,
    GoodsCat,
    Goods,
    DocumentCat,
    Document,
    Brand,
};
