<template>
    <page class="hello">
        <!--<div v-power="['user:add:user:add:user:add:user:add:user:add']">['user:add']</div>-->
        <!--<div v-power="['menu:add','user:add']">['menu:add','user:add']</div>-->
        <!--<div v-power.&&="['menu:add','user:add']">['menu:add','user:add']&&</div>-->
        <!--<div v-power.&&="['menu:add','region:add']">['menu:add','region:add']&&</div>-->
        <div slot="body">
            <el-card
                v-if="$power(['index:todoList'])"
                class="ma-b"
                size="small"
                shadow="never"
                header="待办事项"
            >
                <el-row
                    type="flex"
                    justify="space-between"
                >
                    <el-col :span="4">
                        <div class="info-item">
                            <!--<div class="icon f-icon">&#xf002;</div>-->
                            <div class="body">
                                <div class="label">
                                    待发货订单
                                </div>
                                <Linker
                                    to="/order/order/order?orderStatus=20"
                                    class="value"
                                >
                                    {{ orderNumMap.waitShip }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    仓库中订单
                                </div>
                                <Linker
                                    to="/order/order/order?orderStatus=30-1"
                                    class="value"
                                >
                                    {{ orderNumMap.inStock }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    退款订单审核
                                </div>
                                <Linker
                                    to="/order/afterSale/orderRefund"
                                    class="value"
                                >
                                    {{ orderNumMap.refundWaitReview }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    退货退款订单审核
                                </div>
                                <Linker
                                    to="/order/afterSale/orderReturn"
                                    class="value"
                                >
                                    {{ orderNumMap.returnWaitReview }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    退货退款确认收货
                                </div>
                                <Linker
                                    to="/order/afterSale/orderReturn?refundStatus=7"
                                    class="value"
                                >
                                    {{ orderNumMap.returnWaitReceive }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                v-if="$power(['index:orderStat'])"
                class="ma-b"
                size="small"
                shadow="never"
                header="销量情况"
            >
                <el-row>
                    <el-col :span="12">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    订单数量
                                </div>
                                <Linker
                                    :to="'/stat/payStat/payStat?statDateDay=' + statDate + '&statDateDay=' + statDate"
                                    class="value"
                                >
                                    {{ orderStatMap.orderNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    销售金额
                                </div>
                                <Linker
                                    :to="'/stat/payStat/payStat?statDateDay=' + statDate + '&statDateDay=' + statDate"
                                    class="value"
                                >
                                    {{ orderStatMap.payableAmount }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="ma-t">
                    <el-col :span="24">
                        <ECharts
                            class="echarts-demo"
                            :options="orderStatOptions"
                        />
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                v-if="$power(['index:buyerStat'])"
                class="ma-b"
                size="small"
                shadow="never"
                header="会员概况"
            >
                <el-row>
                    <el-col :span="6">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    会员总数
                                </div>
                                <Linker
                                    :to="'/mb/mbmg/buyer'"
                                    class="value"
                                >
                                    {{ buyerStatMap.buyerNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    新增会员数
                                </div>
                                <Linker
                                    :to="'/mb/mbmg/buyer?insertTime=' + statDate + '&insertTime=' + statDate"
                                    class="value"
                                >
                                    {{ buyerStatMap.buyerNumND }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    代理商总数
                                </div>
                                <Linker
                                    :to="'/dt/dtmg/distributor?isDistributor=1'"
                                    class="value"
                                >
                                    {{ buyerStatMap.distributorNum }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="info-item">
                            <div class="body">
                                <div class="label">
                                    新增代理商数
                                </div>
                                <Linker
                                    :to="'/dt/dtmg/distributor?isDistributor=1&joinTime=' + statDate + '&joinTime=' + statDate"
                                    class="value"
                                >
                                    {{ buyerStatMap.distributorNumND }}
                                </Linker>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="ma-t">
                    <el-col :span="24">
                        <ECharts
                            class="echarts-demo"
                            :options="buyerStatOptions"
                        />
                    </el-col>
                </el-row>
            </el-card>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'Index',
    mixins: [pagesMixin],
    data() {
        return {
            orderNumMap: {},
            orderStatMap: {},
            buyerStatMap: {},
            orderStatList: [],
            buyerStatList: [],
            statDate: '',
        };
    },
    computed: {
        orderStatOptions() {
            const orderNumData = [];
            const payableAmountData = [];
            const dayData = [];
            this.orderStatList.forEach(item => {
                orderNumData.push(item.orderNum);
                payableAmountData.push(item.payableAmount);
                dayData.push(item.statDate);
            });
            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true,
                },
                toolbox: {
                    feature: {
                        // dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar'] },
                        restore: { show: true },
                        saveAsImage: { show: true },
                    },
                },
                legend: {
                    data: ['订单数量', '销售金额'],
                },
                xAxis: [
                    {
                        type: 'category',
                        data: dayData,
                        axisPointer: {
                            type: 'shadow',
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '数量',
                        // min: 0,
                        // max: 250,
                        // interval: 50,
                        axisLabel: {
                            formatter: '{value}',
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        type: 'value',
                        name: '金额',
                        // min: 0,
                        // max: 25,
                        // interval: 5,
                        axisLabel: {
                            formatter: '{value}',
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '订单数量',
                        type: 'bar',
                        data: orderNumData,
                    },
                    {
                        name: '销售金额',
                        type: 'line',
                        yAxisIndex: 1,
                        data: payableAmountData,
                    },
                ],
            };
        },
        buyerStatOptions() {
            const buyerNumData = [];
            const distributorNumData = [];
            const dayData = [];
            this.buyerStatList.forEach(item => {
                buyerNumData.push(item.buyerNum);
                distributorNumData.push(item.distributorNum);
                dayData.push(item.statDate);
            });
            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true,
                },
                toolbox: {
                    feature: {
                        // dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar'] },
                        restore: { show: true },
                        saveAsImage: { show: true },
                    },
                },
                legend: {
                    data: ['会员增长数', '代理商增长数'],
                },
                xAxis: [
                    {
                        type: 'category',
                        data: dayData,
                        axisPointer: {
                            type: 'shadow',
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '人数',
                        // min: 0,
                        // max: 250,
                        interval: 50,
                        axisLabel: {
                            formatter: '{value}',
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '会员增长数',
                        type: 'bar',
                        data: buyerNumData,
                    },
                    {
                        name: '代理商增长数',
                        type: 'bar',
                        // yAxisIndex: 1,
                        data: distributorNumData,
                    },
                ],
            };
        },
    },
    methods: {
        getData() {
            return this.$api.Rs.Console.index().then(json => {
                const res = json.data.data;
                this.orderNumMap = res.orderNumMap;
                this.orderStatMap = res.orderStatMap;
                this.buyerStatMap = res.buyerStatMap;
                this.orderStatList = res.orderStatList;
                this.buyerStatList = res.buyerStatList;
                this.statDate = res.statDate;
            });
        },
    },
    created() {
        return this.getData();
    },
};
</script>

<style lang="scss" scoped>
.echarts-demo {
    width: 100%;
}
</style>
<style lang="scss">
.info-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    > .icon {
        flex: 0 0 auto;
        font-size: 26px;
        width: 30px;
        text-align: center;
        margin-right: $margin;
    }
    > .body {
        flex: 1 1 1%;
        text-align: center;
        > .label {
        }
        > .value {
            font-weight: bold;
            font-size: 150%;
            color: $color-primary;
        }
    }
}
</style>
