<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-card>
                    <el-form-item
                        prop="certId"
                        label="证书"
                        label-width="8em"
                    >
                        <RemoteSelect
                            remote="/sv/cert/select"
                            placeholder="请输入"
                            :props="{id:'id',name:'certName'}"
                            v-model="formModel.certId"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="templateName"
                        label="模板名称"
                        label-width="8em"
                    >
                        <el-input
                            v-model="formModel.templateName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="passMark"
                        label="及格分数"
                        label-width="8em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            v-model.number="formModel.passMark"
                        />
                    </el-form-item>
                </el-card>
                <el-card class="ma-t">
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>试题总数<span class="tc-red">{{ questionNum }}</span>题 共<span class="tc-red">{{ totalScore }}</span>分 单选<span class="tc-red">{{ questionType1Num }}</span> 判断<span class="tc-red">{{ questionType2Num }}</span> 多选<span class="tc-red">{{ questionType3Num }}</span> 案例分析<span class="tc-red">{{ questionType4Num }}</span></span>
                    </div>
                    <div>
                        <el-button
                            type="primary"
                            size="small"
                            @click="onAddQuestionCat"
                        >
                            添加
                        </el-button>
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="templateConfigList"
                            style="width: 100%"
                            class="ma-t"
                        >
                            <el-table-column
                                prop="questionCatName"
                                label="题目分类名称"
                                min-width="100"
                            />
                            <el-table-column
                                label="单选题"
                                width="200"
                            >
                                <template slot-scope="scope">
                                    <div>
                                        <el-form-item
                                            :prop="`templateConfigList[${scope.$index}].questionType1Num`"
                                            :rules="formRules.questionTypeNum"
                                            label=""
                                        >
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                v-model.number="formModel.templateConfigList[scope.$index].questionType1Num"
                                                size="mini"
                                                :disabled="scope.row.questionType1NumTotal === 0"
                                                :max="scope.row.questionType1NumTotal"
                                                @change="changeVal"
                                            />
                                            /<span class="tc-red">{{ scope.row.questionType1NumTotal }}</span>题
                                        </el-form-item>
                                    </div>
                                    <div>
                                        <el-form-item
                                            :prop="`templateConfigList[${scope.$index}].questionType1Score`"
                                            :rules="formRules.questionScore"
                                            label=""
                                        >
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                v-model.number="formModel.templateConfigList[scope.$index].questionType1Score"
                                                size="mini"
                                                :disabled="scope.row.questionType1NumTotal === 0"
                                                @change="changeVal"
                                            />
                                            分/题
                                        </el-form-item>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="多选题"
                                width="200"
                            >
                                <template slot-scope="scope">
                                    <div>
                                        <el-form-item
                                            :prop="`templateConfigList[${scope.$index}].questionType3Num`"
                                            :rules="formRules.questionTypeNum"
                                            label=""
                                        >
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                v-model.number="formModel.templateConfigList[scope.$index].questionType3Num"
                                                size="mini"
                                                :disabled="scope.row.questionType3NumTotal === 0"
                                                :max="scope.row.questionType3NumTotal"
                                                @change="changeVal"
                                            />
                                            /<span class="tc-red">{{ scope.row.questionType3NumTotal }}</span>题
                                        </el-form-item>
                                    </div>
                                    <div>
                                        <el-form-item
                                            :prop="`templateConfigList[${scope.$index}].questionType3Score`"
                                            :rules="formRules.questionScore"
                                            label=""
                                        >
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                v-model.number="formModel.templateConfigList[scope.$index].questionType3Score"
                                                size="mini"
                                                :disabled="scope.row.questionType3NumTotal === 0"
                                                @change="changeVal"
                                            />
                                            分/题
                                        </el-form-item>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="判断题"
                                width="200"
                            >
                                <template slot-scope="scope">
                                    <div>
                                        <el-form-item
                                            :prop="`templateConfigList[${scope.$index}].questionType2Num`"
                                            :rules="formRules.questionTypeNum"
                                            label=""
                                        >
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                v-model.number="formModel.templateConfigList[scope.$index].questionType2Num"
                                                size="mini"
                                                :disabled="scope.row.questionType2NumTotal === 0"
                                                :max="scope.row.questionType2NumTotal"
                                                @change="changeVal"
                                            />
                                            /<span class="tc-red">{{ scope.row.questionType2NumTotal }}</span>题
                                        </el-form-item>
                                    </div>
                                    <div>
                                        <el-form-item
                                            :prop="`templateConfigList[${scope.$index}].questionType2Score`"
                                            :rules="formRules.questionScore"
                                            label=""
                                        >
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                v-model.number="formModel.templateConfigList[scope.$index].questionType2Score"
                                                size="mini"
                                                :disabled="scope.row.questionType2NumTotal === 0"
                                                @change="changeVal"
                                            />
                                            分/题
                                        </el-form-item>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="案例分析"
                                width="200"
                            >
                                <template slot-scope="scope">
                                    <div>
                                        <el-form-item
                                            :prop="`templateConfigList[${scope.$index}].questionType4Num`"
                                            :rules="formRules.questionTypeNum"
                                            label=""
                                        >
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                v-model.number="formModel.templateConfigList[scope.$index].questionType4Num"
                                                size="mini"
                                                :disabled="scope.row.questionType4NumTotal === 0"
                                                :max="scope.row.questionType4NumTotal"
                                                @change="changeVal"
                                            />
                                            /<span class="tc-red">{{ scope.row.questionType4NumTotal }}</span>题
                                        </el-form-item>
                                    </div>
                                    <div>
                                        <el-form-item
                                            :prop="`templateConfigList[${scope.$index}].questionType4Score`"
                                            :rules="formRules.questionScore"
                                            label=""
                                        >
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                v-model.number="formModel.templateConfigList[scope.$index].questionType4Score"
                                                size="mini"
                                                :disabled="scope.row.questionType4NumTotal === 0"
                                                @change="changeVal"
                                            />
                                            分/题
                                        </el-form-item>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                label="操作"
                                width="45"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        type="text"
                                        size="small"
                                        @click="onDelQuestionCat(formModel.templateConfigList[scope.$index])"
                                    >
                                        删除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <el-form-item
                        label-width="13em"
                        class="ma-t"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-card>
            </el-form>
        </template>

        <el-dialog
            title="添加分类"
            center
            width="600px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addCatForm"
                size="small"
            >
                <el-form-item
                    label="试题分类"
                    label-width="6em"
                >
                    <el-tree
                        ref="tree"
                        node-key="id"
                        :data="addDialog.questionCatList"
                        :props="defaultProps"
                        show-checkbox
                        check-strictly
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'PaperTemplateEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                certId: '',
                templateName: '',
                passMark: '',

                templateConfigList: [],
            },
            templateConfigList: [],
            // 表单校验规则
            formRules: {
                certId: {
                    required: true,
                    message: '请选择证书',
                },
                templateName: {
                    required: true,
                    message: '请输入模板名称',
                    trigger: 'blur',
                },
                questionTypeNum: {
                    required: true,
                    message: '请输入题目数量',
                    trigger: 'blur',
                },
                questionScore: {
                    required: true,
                    message: '请输入题目分值',
                    trigger: 'blur',
                },
            },
            addDialog: {
                // 对话框显示
                isVisible: false,
                questionCatList: [],
            },
            defaultProps: {
                children: 'children',
                label: 'name',
            },
            questionType1Num: 0,
            questionType2Num: 0,
            questionType3Num: 0,
            questionType4Num: 0,
            questionNum: 0,
            totalScore: 0,
        };
    },
    watch: {
        'formModel.templateConfigList': function() {
            const result = [];
            const promiseArr = [];
            this.formModel.templateConfigList.forEach(item => {
                promiseArr.push(this.mergeDetail(item));
            });
            Promise.all(promiseArr).then(list => {
                list.forEach(item => {
                    result.push(item);
                });
            });
            this.templateConfigList = result;
        },
        templateConfigList: function() {
            let questionType1Num = 0;
            let questionType2Num = 0;
            let questionType3Num = 0;
            let questionType4Num = 0;
            let questionNum = 0;
            let totalScore = 0;
            this.templateConfigList.forEach(item => {
                questionNum =
                    questionNum +
                    item.questionType1Num +
                    item.questionType2Num +
                    item.questionType3Num +
                    item.questionType4Num;
                questionType1Num = questionType1Num + item.questionType1Num;
                questionType2Num = questionType2Num + item.questionType2Num;
                questionType3Num = questionType3Num + item.questionType3Num;
                questionType4Num = questionType4Num + item.questionType4Num;
                totalScore =
                    totalScore +
                    item.questionType1Num * item.questionType1Score +
                    item.questionType2Num * item.questionType2Score +
                    item.questionType3Num * item.questionType3Score +
                    item.questionType4Num * item.questionType4Score;
            });
            this.questionType1Num = questionType1Num;
            this.questionType2Num = questionType2Num;
            this.questionType3Num = questionType3Num;
            this.questionType4Num = questionType4Num;
            this.questionNum = questionNum;
            this.totalScore = totalScore;
        },
    },
    methods: {
        async mergeDetail(item) {
            let json;
            if (!item.questionCatName) {
                json = await this.$api.Sv.QuestionCat.getDetail({ id: item.questionCatId });
            } else {
                return { ...item };
            }
            const res = json.data.data;
            return {
                ...item,
                questionCatName: res.namePath,
                questionType1NumTotal: res.questionType1NumTotal,
                questionType2NumTotal: res.questionType2NumTotal,
                questionType3NumTotal: res.questionType3NumTotal,
                questionType4NumTotal: res.questionType4NumTotal,
            };
        },
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Ex.PaperTemplate.save({
                    ...this.formModel,
                    templateConfig: JSON.stringify(this.formModel.templateConfigList),
                    questionNum: this.questionNum,
                    totalScore: this.totalScore,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Ex.PaperTemplate.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
                this.formModel.templateConfigList = JSON.parse(res.templateConfig);
            });
        },
        initQuestionCatList() {
            this.$api.Sv.QuestionCat.selectAll().then(json => {
                this.addDialog.questionCatList = this.$utils.Convert.listToTree(json.data.data, 1);
                const selectIdList = [];
                this.formModel.templateConfigList.forEach(item => {
                    selectIdList.push(item.questionCatId);
                });
                this.$refs.tree.setCheckedKeys(selectIdList);
            });
        },
        onAddQuestionCat() {
            this.addDialog.isVisible = true;
            this.initQuestionCatList();
        },
        onAddDialogConfirm() {
            // 插入新数据
            const selectIdList = this.$refs.tree.getCheckedKeys();
            const list = [];
            selectIdList.forEach(item => {
                let isHave = false;
                this.formModel.templateConfigList.forEach(alItem => {
                    if (alItem.questionCatId == item) {
                        isHave = true;
                        list.push({ ...alItem });
                    }
                });
                if (!isHave) {
                    list.push({
                        questionCatId: item,
                        questionType1Num: 0,
                        questionType2Num: 0,
                        questionType3Num: 0,
                        questionType4Num: 0,
                        questionType1Score: 0,
                        questionType2Score: 0,
                        questionType3Score: 0,
                        questionType4Score: 0,
                    });
                }
            });
            this.formModel.templateConfigList = JSON.parse(JSON.stringify(list));
            this.addDialog.isVisible = false;
        },
        onAddDialogCancel() {
            this.addDialog.isVisible = false;
        },
        onDelQuestionCat(row) {
            this.formModel.templateConfigList.splice(this.formModel.templateConfigList.indexOf(row), 1);
        },
        changeVal() {
            let questionType1Num = 0;
            let questionType2Num = 0;
            let questionType3Num = 0;
            let questionType4Num = 0;
            let questionNum = 0;
            let totalScore = 0;
            this.formModel.templateConfigList.forEach(item => {
                questionNum =
                    questionNum +
                    item.questionType1Num +
                    item.questionType2Num +
                    item.questionType3Num +
                    item.questionType4Num;
                questionType1Num = questionType1Num + item.questionType1Num;
                questionType2Num = questionType2Num + item.questionType2Num;
                questionType3Num = questionType3Num + item.questionType3Num;
                questionType4Num = questionType4Num + item.questionType4Num;
                totalScore =
                    totalScore +
                    item.questionType1Num * item.questionType1Score +
                    item.questionType2Num * item.questionType2Score +
                    item.questionType3Num * item.questionType3Score +
                    item.questionType4Num * item.questionType4Score;
            });
            this.questionType1Num = questionType1Num;
            this.questionType2Num = questionType2Num;
            this.questionType3Num = questionType3Num;
            this.questionType4Num = questionType4Num;
            this.questionNum = questionNum;
            this.totalScore = totalScore;
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
