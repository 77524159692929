<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
            >
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>预约信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="预约时间:"
                                label-width="12em"
                            >
                                {{ memberReserve.planDeliveryTime | moment('YYYY-MM-DD') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="公司名称:"
                                label-width="12em"
                            >
                                {{ memberReserve.companyName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="牵引车牌号:"
                                label-width="12em"
                            >
                                {{ memberReserve.tractionLicensePlateNumber }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="挂车车牌号:"
                                label-width="12em"
                            >
                                {{ memberReserve.trailerLicensePlateNumber }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="承接货物类型:"
                                label-width="12em"
                            >
                                {{ memberReserve.undertakeGoodsTypeName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="14天内是否去过中、高风险地区（疫情中、高地区）:"
                                label-width="20em"
                            >
                                {{ memberReserve.isContactEpidemicArea ? '是' : '否' }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="24"
                            v-if="memberReserve.isContactEpidemicArea"
                        >
                            <el-form-item
                                label="从湖北哪个地方返闽:"
                                label-width="20em"
                            >
                                {{ memberReserve.contactEpidemicBackTypeName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="是否与疫区人员有过密切接触:"
                                label-width="20em"
                            >
                                {{ memberReserve.isContactEpidemicAreaPersonnel ? '是' : '否' }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="是否与确诊病例或疑似病例有过密切接触:"
                                label-width="20em"
                            >
                                {{ memberReserve.isConcatPatient ? '是' : '否' }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="疫情风险等级报告:"
                                label-width="12em"
                            >
                                <ImageList
                                    :data="memberReserve.healthCodeUrl"
                                />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>驾驶员信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="驾驶员姓名:"
                                label-width="12em"
                            >
                                {{ memberReserve.driverName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="驾驶员电话:"
                                label-width="12em"
                            >
                                {{ memberReserve.driverTel }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="驾驶员身份证:"
                                label-width="12em"
                            >
                                {{ memberReserve.driverIdCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="驾驶员户籍所在地:"
                                label-width="12em"
                            >
                                {{ memberReserve.driverRegionName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="详细居住地址:"
                                label-width="12em"
                            >
                                {{ memberReserve.driverAddress }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="驾驶员照片:"
                                label-width="12em"
                            >
                                <ImageList
                                    :data="memberReserve.driverImageUrl"
                                />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>押运员信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="押运员姓名:"
                                label-width="12em"
                            >
                                {{ memberReserve.escortrName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="押运员电话:"
                                label-width="12em"
                            >
                                {{ memberReserve.escortrTel }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="押运员身份证:"
                                label-width="12em"
                            >
                                {{ memberReserve.escortrIdCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="押运员户籍所在地:"
                                label-width="12em"
                            >
                                {{ memberReserve.escortrRegionName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="详细居住地址:"
                                label-width="12em"
                            >
                                {{ memberReserve.escortrAddress }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="押运员照片:"
                                label-width="12em"
                            >
                                <ImageList
                                    :data="memberReserve.escortrImageUrl"
                                />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>备选驾驶员信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="备选驾驶员姓名:"
                                label-width="12em"
                            >
                                {{ memberReserve.alternativeDriverName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="备选驾驶员电话:"
                                label-width="12em"
                            >
                                {{ memberReserve.alternativeDriverTel }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="备选驾驶员身份证:"
                                label-width="12em"
                            >
                                {{ memberReserve.alternativeDriverIdCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="备选驾驶员户籍所在地:"
                                label-width="12em"
                            >
                                {{ memberReserve.alternativeDriverRegionName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="详细居住地址:"
                                label-width="12em"
                            >
                                {{ memberReserve.alternativeDriverAddress }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="备选驾驶员照片:"
                                label-width="12em"
                            >
                                <ImageList
                                    :data="memberReserve.alternativeDriverImageUrl"
                                />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>备选押运员信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="备选押运员姓名:"
                                label-width="12em"
                            >
                                {{ memberReserve.alternativeEscortrName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="备选押运员电话:"
                                label-width="12em"
                            >
                                {{ memberReserve.alternativeEscortrTel }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="备选押运员身份证:"
                                label-width="12em"
                            >
                                {{ memberReserve.alternativeEscortrIdCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="备选押运员户籍所在地:"
                                label-width="12em"
                            >
                                {{ memberReserve.alternativeEscortrRegionName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="详细居住地址:"
                                label-width="12em"
                            >
                                {{ memberReserve.alternativeEscortrAddress }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="备选押运员照片:"
                                label-width="12em"
                            >
                                <ImageList
                                    :data="memberReserve.alternativeEscortrImageUrl"
                                />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-col :span="12">
                    <el-form-item
                        label-width="12em"
                        class="ma-t"
                    >
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'MemberReserveDetail',
    mixins: [pagesMixin],
    data() {
        return {
            memberReserve: {},
            id: '',
        };
    },
    methods: {
        init() {
            this.$api.Mb.MemberReserve.getDetail({ id: this.id }).then(json => {
                const res = json.data.data;
                this.memberReserve = res || {};
            });
        },
    },
    created() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
