/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';

class PointGoodsOrder extends Gd {
    constructor() {
        super();
        this.baseUrl += '/pointGoodsOrder';
    }

    data({
        orderStatus,
        orderSn,
        deliveryConsignee,
        deliveryPhone,
        goodsName,
        orderTime,
        trackingNumber,
        currentPage,
        pageSize,
    } = {}) {
        let orderTimeBegin = null;
        let orderTimeEnd = null;
        if (orderTime != null && orderTime.length === 2) {
            orderTimeBegin = orderTime[0];
            orderTimeEnd = orderTime[1];
        }
        return super.get('/data', {
            orderStatus,
            orderSn,
            deliveryConsignee,
            deliveryPhone,
            goodsName,
            orderTimeBegin,
            orderTimeEnd,
            trackingNumber,
            currentPage,
            pageSize,
        });
    }

    cancelOrder({ id, cancelDesc }) {
        return super.post('/cancelOrder', { id, cancelDesc });
    }
}

export default new PointGoodsOrder();
export { PointGoodsOrder };
