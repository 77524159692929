<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <!-- 内容 -->
        <div
            class="body"
            slot="body"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="exportExcel"
                    >
                        导出报表
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="orgName"
                    label="组织机构名称"
                    min-width="200"
                />
                <el-table-column
                    prop="totalApplyNum"
                    label="报名人数"
                    min-width="65"
                />
                <!--                <el-table-column
                    prop="totalWaitApplyNum"
                    label="未报名人数"
                    min-width="70"
                />-->
                <el-table-column
                    prop="totalExamNum"
                    label="考试人数"
                    min-width="65"
                />
                <el-table-column
                    prop="totalExamPassNum"
                    label="考试通过人数"
                    min-width="75"
                />
                <el-table-column
                    prop="totalExamNotPassNum"
                    label="考试不通过人数"
                    min-width="85"
                />
                <el-table-column
                    prop="totalExamPer"
                    label="到考率"
                    min-width="65"
                >
                    <template slot-scope="scope">
                        {{ scope.row.totalExamPer }}%
                    </template>
                </el-table-column>
                <el-table-column
                    prop="totalExamPassPer"
                    label="考试通过率"
                    min-width="70"
                >
                    <template slot-scope="scope">
                        {{ scope.row.totalExamPassPer }}%
                    </template>
                </el-table-column>
                <el-table-column
                    prop="totalAvgExamScore"
                    label="成绩平均值"
                    min-width="70"
                >
                    <template slot-scope="scope">
                        {{ scope.row.totalAvgExamScore }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/ex/exam/exam/examStat/' + scope.row.examId + '/' + scope.row.orgId)"
                            v-if="scope.row.isLeaf === 0"
                        >
                            查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <ECharts
                auto-resize
                class="out-in-warehouse-echarts ma-t"
                :options="options1"
            />
            <ECharts
                auto-resize
                class="out-in-warehouse-echarts ma-t"
                :options="options2"
            />
        </div>
    </page>
</template>

<script>
export default {
    name: 'ExamStat',
    data() {
        return {
            // 表格数据
            tableData: [],
            examId: '',
            orgId: '',
        };
    },
    computed: {
        orgList() {
            const list = [];
            this.tableData.forEach(item => {
                list.push(item.orgName);
            });
            list.splice(0, 1);
            return list;
        },
        totalExamPassPerList() {
            const list = [];
            this.tableData.forEach(item => {
                list.push(item.totalExamPassPer);
            });
            list.splice(0, 1);
            return list;
        },
        totalApplyNumList() {
            const list = [];
            this.tableData.forEach(item => {
                list.push(item.totalApplyNum);
            });
            list.splice(0, 1);
            return list;
        },
        totalExamPassNumList() {
            const list = [];
            this.tableData.forEach(item => {
                list.push(item.totalExamPassNum);
            });
            list.splice(0, 1);
            return list;
        },
        totalExamNumList() {
            const list = [];
            this.tableData.forEach(item => {
                list.push(item.totalExamNum);
            });
            list.splice(0, 1);
            return list;
        },
        totalExamPerList() {
            const list = [];
            this.tableData.forEach(item => {
                list.push(item.totalExamPer);
            });
            list.splice(0, 1);
            return list;
        },
        options1() {
            return {
                title: {
                    text: '全省各支队考试通过率',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                toolbox: {
                    feature: {
                        dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar'] },
                        restore: { show: true },
                        saveAsImage: { show: true },
                    },
                },
                legend: {
                    data: ['报名人数', '通过人数', '通过率'],
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.orgList,
                        axisPointer: {
                            type: 'shadow',
                        },
                        axisLabel: { interval: 0 },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '报考人数',
                        min: 0,
                        max: 250,
                        interval: 50,
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                    {
                        type: 'value',
                        name: '通过人数',
                        min: 0,
                        max: 25,
                        interval: 5,
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                ],
                series: [
                    {
                        name: '报名人数',
                        type: 'bar',
                        data: this.totalApplyNumList,
                    },
                    {
                        name: '通过人数',
                        type: 'bar',
                        data: this.totalExamPassNumList,
                    },
                    {
                        name: '通过率',
                        type: 'line',
                        yAxisIndex: 1,
                        data: this.totalExamPassPerList,
                    },
                ],
            };
        },
        options2() {
            return {
                title: {
                    text: '全省各支队考试报考率',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                toolbox: {
                    feature: {
                        dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar'] },
                        restore: { show: true },
                        saveAsImage: { show: true },
                    },
                },
                legend: {
                    data: ['报名人数', '考试人数', '报考率'],
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.orgList,
                        axisPointer: {
                            type: 'shadow',
                        },
                        axisLabel: { interval: 0 },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '报考人数',
                        min: 0,
                        max: 250,
                        interval: 50,
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                    {
                        type: 'value',
                        name: '考试人数',
                        min: 0,
                        max: 25,
                        interval: 5,
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                ],
                series: [
                    {
                        name: '报名人数',
                        type: 'bar',
                        data: this.totalApplyNumList,
                    },
                    {
                        name: '考试人数',
                        type: 'bar',
                        data: this.totalExamNumList,
                    },
                    {
                        name: '报考率',
                        type: 'line',
                        yAxisIndex: 1,
                        data: this.totalExamPerList,
                    },
                ],
            };
        },
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        init() {
            return this.$api.Ex.Exam.getExamStat({ examId: this.examId, orgId: this.orgId }).then(json => {
                const res = json.data;
                this.tableData = res.data.result;
            });
        },
        exportExcel() {
            this.$http.href('/ex/exam/exportExamStatExcel', { examId: this.examId, orgId: this.orgId });
        },
    },
    created() {
        this.examId = this.$route.params.examId;
        this.orgId = this.$route.params.orgId;
        this.init();
    },
};
</script>

<style lang="scss">
</style>
<style lang="scss">
.out-in-warehouse-echarts {
    width: 100%;
}

.out-in-warehouse {
    background-color: #fff;
}
</style>
