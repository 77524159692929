<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.jobRecruitStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"
                />
                <el-tab-pane
                    label="未提交"
                    name="10"
                />
                <el-tab-pane
                    label="待审核"
                    name="20"
                />
                <el-tab-pane
                    label="上架展示"
                    name="100"
                />
                <el-tab-pane
                    label="下架隐藏"
                    name="98"
                />
                <el-tab-pane
                    label="审核不通过"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="memberName"
                    label="发布者"
                >
                    <el-input
                        v-model="queryFormModel.memberName"
                        placeholder="请输入发布者"
                        :disabled="queryFormModel.isAdministrator"
                    />
                </el-form-item>
                <el-form-item
                    prop="jobTitle"
                    label="职位名称"
                >
                    <el-input
                        v-model="queryFormModel.jobTitle"
                        placeholder="请输入职位名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="regionId"
                    label="省市区"
                >
                    <CascaderPicker
                        check-strictly
                        :api-class="regionApi"
                        v-model="queryFormModel.regionId"
                        :p-id="2"
                        :min-lv="2"
                    />
                </el-form-item>
                <el-form-item
                    prop="workPlace"
                    label="工作地点"
                >
                    <el-input
                        v-model="queryFormModel.workPlace"
                        placeholder="请输入工作地点"
                    />
                </el-form-item>
                <el-form-item
                    prop="workingYears"
                    label="工作年限"
                >
                    <el-input
                        v-model="queryFormModel.workingYears"
                        placeholder="请输入工作年限"
                    />
                </el-form-item>
                <el-form-item
                    prop="education"
                    label="学历"
                >
                    <dictionaries-picker
                        type="education"
                        v-model="queryFormModel.education"
                    />
                </el-form-item>
                <el-form-item
                    prop="salary"
                    label="薪资"
                >
                    <el-input
                        v-model="queryFormModel.salary"
                        placeholder="请输入薪资"
                    />
                </el-form-item>
                <el-form-item
                    prop="companyName"
                    label="公司名称"
                >
                    <el-input
                        v-model="queryFormModel.companyName"
                        placeholder="请输入公司名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAdministrator"
                >
                    <el-checkbox
                        v-model="queryFormModel.isAdministrator"
                        @change="()=>{ if (this.queryFormModel.isAdministrator) {
                            this.queryFormModel.memberName = '';
                        } }"
                    >
                        是否管理员发布
                    </el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/mb/jobRecruit/jobRecruit/edit')"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="memberName"
                    label="发布者"
                    min-width="100"
                />
                <el-table-column
                    prop="jobTitle"
                    label="职位名称"
                    min-width="100"
                />
                <el-table-column
                    prop="regionName"
                    label="省市区"
                    min-width="120"
                />
                <el-table-column
                    prop="workPlace"
                    label="工作地点"
                    min-width="100"
                />
                <el-table-column
                    prop="workingYears"
                    label="工作年限"
                    min-width="100"
                />
                <el-table-column
                    prop="educationName"
                    label="学历"
                    min-width="100"
                />
                <el-table-column
                    prop="salary"
                    label="薪资"
                    min-width="100"
                />
                <el-table-column
                    prop="companyName"
                    label="公司名称"
                    min-width="200"
                />
                <el-table-column
                    prop="jobRecruitStatusName"
                    label="招聘信息状态"
                    min-width="100"
                    v-if="queryFormModel.jobRecruitStatus === '0'"
                />
                <el-table-column
                    label="创建时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/jobRecruit/jobRecruit/view/' +
                                scope.row.id)"
                        >
                            查看
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/jobRecruit/jobRecruit/edit/' +
                                scope.row.id)"
                            v-if="+scope.row.jobRecruitStatus !== 20 && !scope.row.memberId"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onShelf(scope.row.id)"
                            v-if="+scope.row.jobRecruitStatus === 98"
                        >
                            上架
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="offShelf(scope.row.id)"
                            v-if="+scope.row.jobRecruitStatus === 100"
                        >
                            下架
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                            v-if="+scope.row.jobRecruitStatus === 10 && !scope.row.memberId"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'JobRecruit',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                isAdministrator: false,
                memberName: '',
                jobTitle: '',
                workPlace: '',
                workingYears: '',
                education: '',
                salary: '',
                companyName: '',
                jobRecruitStatus: '0',
                regionId: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            regionApi: this.$api.Rs.Region,
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.JobRecruit.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },

        onShelf(id) {
            this.$confirm('是否上架！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '上架中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Mb.JobRecruit.onShelf({
                    id,
                })
                    .then(json => {
                        this.loading.close();
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    })
                    .catch(() => {
                        this.loading.close();
                    });
            });
        },

        offShelf(id) {
            this.$confirm('是否下架！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.loading = this.$loading({
                    lock: true,
                    text: '下架中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
                this.$api.Mb.JobRecruit.offShelf({
                    id,
                })
                    .then(json => {
                        this.loading.close();
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.onQuery(this.pagination);
                    })
                    .catch(() => {
                        this.loading.close();
                    });
            });
        },
        onTableDelete(id) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Mb.JobRecruit.delete({ id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
