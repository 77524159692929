<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="title"
                    label="题目"
                >
                    <el-input
                        v-model="queryFormModel.title"
                        placeholder="请输入题目"
                    />
                </el-form-item>
                <el-form-item
                    prop="questionCatId"
                    label="题目分类"
                >
                    <CascaderPicker
                        check-strictly
                        :api-class="selectApi"
                        v-model="queryFormModel.questionCatId"
                    />
                </el-form-item>
                <el-form-item
                    prop="questionType"
                    label="题目类型"
                >
                    <dictionaries-picker
                        type="questionType"
                        v-model="queryFormModel.questionType"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/sv/question/question/edit')"
                    >
                        新增
                    </el-button>
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="small"
                        @click="onDelete"
                        :disabled="!currentTableSelect.length"
                    >
                        删除
                    </el-button>
                    <el-button
                        class="ma-l"
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        导出题目
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="$http.href('/static/excel/导入题目模板.xlsx')"
                    >
                        下载模板
                    </el-button>
                    <el-upload
                        class="upload-demo dp-ib"
                        action="/sv/question/uploadQuestionByExcel"
                        :show-file-list="false"
                        accept=".xls,.xlsx"
                        :on-success="onOrderImportSuccess"
                        :on-error="onOrderImportError"
                    >
                        <el-button
                            type="success"
                            icon="el-icon-upload2"
                            size="small"
                        >
                            导入题目
                        </el-button>
                    </el-upload>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    class-name="topic"
                    prop="title"
                    label="题目"
                    min-width="100"
                >
                    <template v-slot="{row}">
                        <div class="label">
                            {{ row.title }}
                        </div>
                        <div class="options">
                            <div
                                class="item"
                                v-for="item in row.optionConfigList"
                                :key="item.option"
                            >
                                <div :class="['code',{active:row.questionAnswer&item.option}]">
                                    {{ item.text }}
                                </div>
                                <div
                                    class="label"
                                    v-html="item.content"
                                />
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    class-name="topic-ctrl"
                    fixed="right"
                    label="操作"
                    width="110"
                >
                    <template slot-scope="{row}">
                        <div class="ctrl">
                            <el-button
                                type="text"
                                size="small"
                                @click="$router.push('/sv/question/question/edit/' + row.id)"
                            >
                                编辑
                            </el-button>
                            <el-button
                                type="text"
                                size="small"
                                @click="onTableDelete(row.id)"
                            >
                                删除
                            </el-button>
                        </div>
                        <div class="options">
                            <dl>
                                <dt>题型:</dt>
                                <dd>{{ row.questionTypeName }}</dd>
                            </dl>
                            <dl>
                                <dt>分类:</dt>
                                <dd>{{ row.questionCatNamePath }}</dd>
                            </dl>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Question',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                title: '',
                questionCatId: '',
                questionType: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            selectApi: this.$api.Sv.QuestionCat,
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Sv.Question.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Sv.Question.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onOrderImportSuccess(response) {
            if (response.success) {
                this.$message({
                    message: response.msg,
                    type: 'success',
                });
            } else {
                this.$alert(response.msg, '错误提示', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                });
            }
        },
        onOrderImportError(err) {
            this.$message({
                message: err,
                type: 'error',
            });
        },
        exportExcel() {
            let ids = '';
            if (this.currentTableSelect.length !== 0) {
                const idList = [];
                this.currentTableSelect.forEach(item => {
                    idList.push(item.id);
                });
                ids = idList.join(',');
            }
            this.$http.href('/sv/question/exportExcel', {
                ...this.queryFormModel,
                ids: ids,
            });
        },
    },
};
</script>

<style lang="scss">
td.topic {
    vertical-align: top;

    > .cell {
        > .label {
            font-weight: bold;
        }

        > .options {
            > .item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: $margin;

                > .code {
                    flex: 0 0 auto;
                    width: 2em;
                    height: 2em;
                    line-height: 2em;
                    text-align: center;
                    border-radius: 100vw;
                    margin-right: $margin-small;
                    font-weight: bold;

                    &:after {
                        content: ':';
                    }

                    &.active {
                        background-color: $color-success;
                        color: #fff;

                        &:after {
                            content: '';
                        }
                    }
                }

                > .label {
                    flex: 1;
                }
            }
        }
    }
}

td.topic-ctrl {
    vertical-align: top;

    > .cell {
        > .ctrl {
        }

        > .options {
            > dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                > dt {
                    flex: 0 0 auto;
                    margin-right: $margin-small;
                }

                > dd {
                    flex: 1;
                    white-space: nowrap;
                }
            }
        }
    }
}
</style>
