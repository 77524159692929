/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class PaperTemplate extends Ex {
    constructor() {
        super();
        this.baseUrl += '/paperTemplate';
    }

    data({ templateName, certId, currentPage, pageSize } = {}) {
        return super.get('/data', {
            templateName,
            certId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({ id, certId, templateName, passMark, templateConfig, questionNum, totalScore } = {}) {
        return super.post('/save', {
            id,
            certId,
            templateName,
            passMark,
            templateConfig,
            questionNum,
            totalScore,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new PaperTemplate();
export { PaperTemplate };
