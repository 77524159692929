<template>
    <page back>
        <el-row>
            <el-col :span="16">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        prop="fullName"
                        label="姓名"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.fullName"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="sex"
                        label="性别"
                        label-width="9em"
                    >
                        <dictionaries-picker
                            type="sex"
                            v-model="formModel.sex"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="tel"
                        label="联系电话"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.tel"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="regionId"
                        label="省市区"
                        label-width="9em"
                    >
                        <CascaderPicker
                            :api-class="regionApi"
                            v-model="formModel.regionId"
                            :p-id="2"
                            :min-lv="2"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="jobIntention"
                        label="求职意向"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.jobIntention"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="salaryExpectation"
                        label="期望薪资"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.salaryExpectation"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="workExperience"
                        label="工作经历"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.workExperience"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="selfEvaluation"
                        label="自我评价"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.selfEvaluation"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="professionalSkills"
                        label="专业技能"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.professionalSkills"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        label-width="13em"
                        class="ma-t"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            type="primary"
                            size="small"
                            @click="onSubmit"
                            v-if="!formModel.id||+formModel.jobWantedStatus === 10"
                        >
                            保存并上架
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'JobWantedEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                fullName: '',
                sex: '',
                tel: '',
                jobIntention: '',
                salaryExpectation: '',
                workExperience: '',
                selfEvaluation: '',
                professionalSkills: '',
                jobWantedStatus: '',
                regionId: '',
            },
            examUserList: [],
            // 表单校验规则
            formRules: {
                fullName: {
                    required: true,
                    message: '请输入姓名',
                    trigger: 'blur',
                },
                sex: {
                    required: true,
                    message: '请选择性别',
                },
                tel: {
                    required: true,
                    message: '请输入联系电话',
                    trigger: 'blur',
                },
                jobIntention: {
                    required: true,
                    message: '请输入求职意向',
                    trigger: 'blur',
                },

                salaryExpectation: {
                    required: true,
                    message: '请输入期望薪资',
                    trigger: 'blur',
                },
                workExperience: {
                    required: true,
                    message: '请输入工作经历',
                    trigger: 'blur',
                },
                selfEvaluation: {
                    required: true,
                    message: '请输入自我评价',
                    trigger: 'blur',
                },
                professionalSkills: {
                    required: true,
                    message: '请输入专业技能',
                    trigger: 'blur',
                },
                regionId: {
                    required: true,
                    message: '请选择省市区',
                },
            },
            regionApi: this.$api.Rs.Region,
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.JobWanted.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        onSubmit() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.JobWanted.save({
                    ...this.formModel,
                    isSubmit: true,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Mb.JobWanted.getDetail({ id: this.formModel.id, isLog: false }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
