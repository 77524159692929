<template>
    <page back>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.approvalStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"
                />
                <el-tab-pane
                    label="待审批"
                    name="10"
                />
                <el-tab-pane
                    label="审批通过"
                    name="100"
                />
                <el-tab-pane
                    label="审批不通过"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <!--                <el-form-item
                    prop="memberName"
                    label="发布者"
                >
                    <el-input
                        v-model="queryFormModel.memberName"
                        placeholder="请输入发布者"
                    />
                </el-form-item>-->
                <el-form-item
                    prop="content" 
                    label="正文"
                >
                    <el-input
                        v-model="queryFormModel.content"
                        placeholder="请输入正文"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="状态"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="启用"
                            value="1"
                        />
                        <el-option
                            label="禁用"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    label="发布者"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.nickName }}({{ scope.row.mobile }})
                    </template>
                </el-table-column>
                <!--                <el-table-column
                    prop="title"
                    label="标题"
                    min-width="100"
                />-->
                <el-table-column
                    prop="content"
                    label="正文"
                    min-width="100"
                />
                <!--                <el-table-column
                    prop="agreeNum"
                    label="点赞数"
                    min-width="100"
                />
                <el-table-column
                    prop="commentNum"
                    label="评论数"
                    min-width="100"
                />-->
                <el-table-column
                    prop="approvalStatusName"
                    label="审核状态"
                    min-width="100"
                    v-if="queryFormModel.approvalStatus === '0'"
                />
                <el-table-column
                    label="创建时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column label="是否可用">
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @change="(isSelect) => changeStatus(scope.row, isSelect)"
                            :active-value="true"
                            :inactive-value="false"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/mb/post/postComment/view/' +
                                scope.row.id)"
                        >
                            查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'PostComment',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                postId: '',
                memberName: '',
                content: '',
                approvalStatus: '0',
                isAvailable: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.PostComment.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },

        changeStatus(row, isSelect) {
            this.$api.Mb.PostComment.status({ id: row.id, isAvailable: isSelect }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
            });
        },
    },
    created() {
        this.queryFormModel.postId = this.$route.params.postId;
    },
};
</script>

<style lang="scss">
</style>
