/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Rs } from './Rs';

class Employee extends Rs {
    constructor() {
        super();
        this.baseUrl += '/employee';
    }

    data({ departmentId, employeeSn, employeeName, employeeType, employeeStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            departmentId,
            employeeSn,
            employeeName,
            employeeType,
            employeeStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({
        id,
        departmentId,
        employeeSn,
        employeeName,
        employeeGender,
        employeeTel,
        employeeType,
        employeeStatus,
        remarks,
        inspectorAreaJson,
    } = {}) {
        return super.post('/save', {
            id,
            departmentId,
            employeeSn,
            employeeName,
            employeeGender,
            employeeTel,
            employeeType,
            employeeStatus,
            remarks,
            inspectorAreaJson,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    select() {
        return super.get('/select', {});
    }

    selectByPage({ keyword, employeeName, employeeSn, employeeType, currentPage, pageSize } = {}) {
        return super.get('/selectByPage', {
            keyword,
            employeeName,
            employeeSn,
            employeeType,
            currentPage,
            pageSize,
        });
    }

    exists({ id, departmentId, employeeSn } = {}) {
        return super.get('/exists', {
            id,
            departmentId,
            employeeSn,
        });
    }

    validatorEmployeeName({ id, departmentId, employeeName } = {}) {
        return super.get('/validatorEmployeeName', {
            id,
            departmentId,
            employeeName,
        });
    }
}

export default new Employee();
export { Employee };
