/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Mb } from './Mb';

class Member extends Mb {
    constructor() {
        super();
        this.baseUrl += '/member';
    }

    data({ nickName, mobile, memberStatus, memberLevelId, insertTime, currentPage, pageSize } = {}) {
        let insertBeginTime = null;
        let insertEndTime = null;
        if (insertTime != null && insertTime.length === 2) {
            insertBeginTime = insertTime[0];
            insertEndTime = insertTime[1];
        }
        return super.get('/data', {
            nickName,
            mobile,
            memberStatus,
            memberLevelId,
            insertBeginTime,
            insertEndTime,
            currentPage,
            pageSize,
        });
    }

    changeMemberLevel({ ids, memberLevelId }) {
        return super.post('/changeMemberLevel', {
            ids,
            memberLevelId,
        });
    }
}

export default new Member();
export { Member };
