/**
 * Created by henian.xu on 2020/1/8.
 *
 */

import { registerModule } from '@vmf/vuex';
import { post, get, setToken } from '@vmf/request';
import { router, initialMethodMap } from '@vmf/router';
import { GlobalVar, Md5 } from '@vmf/shared';
import { localStorage } from '@vmf/storage';

const rawData = localStorage.get('__USER_DATA__') || {};
if (rawData.token) {
    setToken(rawData.token);
}

registerModule('User', {
    namespaced: true,
    state: {
        rawData,
    },
    getters: {
        userData(state) {
            return state.rawData;
        },
        userInfo(state, getters) {
            return (getters.userData || {}).user || {};
        },
        token(state) {
            return state.rawData.token;
        },
        account(state, getters) {
            const { userInfo } = getters;
            return userInfo.account || '';
        },
        fullName(state, getters) {
            const { userInfo } = getters;
            return userInfo.fullName || '';
        },
    },
    mutations: {
        setRawData(state, data) {
            state.rawData = data || {};
            if (data) setToken(data.token);
            localStorage.set('__USER_DATA__', data);
        },
        setUserInfo(state, data) {
            if (!state.rawData) state.rawData = {};
            rawData.user = {
                ...state.rawData.user,
                ...data,
            };
            localStorage.set('__USER_DATA__', state.rawData);
        },
    },
    actions: {
        async login({ commit }, options) {
            const params = {
                ...options,
                password: options.password ? Md5(options.password) : '',
            };
            const { data } = await post(`${GlobalVar.appConfig!.USER_API}/login`, params, {
                isSuccessTip: true,
                isAxiosResponseData: true,
            });
            commit('setRawData', data);
            try {
                commit('remoteSelectCache/clearCacheData', null, { root: true });
            } catch (e) {
                console.log(e);
            }
        },
        async logout() {
            await post(
                `${GlobalVar.appConfig!.USER_API}/logout`,
                {},
                {
                    isSuccessTip: true,
                },
            );
            Object.values(initialMethodMap).forEach(initialMethod => {
                initialMethod.isCalled = false;
            });
            const backUrl = `?backUrl=${encodeURIComponent('/')}`;
            await router.replace(`/login${backUrl}`);
        },
        async modifyPassword({ commit }, options) {
            const params = {
                ...options,
                password: options.password ? Md5(options.password) : '',
                oldPassword: options.oldPassword ? Md5(options.oldPassword) : '',
            };
            await post(`${GlobalVar.appConfig!.USER_API}/modifyPwdByUser`, params, {
                isSuccessTip: true,
            });
        },
        async clearCache() {
            await post(
                `${GlobalVar.appConfig!.USER_API}/clearCache`,
                {},
                {
                    isSuccessTip: true,
                },
            );
        },
    },
});

registerModule('App', {
    namespaced: true,
    state: {
        rawData: null,
    },
    getters: {
        appList(state) {
            return state.rawData;
        },
    },
    mutations: {
        setRawData(state, data) {
            state.rawData = data;
        },
    },
    actions: {
        async getAppList({ state, commit }) {
            if (state.rawData !== null) return;
            const { data } = await get(
                GlobalVar.appConfig!.APP_LIST_API!,
                {},
                {
                    isAxiosResponseData: true,
                },
            );
            commit('setRawData', data);
        },
    },
});
