<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSn"
                    label="商品编码"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        placeholder="请输入商品编码"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="状态"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="启用"
                            value="1"
                        />
                        <el-option
                            label="禁用"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    label="商品图片"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.mainImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsSn"
                    label="商品编码"
                    min-width="100"
                />
                <el-table-column
                    prop="showStock"
                    label="库存"
                    min-width="100"
                />
                <el-table-column
                    prop="orderNum"
                    label="排序号"
                    min-width="100"
                />
                <el-table-column
                    label="是否启用"
                    width="80"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @input="onIsAvailableInput($event,scope)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="900px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="pointGoodsCatId"
                    label="商品分类"
                    label-width="9em"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="addDialog.formModel.pointGoodsCatId"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                    label-width="9em"
                >
                    <el-input
                        v-model="addDialog.formModel.goodsName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSn"
                    label="商品编码"
                    label-width="9em"
                >
                    <el-input
                        v-model="addDialog.formModel.goodsSn"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="pointNum"
                    label="购买积分"
                    label-width="9em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        v-model.number="addDialog.formModel.pointNum"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsImage"
                    label="列表图"
                    label-width="9em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="10"
                        v-model="addDialog.formModel.goodsImage"
                        :oss-action="$globalVar.appConfig.OSS_ACTION"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsDetailImage"
                    label="详情图"
                    label-width="9em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="20"
                        v-model="addDialog.formModel.goodsDetailImage"
                        :oss-action="$globalVar.appConfig.OSS_ACTION"
                    />
                </el-form-item>
                <el-form-item
                    prop="stock"
                    label="库存"
                    label-width="9em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        v-model.number="addDialog.formModel.stock"
                    />
                </el-form-item>
                <el-form-item
                    prop="minPerOrderQuantity"
                    label="订单最小购买数量"
                    label-width="9em"
                >
                    <el-input-number
                        controls-position="right"
                        v-model.number="addDialog.formModel.minPerOrderQuantity"
                    />
                </el-form-item>
                <el-form-item
                    prop="maxPerOrderQuantity"
                    label="订单最大购买数量"
                    label-width="9em"
                >
                    <el-input-number
                        controls-position="right"
                        v-model.number="addDialog.formModel.maxPerOrderQuantity"
                    />
                </el-form-item>
                <el-form-item
                    prop="maxMbOrderQuantity"
                    label="会员最大购买数量"
                    label-width="9em"
                >
                    <el-input-number
                        controls-position="right"
                        v-model.number="addDialog.formModel.maxMbOrderQuantity"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderNum"
                    label="排序"
                    label-width="9em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        :max="255"
                        v-model.number="addDialog.formModel.orderNum"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="是否可用"
                    label-width="9em"
                >
                    <el-switch
                        v-model="addDialog.formModel.isAvailable"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'PointGoods',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                goodsName: '', // 商品名称
                goodsSn: '', // 商品编码
                isAvailable: '',
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    pointGoodsCatId: '',
                    goodsName: '',
                    goodsSn: '',
                    pointNum: 1,
                    goodsImage: [],
                    goodsDetailImage: [],
                    stock: 0,
                    minPerOrderQuantity: undefined,
                    maxPerOrderQuantity: undefined,
                    maxMbOrderQuantity: undefined,
                    orderNum: 1,
                    isAvailable: true,
                },
                // 表单校验规则
                formRules: {
                    goodsName: {
                        required: true,
                        message: '请输入商品名称',
                        trigger: 'blur',
                    },
                    goodsSn: {
                        required: true,
                        message: '请输入商品编码',
                        trigger: 'blur',
                    },
                    pointNum: {
                        required: true,
                        message: '请输入购买积分',
                        trigger: 'blur',
                    },
                    stock: {
                        required: true,
                        message: '请输入库存',
                        trigger: 'blur',
                    },
                    orderNum: {
                        required: true,
                        message: '请输入排序号',
                        trigger: 'blur',
                    },
                    isAvailable: {
                        required: true,
                        message: '请选择是否可用',
                    },
                    goodsImage: {
                        type: 'array',
                        required: true,
                        message: '请上传图片',
                    },
                    goodsDetailImage: {
                        type: 'array',
                        required: true,
                        message: '请上传图片',
                    },
                    pointGoodsCatId: {
                        required: true,
                        message: '请选择商品分类',
                    },
                },
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            selectApi: this.$api.Gd.PointGoodsCat,
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.PointGoods.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Gd.PointGoods.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
            });
            this.addDialog.isVisible = true;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Gd.PointGoods.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Gd.PointGoods.save({
                    ...this.addDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Gd.PointGoods.status({
                id: row.id,
                isAvailable: row.isAvailable,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                })
                .catch(() => {
                    this.$message({
                        message: '操作失败请重试！',
                        type: 'error',
                    });
                    row.isAvailable = !row.isAvailable;
                });
        },
    },
};
</script>

<style lang="scss">
</style>
