/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Gd } from './Gd';
import { GlobalVar } from '@vmf/shared';

class Brand extends Gd {
    constructor() {
        super();
        this.baseUrl += '/brand';
    }

    data({ brandName, brandSn, isAvailable, currentPage, pageSize } = {}) {
        return super.get('/data', {
            brandName,
            brandSn,
            isAvailable,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const brandImage = json.data.data.brandImage;
            if (!brandImage) {
                json.data.data.brandImage = [];
            } else {
                json.data.data.brandImage = brandImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const brandDetailImage = json.data.data.brandDetailImage;
            if (!brandDetailImage) {
                json.data.data.brandDetailImage = [];
            } else {
                json.data.data.brandDetailImage = brandDetailImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({ id, brandName, brandSn, brandImage, brandDetailImage, remarks, orderNum, isAvailable } = {}) {
        const brandImageStr = brandImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const brandDetailImageStr = brandDetailImage
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/save', {
            id,
            brandName,
            brandSn,
            brandImage: brandImageStr,
            brandDetailImage: brandDetailImageStr,
            remarks,
            orderNum,
            isAvailable,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    status({ id, isAvailable }) {
        return super.post('/status', { id, isAvailable });
    }
}

export default new Brand();
export { Brand };
