<!-- Created by henian.xu on 2018/9/5. -->

<template>
    <page ref="page">
        <div
            class="second-header"
            slot="secondHeader"
        >
            <el-form
                ref="saveForm"
                class="npa-b"
                size="small"
                :inline="true"
                :model="saveFormModel"
                :rules="saveFormRules"
            >
                <el-form-item
                    prop="pageName"
                    label="页面名称"
                    v-if="pageType === 0 || pageType === 1"
                >
                    <el-input v-model="saveFormModel.pageName" />
                </el-form-item>
                <el-form-item
                    label="是否启用"
                    v-if="pageType === 0 || pageType === 1 || pageType === 2"
                >
                    <el-switch v-model="saveFormModel.isAvailable" />
                </el-form-item>
            </el-form>
            <el-button
                size="small"
                type="primary"
                @click="onSave(false)"
            >
                保存
            </el-button>
            <el-button
                size="small"
                type="success"
                @click="onSave(true)"
            >
                保存并预览
            </el-button>
            <el-button
                size="small"
                @click="onRestore"
            >
                还原初始状态
            </el-button>
        </div>

        <PageDecorator
            ref="pageDecorator"
            :json="decorationJson"
            :available-components="['Divider',
                                    'Toolbar',
                                    'Ad',
                                    'AdCube',
                                    'MagicCube',
                                    'RichText',
                                    'Notification']"
        />

        <el-dialog
            title="扫一扫"
            width="300px"
            :visible.sync="QRCodeDialog.visible"
        >
            <img
                :src="qrcodeUrl"
                width="100%"
            >
        </el-dialog>
    </page>
</template>

<script>
import QRCode from 'qrcode';

export default {
    name: 'DecorationEdit',
    data() {
        return {
            qrcodeUrl: '',
            saveFormModel: {
                id: '',
                pageName: '',
                isAvailable: false,
                isDefault: false,
            },
            decorationJson: undefined,
            saveFormRules: {
                pageName: {
                    required: true,
                    message: '不可为空',
                },
            },
            QRCodeDialog: {
                visible: false,
            },
        };
    },
    computed: {
        pageType() {
            return +this.$route.query.pageType;
        },
        adPageType() {
            return this.$route.query.adPageType;
        },
        distributorLevelId() {
            return this.$route.query.distributorLevelId;
        },
    },
    methods: {
        onSave(preview) {
            this.$refs.saveForm.validate().then(() => {
                this.$api.Sv.ServantDecoration.saveJsonData({
                    ...this.saveFormModel,
                    pageType: this.pageType,
                    JsonData: this.$refs['pageDecorator'].getDecorationJson(),
                    // 在这里传剩余的参数如下
                    adPageType: this.adPageType,
                    distributorLevelId: this.distributorLevelId,
                }).then(json => {
                    const res = json.data;
                    this.saveFormModel.id = res.data.id;
                    if (preview) {
                        console.log(res);
                        QRCode.toDataURL(
                            res.data.buyerUrl,
                            {
                                margin: 2,
                                scale: 30,
                                errorCorrectionLevel: 'H',
                            },
                            (error, url) => {
                                if (error) {
                                    throw new Error(error);
                                }
                                this.qrcodeUrl = url;
                                this.QRCodeDialog.visible = true;
                            },
                        );
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                    }
                });
            });
        },
        onRestore() {
            this.$refs['pageDecorator'].restoreInitial();
        },
    },
    created() {
        if (this.$route.params.id) {
            this.saveFormModel.id = this.$route.params.id;
            this.$api.Sv.ServantDecoration.getJsonData({ id: this.saveFormModel.id }).then(json => {
                const res = json.data.data || {};
                const decoration = res.decoration || {};
                this.decorationJson = decoration.pageContent;
                const { saveFormModel } = this;
                Object.keys(saveFormModel).forEach(key => {
                    saveFormModel[key] = decoration[key] || saveFormModel[key];
                });
            });
        }
    },
};
</script>

<style lang="scss">
.decoration-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    > .ctrl {
        /*position: fixed;*/
        /*right: 18px;*/
        /*width: 400px;*/
    }
}
</style>
