<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-col :span="12">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        prop="examNotes"
                        label="考试须知"
                        label-width="10em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.examNotes"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="testMemberIds"
                        label="测试账号ID"
                        label-width="10em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.testMemberIds"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="courseHourMinute"
                        label="学时分钟设置"
                        label-width="10em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="1"
                            :precision="0"
                            v-model.number="formModel.courseHourMinute"
                        />
                        1学时=？分钟
                    </el-form-item>
                    <el-form-item
                        prop="learnBaseNum"
                        label="学习基础人数"
                        label-width="10em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="0"
                            v-model.number="formModel.learnBaseNum"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isJobRecruitAudit"
                        label="招聘是否审核"
                        label-width="10em"
                    >
                        <template>
                            <el-radio
                                v-model="formModel.isJobRecruitAudit"
                                :label="true"
                            >
                                是
                            </el-radio>
                            <el-radio
                                v-model="formModel.isJobRecruitAudit"
                                :label="false"
                            >
                                否
                            </el-radio>
                        </template>
                    </el-form-item>
                    <el-form-item
                        prop="isJobWantedAudit"
                        label="求职是否审核"
                        label-width="10em"
                    >
                        <template>
                            <el-radio
                                v-model="formModel.isJobWantedAudit"
                                :label="true"
                            >
                                是
                            </el-radio>
                            <el-radio
                                v-model="formModel.isJobWantedAudit"
                                :label="false"
                            >
                                否
                            </el-radio>
                        </template>
                    </el-form-item>
                    <el-form-item
                        label-width="10em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ServantEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                servantLogo: [],
                examNotes: undefined,
                testMemberIds: undefined,
                courseHourMinute: '1',
                learnBaseNum: '0',
                isJobRecruitAudit: true,
                isJobWantedAudit: true,
            },
            // 表单校验规则
            formRules: {
                orgId: {
                    required: true,
                    message: '请选择组织机构',
                },
                servantName: {
                    required: true,
                    message: '请输入运营商名称',
                    trigger: 'blur',
                },
                servantSn: {
                    required: true,
                    message: '请输入运营商编码',
                    trigger: 'blur',
                },
                servantWebsite: {
                    required: true,
                    message: '请输入运营商网址',
                    trigger: 'blur',
                },
                servantLogo: {
                    type: 'array',
                    required: true,
                    message: '请上传运营商LOGO',
                },
                servantRegionId: {
                    required: true,
                    message: '请选择运营商地址',
                },
                servantAddress: {
                    required: true,
                    message: '请输入运营商详细地址',
                    trigger: 'blur',
                },
                payEffectiveTime: {
                    required: true,
                    message: '请输入付款有效时间',
                    trigger: 'blur',
                },
                principalName: {
                    required: true,
                    message: '请输入联系人姓名',
                    trigger: 'blur',
                },
                principalTel: {
                    required: true,
                    message: '请输入联系人电话',
                    trigger: 'blur',
                },
                servantStatus: {
                    required: true,
                    message: '请选择运营商状态',
                },
                paymentMode: [
                    {
                        required: true,
                        message: '请选择付款模式',
                    },
                    {
                        type: 'integer',
                        min: 1,
                        message: '请选择付款模式',
                    },
                ],
                onlinePaymentType: [
                    {
                        required: true,
                        message: '请选择线上付款模式',
                    },
                    {
                        type: 'integer',
                        min: 1,
                        message: '请选择线上付款模式',
                    },
                ],
                memberAccountSign: {
                    required: true,
                    message: '请输入会员账号标识',
                },
            },
            servantSelectApi: this.$api.Rs.Region,
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Sv.Servant.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    // this.$router.back();
                });
            });
        },
        initServant() {
            this.$api.Sv.Servant.getServantInfo().then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
    },
    computed: {
        onlinePayment() {
            return (this.formModel.paymentMode & 1) !== 0;
        },
    },
    created() {
        this.initServant();
    },
};
</script>

<style lang="scss">
</style>
