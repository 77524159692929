/**
 * Created by henian.xu on 2019/10/31.
 * 项目路由
 */
import { addPower, addRoutes } from 'vmf';
import demoRoutes from './demo';
import Api from '@/api';
import { ElementUI, Layout } from '@vmf/components';

const { Loading } = ElementUI;
const MenuProps = { data: {} }; // 菜单布局组件的 数据

// 复用页面
const rsSetUser = () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/user.vue');
const rsSetRole = () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/role.vue');
const rsSetOrg = () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/org.vue');

addRoutes(
    [
        {
            path: '/',
            component: Layout.Menu,
            props: MenuProps,
            children: [
                // ...OthersRoutes,
                ...demoRoutes,
                {
                    path: '/index',
                    name: 'index',
                    component: () => import('@/pages/index.vue'),
                    meta: {
                        title: '首页',
                    },
                },
                // ---平台路由
                ...[
                    // 系统管理
                    ...[
                        // 系统设置
                        ...[
                            {
                                path: '/rs/set/org',
                                name: 'rs-set-org',
                                component: rsSetOrg,
                                meta: {
                                    title: '部门管理',
                                },
                            },
                            {
                                path: '/rs/set/role',
                                name: 'rs-set-role',
                                component: rsSetRole,
                                meta: {
                                    title: '角色管理',
                                },
                            },
                            {
                                path: '/rs/set/variable',
                                name: 'rs-set-variable',
                                component: () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/variable.vue'),
                                meta: {
                                    title: '系统变量',
                                },
                            },
                            {
                                path: '/rs/set/region',
                                name: 'rs-set-region',
                                component: () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/region.vue'),
                                meta: {
                                    title: '区域管理',
                                },
                            },
                            {
                                path: '/rs/set/menu',
                                name: 'rs-set-menu',
                                component: () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/menu.vue'),
                                meta: {
                                    title: '菜单管理',
                                },
                            },
                            {
                                path: '/rs/set/user',
                                name: 'rs-set-user',
                                component: rsSetUser,
                                meta: {
                                    title: '用户管理',
                                },
                            },
                            {
                                path: '/rs/set/app',
                                name: 'rs-set-app',
                                component: () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/app.vue'),
                                meta: {
                                    title: '应用管理',
                                },
                            },
                            {
                                path: '/rs/set/dic',
                                name: 'rs-set-dic',
                                component: () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/dic.vue'),
                                meta: {
                                    title: '字典管理',
                                },
                            },
                            {
                                path: '/rs/set/express',
                                name: 'rs-set-express',
                                component: () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/express.vue'),
                                meta: {
                                    title: '快递公司管理',
                                },
                            },
                        ],
                    ],
                    // 运营商管理
                    ...[
                        // 运营商管理
                        ...[
                            {
                                path: '/pfsv/svmg/servant',
                                name: 'pfsv-svmg-servant',
                                component: () =>
                                    import(/* webpackChunkName: "sv-svmg" */ '@/pages/sv/svmg/servant.vue'),
                                meta: {
                                    title: '运营商管理',
                                },
                            },
                            {
                                path: '/pfsv/svmg/servant/edit/:id?',
                                name: 'pfsv-svmg-servant-edit',
                                component: () =>
                                    import(/* webpackChunkName: "sv-svmg" */ '@/pages/sv/svmg/servantEdit.vue'),
                                meta: {
                                    title: '运营商管理',
                                    parentUrl: '/pfsv/svmg/servant',
                                },
                            },
                            {
                                path: '/pfsv/svmg/servant/servantPaymentConfig/:id?',
                                name: 'pfsv-svmg-servant-servantPaymentConfig',
                                component: () =>
                                    import(/* webpackChunkName: "sv-svmg" */ '@/pages/sv/svmg/servantPaymentConfig.vue'),
                                meta: {
                                    title: '运营商管理',
                                    parentUrl: '/pfsv/svmg/servant',
                                },
                            },
                        ],
                    ],
                    // 平台运营
                    ...[
                        // 平台设置
                        ...[
                            {
                                path: '/pfom/set/wxmsgOrg',
                                name: 'pfom-set-wxmsgOrg',
                                component: () =>
                                    import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/wxmsgOrg.vue'),
                                meta: {
                                    title: '微信消息模板',
                                },
                            },
                            {
                                path: '/pfom/set/wxmsgTemplate/:id?',
                                name: 'pfom-set-wxmsgTemplate',
                                component: () =>
                                    import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/wxmsgTemplate.vue'),
                                meta: {
                                    title: '微信消息模板',
                                    parentUrl: '/pfom/set/wxmsgOrg',
                                },
                            },
                            {
                                path: '/pfom/set/smsOrg',
                                name: 'pfom-set-smsOrg',
                                component: () =>
                                    import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/smsOrg.vue'),
                                meta: {
                                    title: '短信模板',
                                },
                            },
                            {
                                path: '/pfom/set/smsTemplate/:id?',
                                name: 'pfom-set-smsTemplate',
                                component: () =>
                                    import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/smsTemplate.vue'),
                                meta: {
                                    title: '短信模板',
                                    parentUrl: '/pfom/set/smsOrg',
                                },
                            },
                            {
                                path: '/pfom/set/smsAccount',
                                name: 'pfom-set-smsAccount',
                                component: () =>
                                    import(/* webpackChunkName: "pfom-set" */ '@/pages/pfom/set/smsAccount.vue'),
                                meta: {
                                    title: '短信设置',
                                },
                            },
                        ],
                    ],
                ],
                // ---运营商路由
                ...[
                    // 基础数据
                    ...[
                        // 权限管理
                        ...[
                            {
                                path: '/sv/set/org',
                                name: 'sv-set-org',
                                component: rsSetOrg,
                                meta: {
                                    title: '部门管理',
                                },
                            },
                            {
                                path: '/sv/set/role',
                                name: 'sv-set-role',
                                component: rsSetRole,
                                meta: {
                                    title: '角色管理',
                                },
                            },
                            {
                                path: '/sv/set/user', // 复用 rs-set-user .vue
                                name: 'sv-set-user',
                                component: rsSetUser,
                                meta: {
                                    title: '用户管理',
                                },
                            },
                        ],
                        // 员工管理
                        ...[
                            {
                                path: '/rs/set/employee',
                                name: 'rs-set-employee',
                                component: () => import(/* webpackChunkName: "rs-set" */ '@/pages/rs/set/employee.vue'),
                                meta: {
                                    title: '员工列表',
                                },
                            },
                        ],
                        // 模块管理
                        ...[
                            {
                                path: '/sv/module/moduleCat',
                                name: 'sv-module-moduleCat',
                                component: () =>
                                    import(/* webpackChunkName: "sv-module" */ '@/pages/sv/module/moduleCat.vue'),
                                meta: {
                                    title: '模块管理',
                                },
                            },
                        ],
                        // 运营商管理
                        ...[
                            {
                                path: '/svsv/svmg/servantInfo',
                                name: 'svsv-svmg-servantInfo',
                                component: () =>
                                    import(/* webpackChunkName: "sv-module" */ '@/pages/sv/svmg/servantInfo.vue'),
                                meta: {
                                    title: '运营商信息',
                                },
                            },
                        ],
                    ],
                    // 会员管理
                    ...[
                        // 会员管理
                        ...[
                            {
                                path: '/mb/member/member',
                                name: 'mb-member-member',
                                component: () =>
                                    import(/* webpackChunkName: "mb-member" */ '@/pages/mb/member/member.vue'),
                                meta: {
                                    title: '会员列表',
                                },
                            },
                            {
                                path: '/mb/member/memberLevel',
                                name: 'mb-member-memberLevel',
                                component: () =>
                                    import(/* webpackChunkName: "mb-member" */ '@/pages/mb/member/memberLevel.vue'),
                                meta: {
                                    title: '会员等级列表',
                                },
                            },
                        ],
                        // 积分管理
                        ...[
                            {
                                path: '/mb/point/pointRule',
                                name: 'mb-point-pointRule',
                                component: () =>
                                    import(/* webpackChunkName: "mb-point" */ '@/pages/mb/point/pointRule.vue'),
                                meta: {
                                    title: '积分场景',
                                },
                            },
                        ],
                        // 成长值管理
                        ...[
                            {
                                path: '/mb/growthValue/growthValueRule',
                                name: 'mb-growthValue-growthValueRule',
                                component: () =>
                                    import(/* webpackChunkName: "mb-growthValue" */ '@/pages/mb/growthValue/growthValueRule.vue'),
                                meta: {
                                    title: '成长值场景',
                                },
                            },
                        ],
                        // 消息管理
                        ...[
                            {
                                path: '/ms/userMessage/userMessageSend',
                                name: 'ms-userMessage-userMessageSend',
                                component: () =>
                                    import(/* webpackChunkName: "ms-svmg" */ '@/pages/ms/userMessage/userMessageSend.vue'),
                                meta: {
                                    title: '消息管理',
                                },
                            },
                            {
                                path: '/ms/userMessage/userMessageSend/edit/:id?',
                                name: 'ms-userMessage-userMessageSend-edit',
                                component: () =>
                                    import(/* webpackChunkName: "ms-svmg" */ '@/pages/ms/userMessage/userMessageSendEdit.vue'),
                                meta: {
                                    title: '消息管理',
                                    parentUrl: '/ms/userMessage/userMessageSend',
                                },
                            },
                        ],
                    ],
                    // 运营管理
                    ...[
                        // 店铺装修
                        ...[
                            {
                                path: '/sv/decoration/homeDecoration',
                                name: 'sv-decoration-homeDecoration',
                                component: () =>
                                    import(/* webpackChunkName: "sv-decoration" */ '@/pages/sv/decoration/homeDecoration.vue'),
                                meta: {
                                    title: '首页装修',
                                },
                            },
                            {
                                path: '/sv/decoration/adPageDecoration',
                                name: 'sv-decoration-adPageDecoration',
                                component: () =>
                                    import(/* webpackChunkName: "sv-decoration" */ '@/pages/sv/decoration/adPageDecoration.vue'),
                                meta: {
                                    title: '广告位装修',
                                },
                            },
                            // 装修编辑
                            {
                                path: '/sv/decoration/home/edit/:id?',
                                name: 'sv-decoration-home-edit',
                                component: () =>
                                    import(/* webpackChunkName: "sv-decoration" */ '@/pages/sv/decoration/edit.vue'),
                                meta: {
                                    title: '首页装修',
                                    parentUrl: '/sv/decoration/homeDecoration',
                                },
                            },
                            {
                                path: '/sv/decoration/adPage/edit/:id?',
                                name: 'sv-decoration-adPage-edit',
                                component: () =>
                                    import(/* webpackChunkName: "sv-decoration" */ '@/pages/sv/decoration/edit.vue'),
                                meta: {
                                    title: '广告位装修',
                                    parentUrl: '/sv/decoration/adPageDecoration',
                                },
                            },
                            {
                                path: '/sv/decoration/activityDecoration',
                                name: 'sv-decoration-activityDecoration',
                                component: () =>
                                    import(/* webpackChunkName: "sv-decoration" */ '@/pages/sv/decoration/activityDecoration.vue'),
                                meta: {
                                    title: '活动装修',
                                },
                            },
                            {
                                path: '/sv/decoration/activity/edit/:id?',
                                name: 'sv-decoration-activity-edit',
                                component: () =>
                                    import(/* webpackChunkName: "sv-decoration" */ '@/pages/sv/decoration/edit.vue'),
                                meta: {
                                    title: '活动装修',
                                    parentUrl: '/sv/decoration/activityDecoration',
                                },
                            },
                        ],
                        // 建议反馈
                        ...[
                            {
                                path: '/mb/feedback/feedback',
                                name: 'mb-feedback-feedback',
                                component: () =>
                                    import(/* webpackChunkName: "mb-feedback" */ '@/pages/mb/feedback/feedback.vue'),
                                meta: {
                                    title: '建议反馈',
                                },
                            },
                        ],
                        // 营销活动
                        ...[
                            {
                                path: '/pm/coupon/couponGiveProvide',
                                name: 'pm-coupon-couponGiveProvide',
                                component: () =>
                                    import(/* webpackChunkName: "pm-coupon" */ '@/pages/pm/coupon/couponGiveProvide.vue'),
                                meta: {
                                    title: '优惠券发放',
                                },
                            },
                            {
                                path: '/pm/coupon/couponGiveProvide/edit/:id?',
                                name: 'pm-coupon-couponGiveProvide-edit',
                                component: () =>
                                    import(/* webpackChunkName: "pm-coupon" */ '@/pages/pm/coupon/couponGiveProvideEdit.vue'),
                                meta: {
                                    title: '优惠券发放',
                                    parentUrl: '/pm/coupon/couponGiveProvide',
                                },
                            },
                            {
                                path: '/pm/coupon/couponGiveReceive',
                                name: 'pm-coupon-couponGiveReceive',
                                component: () =>
                                    import(/* webpackChunkName: "pm-coupon" */ '@/pages/pm/coupon/couponGiveReceive.vue'),
                                meta: {
                                    title: '优惠券领取',
                                },
                            },
                            {
                                path: '/pm/coupon/couponGiveReceive/edit/:id?',
                                name: 'pm-coupon-couponGiveReceive-edit',
                                component: () =>
                                    import(/* webpackChunkName: "pm-coupon" */ '@/pages/pm/coupon/couponGiveReceiveEdit.vue'),
                                meta: {
                                    title: '优惠券领取',
                                    parentUrl: '/pm/coupon/couponGiveReceive',
                                },
                            },
                        ],
                    ],
                    // 证书管理
                    ...[
                        //证书管理
                        ...[
                            {
                                path: '/sv/cert/certCat',
                                name: 'sv-cert-certCat',
                                component: () =>
                                    import(/* webpackChunkName: "sv-cert" */ '@/pages/sv/cert/certCat.vue'),
                                meta: {
                                    title: '证书分类',
                                },
                            },
                            {
                                path: '/sv/cert/cert',
                                name: 'sv-cert-cert',
                                component: () => import(/* webpackChunkName: "sv-cert" */ '@/pages/sv/cert/cert.vue'),
                                meta: {
                                    title: '证书列表',
                                },
                            },
                        ],
                    ],
                    // 题目管理
                    ...[
                        // 题目管理
                        ...[
                            {
                                path: '/sv/question/questionCat',
                                name: 'sv-question-questionCat',
                                component: () =>
                                    import(/* webpackChunkName: "sv-question" */ '@/pages/sv/question/questionCat.vue'),
                                meta: {
                                    title: '题目分类',
                                },
                            },
                            {
                                path: '/sv/question/question',
                                name: 'sv-question-question',
                                component: () =>
                                    import(/* webpackChunkName: "sv-question" */ '@/pages/sv/question/question.vue'),
                                meta: {
                                    title: '题目列表',
                                },
                            },
                            {
                                path: '/sv/question/question/edit/:id?',
                                name: 'sv-question-questionEdit',
                                component: () =>
                                    import(/* webpackChunkName: "sv-question" */ '@/pages/sv/question/questionEdit.vue'),
                                meta: {
                                    title: '题目列表',
                                    parentUrl: '/sv/question/question',
                                },
                            },
                        ],
                    ],
                    // 考试管理
                    ...[
                        //试卷模板管理
                        ...[
                            {
                                path: '/ex/paperTemplate/paperTemplate',
                                name: 'ex-paperTemplate-paperTemplate',
                                component: () =>
                                    import(/* webpackChunkName: "ex-paperTemplate" */ '@/pages/ex/paperTemplate/paperTemplate.vue'),
                                meta: {
                                    title: '试卷模板',
                                },
                            },
                            {
                                path: '/ex/paperTemplate/paperTemplate/edit/:id?',
                                name: 'ex-paperTemplate-paperTemplateEdit',
                                component: () =>
                                    import(/* webpackChunkName: "ex-paperTemplate" */ '@/pages/ex/paperTemplate/paperTemplateEdit.vue'),
                                meta: {
                                    title: '试卷模板',
                                    parentUrl: '/ex/paperTemplate/paperTemplate',
                                },
                            },
                        ],
                        //考试管理
                        ...[
                            {
                                path: '/ex/exam/exam',
                                name: 'ex-exam-exam',
                                component: () => import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/exam.vue'),
                                meta: {
                                    title: '考试列表',
                                },
                            },
                            {
                                path: '/ex/exam/exam/edit/:id?',
                                name: 'ex-exam-examEdit',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/examEdit.vue'),
                                meta: {
                                    title: '考试列表',
                                    parentUrl: '/ex/exam/exam',
                                },
                            },
                            {
                                path: '/ex/exam/exam/examMember/:id?',
                                name: 'ex-exam-examMember',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/examMember.vue'),
                                meta: {
                                    title: '考试名单',
                                    parentUrl: '/ex/exam/exam',
                                },
                            },
                            {
                                path: '/ex/exam/exam/examStat/:examId?/:orgId?',
                                name: 'ex-exam-examStat',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/examStat.vue'),
                                meta: {
                                    title: '统计报表',
                                    parentUrl: '/ex/exam/exam',
                                    keepOnly: true,
                                },
                            },
                            {
                                path: '/ex/exam/exam/examCompanyStat/:examId?',
                                name: 'ex-exam-examCompanyStat',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/examCompanyStat.vue'),
                                meta: {
                                    title: '统计报表',
                                    parentUrl: '/ex/exam/exam',
                                    keepOnly: true,
                                },
                            },
                            {
                                path: '/ex/exam/examCheck',
                                name: 'ex-exam-examCheck',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/examCheck.vue'),
                                meta: {
                                    title: '抽查列表',
                                },
                            },
                            {
                                path: '/ex/exam/examCheck/edit/:id?',
                                name: 'ex-exam-examCheckEdit',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/examCheckEdit.vue'),
                                meta: {
                                    title: '抽查列表',
                                    parentUrl: '/ex/exam/examCheck',
                                },
                            },
                            {
                                path: '/ex/exam/examGame',
                                name: 'ex-exam-examGame',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/examGame.vue'),
                                meta: {
                                    title: '游戏列表',
                                },
                            },
                            {
                                path: '/ex/exam/examGame/edit/:id?',
                                name: 'ex-exam-examGameEdit',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/examGameEdit.vue'),
                                meta: {
                                    title: '游戏列表',
                                    parentUrl: '/ex/exam/examGame',
                                },
                            },
                            {
                                path: '/ex/exam/examReport',
                                name: 'ex-exam-examReport',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/examReport.vue'),
                                meta: {
                                    title: '统计报表',
                                },
                            },
                            {
                                path: '/ex/exam/exam/examReportStat/:examId?/:orgId?',
                                name: 'ex-exam-examReportStat',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/examStat.vue'),
                                meta: {
                                    title: '统计报表',
                                    parentUrl: '/ex/exam/examReport',
                                    keepOnly: true,
                                },
                            },
                            {
                                path: '/ex/exam/exam/examReportCompanyStat/:examId?',
                                name: 'ex-exam-examReportCompanyStat',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/examCompanyStat.vue'),
                                meta: {
                                    title: '统计报表',
                                    parentUrl: '/ex/exam/examReport',
                                    keepOnly: true,
                                },
                            },
                            {
                                path: '/ex/exam/courseExam',
                                name: 'ex-exam-courseExam',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/courseExam.vue'),
                                meta: {
                                    title: '精品课程列表',
                                },
                            },
                            {
                                path: '/ex/exam/courseExam/edit/:id?',
                                name: 'ex-exam-courseExamEdit',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/exam/examEdit.vue'),
                                meta: {
                                    title: '精品课程列表',
                                    parentUrl: '/ex/exam/courseExam',
                                },
                            },
                        ],
                        // 报名管理
                        ...[
                            {
                                path: '/ex/examApply/examApply',
                                name: 'ex-examApply-examApply',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/examApply/examApply.vue'),
                                meta: {
                                    title: '报名列表',
                                },
                            },
                            {
                                path: '/ex/examApply/examApply/paperList/:id?',
                                name: 'ex-examApply-examApply',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/examApply/examApplyPaper.vue'),
                                meta: {
                                    title: '试卷列表',
                                    parentUrl: '/ex/examApply/examApply',
                                },
                            },
                            {
                                path: '/ex/examApply/examApply/paperDetail/:id?',
                                name: 'ex-examApply-examApply',
                                component: () =>
                                    import(/* webpackChunkName: "ex-exam" */ '@/pages/ex/examApply/examApplyPaperDetail.vue'),
                                meta: {
                                    title: '试卷详情',
                                    parentUrl: '/ex/examApply/examApply',
                                },
                            },
                        ],
                        // 人员管理
                        ...[
                            {
                                path: '/ex/orgMember/orgMemberCat',
                                name: 'ex-orgMember-orgMemberCat',
                                component: () =>
                                    import(/* webpackChunkName: "ex-orgMember" */ '@/pages/ex/orgMember/orgMemberCat.vue'),
                                meta: {
                                    title: '人员分类',
                                },
                            },
                            {
                                path: '/ex/orgMember/orgMember',
                                name: 'ex-orgMember-orgMember',
                                component: () =>
                                    import(/* webpackChunkName: "ex-orgMember" */ '@/pages/ex/orgMember/orgMember.vue'),
                                meta: {
                                    title: '人员列表',
                                },
                            },
                        ],
                        // 教师管理
                        ...[
                            {
                                path: '/sv/teacher/teacher',
                                name: 'sv-teacher-teacher',
                                component: () =>
                                    import(/* webpackChunkName: "sv-teacher" */ '@/pages/sv/teacher/teacher.vue'),
                                meta: {
                                    title: '教师列表',
                                },
                            },
                        ],
                    ],
                    // 课程管理
                    ...[
                        //课程管理
                        ...[
                            {
                                path: '/sv/course/courseCat',
                                name: 'sv-course-courseCat',
                                component: () =>
                                    import(/* webpackChunkName: "sv-course" */ '@/pages/sv/course/courseCat.vue'),
                                meta: {
                                    title: '课程分类',
                                },
                            },
                            {
                                path: '/sv/course/course',
                                name: 'sv-course-course',
                                component: () =>
                                    import(/* webpackChunkName: "sv-course" */ '@/pages/sv/course/course.vue'),
                                meta: {
                                    title: '课程列表',
                                },
                            },
                            {
                                path: '/sv/course/course/edit/:id?',
                                name: 'sv-course-courseEdit',
                                component: () =>
                                    import(/* webpackChunkName: "sv-course" */ '@/pages/sv/course/courseEdit.vue'),
                                meta: {
                                    title: '课程列表',
                                    parentUrl: '/sv/course/course',
                                },
                            },
                        ],
                        //文档管理
                        ...[
                            {
                                path: '/gd/document/documentCat',
                                name: 'gd-document-documentCat',
                                component: () =>
                                    import(/* webpackChunkName: "gd-document" */ '@/pages/gd/document/documentCat.vue'),
                                meta: {
                                    title: '文档分类',
                                },
                            },
                            {
                                path: '/gd/document/document',
                                name: 'gd-document-document',
                                component: () =>
                                    import(/* webpackChunkName: "gd-document" */ '@/pages/gd/document/document.vue'),
                                meta: {
                                    title: '文档列表',
                                },
                            },
                        ],
                    ],
                    // CMS管理
                    ...[
                        // CMS分类
                        ...[
                            {
                                path: '/pt/cms/cmsCatalog',
                                name: 'pt-cms-cmsCatalog',
                                component: () => import(/* webpackChunkName: "pt-cms" */ '@/pages/pt/cms/cmsCatalog'),
                                meta: {
                                    title: 'CMS分类',
                                },
                            },
                        ],
                        // CMS文章
                        ...[
                            {
                                path: '/pt/cms/cmsArticle',
                                name: 'pt-cms-cmsArticle',
                                component: () => import(/* webpackChunkName: "pt-cms" */ '@/pages/pt/cms/cmsArticle'),
                                meta: {
                                    title: 'CMS文章',
                                },
                            },
                            {
                                path: '/pt/cms/cmsArticle/edit/:cmsArticleType?/:id?',
                                name: 'pt-cms-cmsArticle-edit',
                                component: () =>
                                    import(/* webpackChunkName: "pt-cms" */ '@/pages/pt/cms/cmsArticleEdit.vue'),
                                meta: {
                                    title: 'CMS文章',
                                    parentUrl: '/pt/cms/cmsArticle',
                                },
                            },
                        ],
                    ],
                    // 积分商城
                    ...[
                        //积分商品
                        ...[
                            {
                                path: '/gd/pointGoods/pointGoodsCat',
                                name: 'gd-pointGoods-pointGoodsCat',
                                component: () =>
                                    import(/* webpackChunkName: "gd-pointGoods" */ '@/pages/gd/pointGoods/pointGoodsCat.vue'),
                                meta: {
                                    title: '积分商品分类',
                                },
                            },
                            {
                                path: '/gd/pointGoods/pointGoods',
                                name: 'gd-pointGoods-pointGoods',
                                component: () =>
                                    import(/* webpackChunkName: "gd-pointGoods" */ '@/pages/gd/pointGoods/pointGoods.vue'),
                                meta: {
                                    title: '积分商品列表',
                                },
                            },
                        ],
                        //积分订单
                        ...[
                            {
                                path: '/gd/pointGoodsOrder/pointGoodsOrder',
                                name: 'gd-pointGoodsOrder-pointGoodsOrder',
                                component: () =>
                                    import(/* webpackChunkName: "gd-pointGoods" */ '@/pages/gd/pointGoodsOrder/pointGoodsOrder.vue'),
                                meta: {
                                    title: '订单列表',
                                },
                            },
                        ],
                    ],
                    // 商品管理
                    ...[
                        //商品管理
                        ...[
                            {
                                path: '/gd/goods/goodsCat',
                                name: 'gd-goods-goodsCat',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/goodsCat.vue'),
                                meta: {
                                    title: '商品分类',
                                },
                            },
                            {
                                path: '/gd/goods/brand',
                                name: 'gd-goods-brand',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/brand/brand.vue'),
                                meta: {
                                    title: '品牌列表',
                                },
                            },
                            {
                                path: '/gd/goods/goods',
                                name: 'gd-goods-goods',
                                component: () =>
                                    import(/* webpackChunkName: "gd-goods" */ '@/pages/gd/goods/goods.vue'),
                                meta: {
                                    title: '商品列表',
                                },
                            },
                        ],
                    ],
                    // 数据上报
                    ...[
                        //体温上报
                        ...[
                            {
                                path: '/mb/temperatureReport/memberTemperatureReport',
                                name: 'mb-temperatureReport-memberTemperatureReport',
                                component: () =>
                                    import(/* webpackChunkName: "mb-temperatureReport" */ '@/pages/mb/temperatureReport/memberTemperatureReport.vue'),
                                meta: {
                                    title: '体温上报',
                                },
                            },
                        ],
                        //预约
                        ...[
                            {
                                path: '/mb/memberReserve/memberReserve',
                                name: 'mb-memberReserve-memberReserve',
                                component: () =>
                                    import(/* webpackChunkName: "mb-memberReserve" */ '@/pages/mb/memberReserve/memberReserve.vue'),
                                meta: {
                                    title: '预约列表',
                                },
                            },
                            {
                                path: '/mb/memberReserve/memberReserve/detail/:id?',
                                name: 'mb-memberReserve-memberReserveDetail',
                                component: () =>
                                    import(/* webpackChunkName: "mb-memberReserve" */ '@/pages/mb/memberReserve/memberReserveDetail.vue'),
                                meta: {
                                    title: '预约列表',
                                    parentUrl: '/mb/memberReserve/memberReserve',
                                },
                            },
                        ],
                    ],
                    // 招聘求职管理
                    ...[
                        // 招聘管理
                        ...[
                            {
                                path: '/mb/jobRecruit/jobRecruit',
                                name: 'mb-jobRecruit-jobRecruit',
                                component: () =>
                                    import(/* webpackChunkName: "mb-jobRecruit" */ '@/pages/mb/jobRecruit/jobRecruit.vue'),
                                meta: {
                                    title: '招聘列表',
                                },
                            },
                            {
                                path: '/mb/jobRecruit/jobRecruit/edit/:id?',
                                name: 'mb-jobRecruit-jobRecruit-edit',
                                component: () =>
                                    import(/* webpackChunkName: "mb-jobRecruit" */ '@/pages/mb/jobRecruit/jobRecruitEdit.vue'),
                                meta: {
                                    title: '编辑招聘信息',
                                    parentUrl: '/mb/jobRecruit/jobRecruit',
                                },
                            },
                            {
                                path: '/mb/jobRecruit/jobRecruit/view/:id',
                                name: 'mb-jobRecruit-jobRecruit-view',
                                component: () =>
                                    import(/* webpackChunkName: "mb-jobRecruit" */ '@/pages/mb/jobRecruit/jobRecruitView.vue'),
                                meta: {
                                    title: '招聘详情',
                                    parentUrl: '/mb/jobRecruit/jobRecruit',
                                },
                            },
                        ],
                        // 求职管理
                        ...[
                            {
                                path: '/mb/jobWanted/jobWanted',
                                name: 'mb-jobWanted-jobWanted',
                                component: () =>
                                    import(/* webpackChunkName: "mb-jobWanted" */ '@/pages/mb/jobWanted/jobWanted.vue'),
                                meta: {
                                    title: '求职列表',
                                },
                            },
                            {
                                path: '/mb/jobWanted/jobWanted/edit/:id?',
                                name: 'mb-jobWanted-jobWanted-edit',
                                component: () =>
                                    import(/* webpackChunkName: "mb-jobWanted" */ '@/pages/mb/jobWanted/jobWantedEdit.vue'),
                                meta: {
                                    title: '编辑求职信息',
                                    parentUrl: '/mb/jobWanted/jobWanted',
                                },
                            },
                            {
                                path: '/mb/jobWanted/jobWanted/view/:id',
                                name: 'mb-jobWanted-jobWanted-view',
                                component: () =>
                                    import(/* webpackChunkName: "mb-jobWanted" */ '@/pages/mb/jobWanted/jobWantedView.vue'),
                                meta: {
                                    title: '求职详情',
                                    parentUrl: '/mb/jobWanted/jobWanted',
                                },
                            },
                        ],
                    ],
                    // 帖子管理
                    ...[
                        {
                            path: '/mb/post/post',
                            name: 'mb-post-post',
                            component: () => import(/* webpackChunkName: "mb-post" */ '@/pages/mb/post/post.vue'),
                            meta: {
                                title: '帖子列表',
                            },
                        },
                        {
                            path: '/mb/post/post/view/:id',
                            name: 'mb-post-post-view',
                            component: () => import(/* webpackChunkName: "mb-post" */ '@/pages/mb/post/postView.vue'),
                            meta: {
                                title: '帖子详情',
                                parentUrl: '/mb/post/post',
                            },
                        },
                        {
                            path: '/mb/post/postComment/:postId/',
                            name: 'mb-post-postComment',
                            component: () =>
                                import(/* webpackChunkName: "mb-post" */ '@/pages/mb/post/postComment.vue'),
                            meta: {
                                title: '帖子评论',
                                parentUrl: '/mb/post/post',
                            },
                        },
                        {
                            path: '/mb/post/postComment/view/:id',
                            name: 'mb-post-postComment-view',
                            component: () =>
                                import(/* webpackChunkName: "mb-post" */ '@/pages/mb/post/postCommentView.vue'),
                            meta: {
                                title: '帖子评论详情',
                                parentUrl: '/mb/post/post',
                            },
                        },
                    ],
                    // 登记表管理
                    ...[
                        {
                            path: '/ex/registerForm/registerFormBatch',
                            name: 'ex-registerForm-registerFormBatch',
                            component: () =>
                                import(/* webpackChunkName: "ex-registerForm" */ '@/pages/ex/registerForm/registerFormBatch.vue'),
                            meta: {
                                title: '登记表批次',
                            },
                        },
                        {
                            path: '/ex/registerForm/registerForm/:formBatchId',
                            name: 'ex-registerForm-registerForm',
                            component: () =>
                                import(/* webpackChunkName: "ex-registerForm" */ '@/pages/ex/registerForm/registerForm.vue'),
                            meta: {
                                title: '登记表',
                                parentUrl: '/ex/registerForm/registerFormBatch',
                            },
                        },
                        {
                            path: '/ex/registerForm/registerForm/edit/:formBatchId/:id?',
                            name: 'ex-registerForm-registerForm-edit',
                            component: () =>
                                import(/* webpackChunkName: "ex-registerForm" */ '@/pages/ex/registerForm/registerFormEdit.vue'),
                            meta: {
                                title: '编辑登记表',
                                parentUrl: '/ex/registerForm/registerFormBatch',
                            },
                        },
                        {
                            path: '/ex/registerForm/registerForm/view/:id',
                            name: 'ex-registerForm-registerForm-view',
                            component: () =>
                                import(/* webpackChunkName: "ex-registerForm" */ '@/pages/ex/registerForm/registerFormView.vue'),
                            meta: {
                                title: '登记表详情',
                                parentUrl: '/ex/registerForm/registerFormBatch',
                            },
                        },
                    ],
                ],
            ],
        },
    ],
    async () => {
        console.log('初始路由数据开始', 111111);
        const loadingInstance = Loading.service({
            lock: true,
            text: '正在加载所需数据,请稍后...',
        });
        const jsons = await Promise.all([Api.Rs.User.menu(), Api.Rs.User.menuResource()]).finally(() => {
            loadingInstance.close();
        });
        MenuProps.data = jsons[0].data.data;
        addPower(jsons[1].data.data);
    },
);

// export default router;
