/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sv } from './Sv';

class Question extends Sv {
    constructor() {
        super();
        this.baseUrl += '/question';
    }

    data({ title, questionCatId, questionType, currentPage, pageSize } = {}) {
        return super.get('/data', {
            title,
            questionCatId,
            questionType,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    save({
        id,
        questionCatId,
        title,
        answerAnalysis,
        questionType,
        questionOptionType,
        questionAnswer,
        optionConfig,
    } = {}) {
        return super.post('/save', {
            id,
            questionCatId,
            title,
            answerAnalysis,
            questionType,
            questionOptionType,
            questionAnswer,
            optionConfig,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new Question();
export { Question };
