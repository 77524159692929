/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class ExamApply extends Ex {
    constructor() {
        super();
        this.baseUrl += '/examApply';
    }

    data({
        applyNo,
        examId,
        fullName,
        mobile,
        examOrgId,
        applyStatus,
        examApplyTime,
        examApplyPaperTime,
        tags,
        payAuditUserName,
        companyName,
        currentCompanyName,
        currentPage,
        pageSize,
    } = {}) {
        let examApplyTimeBegin = null;
        let examApplyTimeEnd = null;
        if (examApplyTime != null && examApplyTime.length === 2) {
            examApplyTimeBegin = examApplyTime[0];
            examApplyTimeEnd = examApplyTime[1];
        }
        let examApplyPaperTimeBegin = null;
        let examApplyPaperTimeEnd = null;
        if (examApplyPaperTime != null && examApplyPaperTime.length === 2) {
            examApplyPaperTimeBegin = examApplyPaperTime[0];
            examApplyPaperTimeEnd = examApplyPaperTime[1];
        }
        return super.get('/data', {
            applyNo,
            examId,
            fullName,
            mobile,
            examOrgId,
            applyStatus,
            examApplyTimeBegin,
            examApplyTimeEnd,
            examApplyPaperTimeBegin,
            examApplyPaperTimeEnd,
            tags,
            payAuditUserName,
            companyName,
            currentCompanyName,
            currentPage,
            pageSize,
        });
    }

    payExamApply({ id, payVoucher, paymentComments }) {
        const payVoucherStr = payVoucher
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        return super.post('/payExamApply', { id, payVoucher: payVoucherStr, paymentComments });
    }

    getDetail({ id }) {
        return super.get('/get', { id });
    }

    editExamApply({ id, fullName, sex, idNumber, companyName, currentCompanyName } = {}) {
        return super.post('/editExamApply', {
            id,
            fullName,
            sex,
            idNumber,
            companyName,
            currentCompanyName,
        });
    }

    batchSetViewCourse({ ids, isViewCourse }) {
        return super.post('/batchSetViewCourse', { ids, isViewCourse });
    }

    saveTags({ ids, tags }) {
        return super.post('/saveTags', { ids, tags });
    }

    freeCourse({ ids }) {
        return super.post('/freeCourse', { ids });
    }
}

export default new ExamApply();
export { ExamApply };
