/**
 * Created by henian.xu on 2019/11/18.
 *
 */

import Vue from 'vue';
import Filter from '@vmf/filter';
import Power from '@vmf/power';
import { GlobalVar } from '@vmf/shared';
import { router } from '@vmf/router';
import { store } from '@vmf/vuex';
import Plugin from './plugin';
import Api from './api';
import UtilsInstall from './Utils';

import './store';

export { addPower, hasPower } from '@vmf/power';
export * from '@vmf/request';
export * from '@vmf/storage';
export * from '@vmf/router';
export * from '@vmf/vuex';
export * from './Utils';

export { Vue, GlobalVar };

Vue.use(Filter);
Vue.use(Power);
Vue.use(Plugin);
Vue.use(Api);
Vue.use(UtilsInstall);

const requireProject = (require as any).context('@', true, /main\.js$/);
requireProject.keys().forEach((fileName: string) => {
    // 只会获取1级和2级目录下的main.js文件
    if (fileName.split('/').length > 3) return;
    requireProject(fileName);
});

// TODO 处理使用 cdn 引用vue.min.js 时无效的问题
// Vue.config.productionTip = Utils.GlobalVar.productionTip;

// vue-meta 处理标题
const keyName =
    (GlobalVar.appConfig && GlobalVar.appConfig.VUE_META && GlobalVar.appConfig.VUE_META.keyName) || 'metaInfo';
new Vue({
    router,
    store,
    [keyName]() {
        const {
            meta: { title = '', subTitle = '' },
        } = this.$route;
        return { title: `${title}${subTitle ? `-${subTitle}` : subTitle}` || GlobalVar.DocTitle };
    },
    render: h =>
        h(
            'div',
            {
                attrs: {
                    id: 'app',
                },
            },
            [h('router-view')],
        ),
}).$mount('#app');
