/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import Rs from './rs';
import Ms from './ms';
import Sv from './sv';
import Mb from './mb';
import Ex from './ex';
import Pt from './pt';
import Gd from './gd';
import Pm from './pm';

export const Api = {
    Rs,
    Ms,
    Sv,
    Mb,
    Ex,
    Pt,
    Gd,
    Pm,
};
export default Api;
