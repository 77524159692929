/**
 * Created by henian.xu on 2018/7/19.
 *
 */

import SmsAccount from './SmsAccount';
import SmsTemplate from './SmsTemplate';
import WxmsgTemplate from './WxmsgTemplate';
import UserMessageSend from './UserMessageSend';

export default {
    SmsAccount,
    SmsTemplate,
    WxmsgTemplate,
    UserMessageSend,
};
