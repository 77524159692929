<template>
    <page back>
        <template slot="body">
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-card
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>招聘信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="发布者:"
                                label-width="9em"
                            >
                                {{ detail.memberName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="名称:"
                                label-width="9em"
                            >
                                {{ detail.fullName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="性别:"
                                label-width="9em"
                            >
                                {{ detail.sexName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="联系电话:"
                                label-width="9em"
                            >
                                {{ detail.tel }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="省市区:"
                                label-width="9em"
                            >
                                {{ detail.regionName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="求职意向:"
                                label-width="9em"
                            >
                                {{ detail.jobIntention }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="期望薪资:"
                                label-width="9em"
                            >
                                {{ detail.salaryExpectation }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="工作经历:"
                                label-width="9em"
                            >
                                {{ detail.workExperience }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="自我评价:"
                                label-width="9em"
                            >
                                {{ detail.selfEvaluation }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="专业技能:"
                                label-width="9em"
                            >
                                {{ detail.professionalSkills }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="招聘状态:"
                                label-width="9em"
                            >
                                {{ detail.jobWantedStatusName }}
                            </el-form-item>
                        </el-col>
                        <div v-if="detail.memberId">
                            <el-col :span="12">
                                <el-form-item
                                    label="提交时间:"
                                    label-width="9em"
                                >
                                    {{
                                        detail.submitTime | moment('YYYY-MM-DD HH:mm:ss')
                                    }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    label="审核人姓名:"
                                    label-width="9em"
                                >
                                    {{ detail.auditUserName }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    label="审核时间:"
                                    label-width="9em"
                                >
                                    {{
                                        detail.auditTime | moment('YYYY-MM-DD HH:mm:ss')
                                    }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    label="审核备注:"
                                    label-width="9em"
                                >
                                    {{ detail.auditRemarks }}
                                </el-form-item>
                            </el-col>
                        </div>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                    v-if="+detail.jobWantedStatus === 20"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>审核</span>
                    </div>
                    <el-col :span="24">
                        <el-col :span="12">
                            <el-form-item
                                prop="auditStatus"
                                label="审核是否通过:"
                                label-width="11em"
                            >
                                <el-select
                                    v-model="formModel.auditStatus"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        label="审核通过"
                                        value="100"
                                    />
                                    <el-option
                                        label="审核不通过"
                                        value="99"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col
                        :span="24"
                        v-if="+formModel.auditStatus === 99"
                    >
                        <el-col :span="12">
                            <el-form-item
                                prop="auditRemarks"
                                label="备注:"
                                label-width="11em"
                            >
                                <el-input
                                    type="textarea"
                                    v-model="formModel.auditRemarks"
                                    auto-complete="off"
                                />
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="24">
                        <el-col :span="12">
                            <el-form-item label-width="11em">
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="submit"
                                >
                                    提交审核
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>审核日志</span>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="detail.auditLogList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="auditTime"
                                    label="审核时间"
                                    min-width="100"
                                >
                                    <template slot-scope="scope">
                                        {{ scope.row.auditTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="auditorName"
                                    label="审核操作人"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="auditOpinion"
                                    label="审核意见"
                                    min-width="100"
                                />
                            </el-table>
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>操作日志</span>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="detail.operateLogList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="operateTime"
                                    label="操作时间"
                                    min-width="100"
                                >
                                    <template slot-scope="scope">
                                        {{ scope.row.operateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="operatorName"
                                    label="操作人"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="operateName"
                                    label="操作名称"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="operateNote"
                                    label="操作说明"
                                    min-width="100"
                                />
                            </el-table>
                        </el-form-item>
                    </div>
                </el-card>
            </el-form>
            <div class="pa-a ta-c">
                <el-button
                    size="small"
                    type="primary"
                    @click="$router.back()"
                >
                    返 回
                </el-button>
            </div>
        </template>
    </page>
</template>

<script>
export default {
    name: 'JobWantedView',
    data() {
        return {
            id: '',
            detail: {},
            formModel: {
                id: '',
                auditStatus: '',
                auditRemarks: '',
            },
            // 表单校验规则
            formRules: {
                auditStatus: {
                    required: true,
                    message: '请选择审核是否通过',
                },
                auditRemarks: {
                    required: true,
                    message: '请输入备注',
                    trigger: 'blur',
                },
            },
            loading: '',
        };
    },
    methods: {
        init() {
            this.$api.Mb.JobWanted.getDetail({ id: this.id, isLog: true }).then(json => {
                const res = json.data;
                this.detail = res.data ? res.data : {};
            });
        },
        submit() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm('是否提交审核！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.loading = this.$loading({
                        lock: true,
                        text: '提交中',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)',
                    });
                    this.$api.Mb.JobWanted.auditJobWanted({
                        ...this.formModel,
                    })
                        .then(json => {
                            this.loading.close();
                            const res = json.data;
                            this.$message({
                                message: res.msg,
                                type: 'success',
                            });
                            this.$router.back();
                        })
                        .catch(() => {
                            this.loading.close();
                        });
                });
            });
        },
    },
    created() {
        this.id = this.$route.params.id;
        this.formModel.id = this.id;
        if (this.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
