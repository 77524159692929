/**
 * Created by henian.xu on 2019/9/19.
 * TODO 解决在 config 配置时无法 引用 vmf 模块组的问题
 */

import DefaultImg from '@/assets/images/default.jpg';
import loginBg from '@/assets/images/login-bg.jpg';

const isDev = process.env.NODE_ENV === 'development';

// TODO 模块命名空间
export const MODULE_NAMESPACE = '';

// 默认图片
export const DEFAULT_IMG = DefaultImg;
// 登录页背景图
export const LOGIN_IMAGE_URL = loginBg;

// 用户模块 API 接口. 应该包含 login/logout 等接口
export const USER_API = '/rs/user';
// 应用 App 列表接口
export const APP_LIST_API = '/rs/app/selectByUser';

// 路由默认配置，路由表并不从此注入
export const ROUTER_DEFAULT_CONFIG = {
    mode: 'hash', //'hash' | 'history' | 'abstract'
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active',
};

// TODO vuex 默认配置
export const VUEX_DEFAULT_CONFIG = {};

// TODO 是否强制所有请求访问本地 MOCK，每个请求也可以单独控制是否请求 MOCK
export const AJAX_LOCALLY_ENABLE = false;

// axios 默认配置
export const AXIOS_DEFAULT_CONFIG = {
    baseURL: isDev ? '/devApi' : '',
    timeout: 20000,
    maxContentLength: 2000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // token: (Local.get('userData') || {}).token,
    },
    //---
    isAxiosResponseData: false,
};

export const OSS_ACTION = !isDev ? '/rs/attachment/signOSSUpload' : '';
