/**
 * Created by henian.xu on 2019/10/31.
 * 组件demo的路由
 */

export default [
    {
        path: '/demo/component/form',
        name: 'demo-component-form',
        component: () => import(/* webpackChunkName: "demo-component" */ '@/pages/demo/component/form.vue'),
        meta: {
            title: '表单示例',
        },
    },
    {
        path: '/demo/component/treeTransfer',
        name: 'demo-component-treeTransfer',
        component: () => import(/* webpackChunkName: "demo-component" */ '@/pages/demo/component/treeTransfer.vue'),
        meta: {
            title: '树形穿梭框',
        },
    },
    {
        path: '/demo/component/ECharts',
        name: 'demo-component-ECharts',
        component: () => import(/* webpackChunkName: "demo-component" */ '@/pages/demo/component/ECharts.vue'),
        meta: {
            title: '数据可视化ECharts',
        },
    },
    {
        path: '/demo/component/remoteSelect',
        name: 'demo-component-remoteSelect',
        component: () => import(/* webpackChunkName: "demo-component" */ '@/pages/demo/component/remoteSelect.vue'),
        meta: {
            title: '远程选择器组件开发',
            parentUrl: '/demo/component/form',
        },
    },
    {
        path: '/demo/component/richText',
        name: 'demo-component-richText',
        component: () => import(/* webpackChunkName: "demo-component" */ '@/pages/demo/component/richText.vue'),
        meta: {
            title: '富文本组件Tinymce',
        },
    },
    {
        path: '/demo/component/baiduMap',
        name: 'demo-component-baiduMap',
        component: () => import(/* webpackChunkName: "demo-component" */ '@/pages/demo/component/BaiduMap.vue'),
        meta: {
            title: '百度地图示例',
        },
    },
    {
        path: '/demo/component/draggable',
        name: 'demo-component-draggable',
        component: () => import(/* webpackChunkName: "demo-component" */ '@/pages/demo/component/Draggable.vue'),
        meta: {
            title: '拖拽示例',
        },
    },
    {
        path: '/demo/component/decorator',
        name: 'demo-component-decorator',
        component: () => import(/* webpackChunkName: "demo-component" */ '@/pages/demo/component/Decorator.vue'),
        meta: {
            title: '装修组件',
        },
    },
    {
        path: '/demo/component/lodop',
        name: 'demo-component-lodop',
        component: () => import(/* webpackChunkName: "demo-component" */ '@/pages/demo/component/Lodop.vue'),
        meta: {
            title: 'Lodop打印组件',
        },
    },
    {
        path: '/demo/component/fullcalendar',
        name: 'demo-component-fullcalendar',
        component: () => import(/* webpackChunkName: "demo-component" */ '@/pages/demo/component/Fullcalendar.vue'),
        meta: {
            title: '日期组件示例Fullcalendar',
        },
    },
];
