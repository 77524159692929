<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="nickName"
                    label="昵称"
                >
                    <el-input
                        v-model="queryFormModel.nickName"
                        placeholder="请输入昵称"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="电话"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="memberStatus"
                    label="用户状态"
                >
                    <dictionaries-picker
                        type="memberStatus"
                        v-model="queryFormModel.memberStatus"
                    />
                </el-form-item>
                <el-form-item
                    prop="memberLevelId"
                    label="会员等级"
                >
                    <el-select
                        v-model="queryFormModel.memberLevelId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in memberLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="insertTime"
                    label="注册时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.insertTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onBatchChangeMemberLevel"
                    >
                        批量设置会员等级
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="memberLevelName"
                    label="会员等级"
                    min-width="100"
                />
                <el-table-column
                    prop="nickName"
                    label="昵称"
                    min-width="100"
                />
                <el-table-column
                    label="头像"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.userAvatarUrl || scope.row.avatar || ''"
                            :thumbnail-width="0"
                            :preview-width="0"
                            background-size="auto"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="mobile"
                    label="电话"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.mobile }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="注册时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="memberStatusName"
                    label="用户状态"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="160"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onChangeMemberLevel(scope.row)"
                        >
                            设置会员等级
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onResetPwdClick(scope.row)"
                        >
                            重置密码
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 设置会员等级 -->
        <el-dialog
            title="设置会员等级"
            center
            width="400px"
            :visible.sync="setLevelDialog.isVisible"
            @closed="onSetLevelDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="setLevelDialog.formModel"
                :rules="setLevelDialog.formRules"
            >
                <el-form-item
                    prop="memberLevelId"
                    label="会员等级"
                    label-width="6em"
                >
                    <el-select
                        v-model="setLevelDialog.formModel.memberLevelId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in memberLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSetLevelDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSetLevelDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Member',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                nickName: '', // 昵称
                mobile: '', // 电话
                memberStatus: '', // 会员状态
                memberLevelId: '', //会员等级
                insertTime: '',
            },
            setLevelDialog: {
                // 对话框显示
                isVisible: false,
                formModel: {
                    ids: '',
                    memberLevelId: '',
                },
                // 表单校验规则
                formRules: {
                    memberLevelId: {
                        required: true,
                        message: '请选择会员等级',
                    },
                },
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            //等级列表
            memberLevelList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.Member.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onSetLevelDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.Member.changeMemberLevel({
                    ...this.setLevelDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.setLevelDialog.isVisible = false;
                });
            });
        },
        onSetLevelDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            this.setLevelDialog.isVisible = false;
        },
        onChangeMemberLevel(row) {
            this.setLevelDialog.formModel.ids = row.id;
            this.setLevelDialog.isVisible = true;
        },
        initLevelList() {
            this.$api.Mb.MemberLevel.select().then(json => {
                const res = json.data.data;
                this.memberLevelList = res;
            });
        },
        onBatchChangeMemberLevel() {
            if (this.currentTableSelect.length === 0) {
                this.$message({
                    message: '请选择会员',
                    type: 'error',
                });
                return;
            }
            const changeMemberIdList = [];
            this.currentTableSelect.forEach(item => {
                changeMemberIdList.push(item.id);
            });
            this.setLevelDialog.formModel.ids = changeMemberIdList.join(',');
            this.setLevelDialog.isVisible = true;
        },
        onResetPwdClick(row) {
            this.$confirm('是否重置该用户密码！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Rs.User.resetPwd({
                    id: row.memberUserId,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                });
            });
        },
    },
    created() {
        this.initLevelList();
    },
};
</script>

<style lang="scss">
</style>
