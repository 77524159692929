<template>
    <page back>
        <el-row>
            <el-col :span="16">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        prop="jobTitle"
                        label="职位名称"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.jobTitle"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="regionId"
                        label="省市区"
                        label-width="9em"
                    >
                        <CascaderPicker
                            :api-class="regionApi"
                            v-model="formModel.regionId"
                            :p-id="2"
                            :min-lv="2"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="workPlace"
                        label="工作地点"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.workPlace"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="workingYears"
                        label="工作年限"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.workingYears"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="education"
                        label="学历"
                        label-width="9em"
                    >
                        <dictionaries-picker
                            type="education"
                            v-model="formModel.education"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="salary"
                        label="薪资"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.salary"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="companyName"
                        label="公司名称"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.companyName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="companyBenefits"
                        label="公司福利"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.companyBenefits"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="companyProfile"
                        label="公司简介"
                        label-width="9em"
                    >
                        <Tinymce
                            :height="500"
                            image-action="/rs/attachment/uploadFileLibrary"
                            v-model="formModel.companyProfile"
                            :toolbar="toolbar"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="companyAddress"
                        label="公司地址"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.companyAddress"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="tel"
                        label="联系电话"
                        label-width="9em"
                    >
                        <el-input
                            v-model="formModel.tel"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        label-width="13em"
                        class="ma-t"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            type="primary"
                            size="small"
                            @click="onSubmit"
                            v-if="!formModel.id||+formModel.jobRecruitStatus===10"
                        >
                            保存并上架
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'JobRecruitEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                jobTitle: '',
                workPlace: '',
                workingYears: '',
                education: '',
                salary: '',
                companyName: '',
                companyBenefits: '',
                companyProfile: '',
                companyAddress: '',
                tel: '',
                jobRecruitStatus: '',
                regionId: '',
            },
            examUserList: [],
            // 表单校验规则
            formRules: {
                jobTitle: {
                    required: true,
                    message: '请输入工作地点',
                    trigger: 'blur',
                },
                workPlace: {
                    required: true,
                    message: '请输入工作地点',
                    trigger: 'blur',
                },
                workingYears: {
                    required: true,
                    message: '请输入工作年限',
                    trigger: 'blur',
                },
                education: {
                    required: true,
                    message: '请选择学历',
                },
                salary: {
                    required: true,
                    message: '请输入薪资',
                    trigger: 'blur',
                },
                companyName: {
                    required: true,
                    message: '请输入公司名称',
                    trigger: 'blur',
                },
                companyBenefits: {
                    required: true,
                    message: '请输入公司福利',
                    trigger: 'blur',
                },
                companyAddress: {
                    required: true,
                    message: '请输入公司地址',
                    trigger: 'blur',
                },
                tel: {
                    required: true,
                    message: '请输入联系电话',
                    trigger: 'blur',
                },
                regionId: {
                    required: true,
                    message: '请选择省市区',
                },
            },
            regionApi: this.$api.Rs.Region,
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.JobRecruit.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        onSubmit() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.JobRecruit.save({
                    ...this.formModel,
                    isSubmit: true,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        init() {
            this.$api.Mb.JobRecruit.getDetail({ id: this.formModel.id, isLog: false }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
