<!-- Created by henian.xu on 2020/2/20. -->

<template>
    <div class="question-box">
        <div class="header">
            <div class="label">
                {{ index + 1 }}.<span>[{{ data.questionType === 1?'单选题':data.questionType === 2?'判断题':data.questionType === 3?'多选题':'案例分析' }}]</span>{{ data.title }}
            </div>
            <div class="extra">
                <i
                    class="el-icon-check tc-success"
                    v-if="data.questionAnswer === data.selectOption"
                />
                <i
                    class="el-icon-close tc-danger"
                    v-if="data.questionAnswer !== data.selectOption"
                />
            </div>
        </div>
        <div class="body">
            <div class="options">
                <template v-if="!isEdit">
                    <dl
                        v-for="item in data.optionList"
                        :key="item.id"
                    >
                        <dt :class="[(!!data.selectOption && (data.selectOption & item.option) !== 0)?'active':'']">
                            {{ item.text }}
                        </dt>
                        <dd v-if="data.questionOptionType === 1">
                            {{ item.content }}
                        </dd>
                        <dd v-if="data.questionOptionType === 2">
                            <ImageList
                                :data="item.image[0].address"
                            />
                        </dd>
                    </dl>
                </template>
                <el-checkbox-group
                    class="ma-t"
                    v-model="data.selectOptionIdList"
                    v-if="isEdit"
                >
                    <dl
                        v-for="item in data.optionList"
                        :key="item.id"
                    >
                        <template
                            v-if="data.questionType != 3"
                        >
                            <el-radio
                                v-model="data.selectOption"
                                :label="item.option"
                                class="ma-t"
                            >
                                {{ item.text }}
                                <span v-if="data.questionOptionType === 1">
                                    {{ item.content }}
                                </span>
                                <span v-if="data.questionOptionType === 2">
                                    <ImageList
                                        :data="item.image[0].address"
                                    />
                                </span>
                            </el-radio>
                        </template>
                        <template
                            v-if="data.questionType == 3"
                        >
                            <el-checkbox :label="item.option">
                                <span v-if="data.questionOptionType === 1">
                                    {{ item.content }}
                                </span>
                                <span v-if="data.questionOptionType === 2">
                                    <ImageList
                                        :data="item.image[0].address"
                                    />
                                </span>
                            </el-checkbox>
                        </template>
                    </dl>
                </el-checkbox-group>
            </div>
        </div>
        <div class="footer">
            <div class="info">
                <dl>
                    <dt>考生答案:</dt>
                    <dd>{{ data.selectOptionStr }}</dd>
                </dl>
                <dl>
                    <dt>参考答案:</dt>
                    <dd>{{ data.questionAnswerStr }}</dd>
                </dl>
                <dl>
                    <dt>分值:</dt>
                    <dd>{{ data.questionScore }}分</dd>
                </dl>
            </div>
            <div class="resolve">
                <div class="label">
                    答案解析:
                </div>
                <div class="content">
                    {{ data.answerAnalysis }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QuestionBox',
    data() {
        return {};
    },
    props: {
        data: {
            type: Object,
        },
        index: {
            type: Number,
        },
        isEdit: {
            type: Boolean,
        },
    },
};
</script>

<style lang="scss">
.question-box {
    border: 1px solid $color-border;
    margin: $margin 0;

    > .header {
        padding: $padding;
        background-color: mix($color-info, #fff, 10%);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        > .label {
            flex: 1;

            > span {
                color: $color-danger;
                margin: 0 $margin-small;
            }
        }

        > .extra {
            flex: 0 0 auto;

            > i {
                font-size: 120%;
            }
        }
    }

    > .body {
        padding: $padding;

        > .options {
            > dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                > dt {
                    flex: 0 0 auto;
                    width: 2em;
                    height: 2em;
                    line-height: 2em;
                    text-align: center;
                    border-radius: 100vw;
                    margin-right: $margin-small;
                    font-weight: bold;

                    &:after {
                        content: ':';
                    }

                    &.active {
                        background-color: $color-success;
                        color: #fff;

                        &:after {
                            content: '';
                        }
                    }
                }

                > dd {
                    flex: 1;
                    padding: $padding-small 0;
                }
            }
        }
    }

    > .footer {
        padding: $padding;
        border-top: 1px solid $color-border;

        > .info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: $margin;

            > dl {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                > dt {
                    flex: 0 0 auto;
                    border-radius: 100vw;
                    margin-right: $margin-small;
                }

                > dd {
                    flex: 1;
                    font-weight: bold;
                }
            }
        }
    }
}
</style>
