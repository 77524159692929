/**
 * Created by henian.xu on 2019/10/3.
 *
 */
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { MessageBox, Message, Loading } from 'element-ui';
import { isString, Runtime } from '@vmf/shared';
import { AxiosRequestConfigExtend, AxiosResponseData } from './interface';

const recordRequestMap: { [k: string]: CancelTokenSource } = {};
const recordLoadingMap: { [k: string]: boolean } = {};
let loadingInstance: ReturnType<typeof Loading.service> | null = null;

// 获取取消请求配置
export function getExtendConfig(config: AxiosRequestConfig): AxiosRequestConfigExtend | null {
    const { cancelToken } = config;
    if (!cancelToken || !cancelToken.extendConfig) return null;
    return cancelToken.extendConfig;
}

// 记录请求用于处理重复请求
function recordRequest(config: AxiosRequestConfig): void {
    const extendConfig = getExtendConfig(config);
    if (!extendConfig) return;
    const { isRepeat, isCancelBefore, cancelSource } = extendConfig;
    if (isRepeat) return; // 可重复的请求不记录
    const { url, baseURL } = config;
    const recordKey = `${baseURL}${url}`;
    const oldCancelSource = recordRequestMap[recordKey];
    if (!oldCancelSource) {
        // requestMap 无记录说明是新的请求
        recordRequestMap[recordKey] = cancelSource;
    } else if (isCancelBefore) {
        oldCancelSource.cancel(`因重复而取消之前的请求:${recordKey}`);
        recordRequestMap[recordKey] = cancelSource; // 更新取消令牌
    } else {
        cancelSource.cancel(`因重复而取消当前的请求:${recordKey}`);
    }
}

// 删除已记录的请求
function removeRequestRecord(response: AxiosResponse): void {
    const { config } = response;
    if (!config || !config.url) return;
    const { url, baseURL } = config;
    const recordKey = new RegExp(`^${baseURL}`).test(url) ? url : `${baseURL}${url}`;
    delete recordRequestMap[recordKey];
}

// 请求提示处理
function promptHandler(response: AxiosResponse<AxiosResponseData>): Promise<AxiosResponse<AxiosResponseData>> {
    const { config, data: reslut } = response;
    const extendConfig = getExtendConfig(config);
    if (!extendConfig) return Promise.resolve(response);
    const { isHandleError, isSuccessTip } = extendConfig;
    if (!reslut.success) {
        if (isHandleError) return Promise.resolve(response);
        if (!isHandleError) {
            // 统一处理失败请求提示
            MessageBox.alert(reslut.msg || '', '温馨提示', { type: 'error' });
        }
        return Promise.reject(response);
    }
    if (isSuccessTip) {
        // 统一处理成功请求提示
        Message.success({ message: reslut.msg || '', offset: 70 });
    }
    return Promise.resolve(response);
}

// loading处理
function recordRequestLoading(config: AxiosRequestConfig) {
    const extendConfig = getExtendConfig(config);
    if (!extendConfig) return;
    const { isLoading } = extendConfig;
    if (!isLoading) return;
    const { url, baseURL } = config;
    const recordKey = `${baseURL}${url}`;
    recordLoadingMap[recordKey] = true;
    loadingInstance = Loading.service({
        lock: true,
        text: isString(isLoading) ? isLoading : '请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: 'loading-show-ani',
    });
    // vm.$nprogress.start();
}
function removeRequestLoading(response: AxiosResponse) {
    const { config } = response;
    if (!config || !config.url) return;
    const { url, baseURL } = config;
    const recordKey = new RegExp(`^${baseURL}`).test(url) ? url : `${baseURL}${url}`;
    delete recordLoadingMap[recordKey];
    const { length } = Object.keys(recordLoadingMap);
    if (length || !loadingInstance) return;
    loadingInstance.close();
    loadingInstance = null;
    // vm.$nprogress.done();
}

const httpStatusHandler: {
    isStatusProcessing: { [n: number]: boolean };
    [n: number]: (response: AxiosResponse) => void;
} = {
    isStatusProcessing: {
        401: false,
    },
    401() {
        console.log(401);
        const router = Runtime.getRouter()!;
        const { currentRoute } = router;
        const query: { backUrl?: string } = {};
        if (currentRoute.name === null && currentRoute.path === '/') {
            const hash = (window.location.hash || '').replace(/^#/, '');
            const [matchedComponent] = router.getMatchedComponents(hash);
            if (matchedComponent.name !== 'ErrorPage') {
                query.backUrl = hash;
            }
        }
        if (this.isStatusProcessing[401] || currentRoute.name === 'login') return;
        this.isStatusProcessing[401] = true;
        router
            .replace({
                path: '/login',
                query,
            })
            .finally(() => {
                this.isStatusProcessing[401] = false;
            });
    },
    404(/* response */) {
        /* console.log('404'); */
    },
};

// http状态处理
function httpStatusCodeHandler(error: any): void {
    const { response } = error || {};
    if (!response || !+response.status) return;
    const { config, status } = response;
    const extendConfig = getExtendConfig(config);
    if (extendConfig && extendConfig.isHandleError) return;
    if (httpStatusHandler[status]) httpStatusHandler[status](response);
}

//* *-- 导出方法 --**//

/**
 * 请求成功拦截
 * @param config
 */
export function requestSuccess(config: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig> {
    recordRequestLoading(config);
    recordRequest(config);
    return config;
}

/**
 * 请求失败拦截
 * @param error
 */
export function requestFail(error: any): any {}

/**
 * 响应成功拦截 status === 200
 * @param response
 */
export function responseSuccess(
    response: AxiosResponse<AxiosResponseData>,
): AxiosResponse<AxiosResponseData> | Promise<AxiosResponse<AxiosResponseData>> {
    removeRequestRecord(response);
    removeRequestLoading(response);
    return promptHandler(response);
}

/**
 * 响应失败拦截 status !== 200
 * @param error
 */
export function responseFail(error: any): Promise<any> {
    removeRequestRecord(error);
    removeRequestLoading(error);
    httpStatusCodeHandler(error);
    return Promise.reject(error);
}
