/**
 * Created by henian.xu on 2019/11/24.
 *
 */
import Router from 'vue-router';

let _router: Router | null;
export function setRouter(router: Router) {
    _router = router;
}
export function getRouter() {
    return _router;
}

export default {
    setRouter,
    getRouter,
};
