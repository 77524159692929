<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-row>
            <el-col :span="12">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item
                        prop="orgId"
                        label="组织机构"
                        label-width="10em"
                    >
                        <el-select
                            v-model="formModel.orgId"
                            placeholder="请选择"
                        >
                            <el-option
                                v-for="item in orgList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id + ''"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        prop="servantName"
                        label="运营商名称"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.servantName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantSn"
                        label="运营商编码"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.servantSn"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantWebsite"
                        label="运营商网址"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.servantWebsite"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantLogo"
                        label="运营商LOGO"
                        label-width="10em"
                    >
                        <upload
                            validate-md5
                            action="/rs/attachment/uploadFileLibrary"
                            :limit="1"
                            v-model="formModel.servantLogo"
                            :oss-action="$globalVar.appConfig.OSS_ACTION"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantRegionId"
                        label="运营商地址"
                        label-width="10em"
                    >
                        <CascaderPicker
                            :api-class="servantSelectApi"
                            v-model="formModel.servantRegionId"
                            :p-id="2"
                            :min-lv="2"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantAddress"
                        label="运营商详细地址"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.servantAddress"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantHouseNumber"
                        label="运营商门牌(单元)号"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.servantHouseNumber"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantPostcode"
                        label="邮政编码"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.servantPostcode"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="servantEmail"
                        label="电子邮箱"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.servantEmail"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalName"
                        label="联系人姓名"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.principalName"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="principalTel"
                        label="联系人电话"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.principalTel"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="memberRegisterMode"
                        label="会员注册模式"
                        label-width="10em"
                    >
                        <checkbox-group-andOr
                            type="memberRegisterMode"
                            v-model="formModel.memberRegisterMode"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="memberAccountSign"
                        label="会员账号标识"
                        label-width="10em"
                    >
                        <el-input
                            v-model="formModel.memberAccountSign"
                            auto-complete="off"
                        />
                        (4个字母以内)
                    </el-form-item>
                    <el-form-item
                        prop="paymentMode"
                        label="订单付款模式"
                        label-width="10em"
                    >
                        <checkbox-group-andOr
                            type="paymentMode"
                            v-model="formModel.paymentMode"
                        />
                    </el-form-item>
                    <el-form-item
                        key="onlinePaymentType"
                        prop="onlinePaymentType"
                        label="订单付款类型"
                        label-width="10em"
                        v-if="onlinePayment"
                    >
                        <checkbox-group-andOr
                            :data="[
                                /*{id:2,name:'微信支付'},
                                {id:32,name:'支付宝支付'},*/
                                {id:1024,name:'零钱支付'},
                                {id:4096,name:'小程序支付'},
                            ]"
                            v-model="formModel.onlinePaymentType"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="payEffectiveTime"
                        label="付款有效时间"
                        label-width="10em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="0"
                            v-model.number="formModel.payEffectiveTime"
                        />
                        分钟
                    </el-form-item>
                    <el-form-item
                        prop="remarks"
                        label="备注"
                        label-width="10em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.remarks"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="examNotes"
                        label="考试须知"
                        label-width="10em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.examNotes"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="testMemberIds"
                        label="测试账号ID"
                        label-width="10em"
                    >
                        <el-input
                            type="textarea"
                            v-model="formModel.testMemberIds"
                            auto-complete="off"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="courseHourMinute"
                        label="学时分钟设置"
                        label-width="10em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="1"
                            :precision="0"
                            v-model.number="formModel.courseHourMinute"
                        />
                        1学时=？分钟
                    </el-form-item>
                    <el-form-item
                        prop="learnBaseNum"
                        label="学习基础人数"
                        label-width="10em"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="0"
                            v-model.number="formModel.learnBaseNum"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="isJobRecruitAudit"
                        label="招聘是否审核"
                        label-width="10em"
                    >
                        <template>
                            <el-radio
                                v-model="formModel.isJobRecruitAudit"
                                :label="true"
                            >
                                是
                            </el-radio>
                            <el-radio
                                v-model="formModel.isJobRecruitAudit"
                                :label="false"
                            >
                                否
                            </el-radio>
                        </template>
                    </el-form-item>
                    <el-form-item
                        prop="isJobWantedAudit"
                        label="求职是否审核"
                        label-width="10em"
                    >
                        <template>
                            <el-radio
                                v-model="formModel.isJobWantedAudit"
                                :label="true"
                            >
                                是
                            </el-radio>
                            <el-radio
                                v-model="formModel.isJobWantedAudit"
                                :label="false"
                            >
                                否
                            </el-radio>
                        </template>
                    </el-form-item>
                    <el-form-item
                        prop="servantStatus"
                        label="运营商状态"
                        label-width="10em"
                    >
                        <dictionaries-picker
                            type="servantStatus"
                            v-model="formModel.servantStatus"
                        />
                    </el-form-item>
                    <el-form-item
                        label-width="10em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ServantEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                orgId: '', // 组织机构ID
                servantName: '', // 运营商名称
                servantSn: '', // 运营商编码
                servantWebsite: '', // 运营商网址
                servantLogo: [], // 运营商LOGO
                servantRegionId: '', // 运营商地址
                servantAddress: '', // 运营商详细地址
                servantHouseNumber: '', // 运营商门牌(单元)号
                servantPostcode: '', // 邮政编码
                servantEmail: '', // 电子邮箱
                principalName: '', // 联系人姓名
                principalTel: '', // 联系人电话
                memberRegisterMode: 1,
                remarks: '', // 备注
                servantStatus: '', // 运营商状态
                memberAccountSign: '',
                paymentMode: '',
                onlinePaymentType: '',
                appid: '',
                secret: '',
                token: '',
                aeskey: '',
                payEffectiveTime: undefined,
                examNotes: undefined,
                testMemberIds: undefined,
                courseHourMinute: '1',
                learnBaseNum: '0',
                isJobRecruitAudit: true,
                isJobWantedAudit: true,
            },
            // 表单校验规则
            formRules: {
                orgId: {
                    required: true,
                    message: '请选择组织机构',
                },
                servantName: {
                    required: true,
                    message: '请输入运营商名称',
                    trigger: 'blur',
                },
                servantSn: {
                    required: true,
                    message: '请输入运营商编码',
                    trigger: 'blur',
                },
                servantWebsite: {
                    required: true,
                    message: '请输入运营商网址',
                    trigger: 'blur',
                },
                servantLogo: {
                    type: 'array',
                    required: true,
                    message: '请上传运营商LOGO',
                },
                servantRegionId: {
                    required: true,
                    message: '请选择运营商地址',
                },
                servantAddress: {
                    required: true,
                    message: '请输入运营商详细地址',
                    trigger: 'blur',
                },
                payEffectiveTime: {
                    required: true,
                    message: '请输入付款有效时间',
                    trigger: 'blur',
                },
                principalName: {
                    required: true,
                    message: '请输入联系人姓名',
                    trigger: 'blur',
                },
                principalTel: {
                    required: true,
                    message: '请输入联系人电话',
                    trigger: 'blur',
                },
                servantStatus: {
                    required: true,
                    message: '请选择运营商状态',
                },
                memberRegisterMode: [
                    {
                        required: true,
                        message: '请选择会员注册模式',
                    },
                    {
                        type: 'integer',
                        min: 1,
                        message: '请选择会员注册模式',
                    },
                ],
                paymentMode: [
                    {
                        required: true,
                        message: '请选择付款模式',
                    },
                    {
                        type: 'integer',
                        min: 1,
                        message: '请选择付款模式',
                    },
                ],
                onlinePaymentType: [
                    {
                        required: true,
                        message: '请选择线上付款模式',
                    },
                    {
                        type: 'integer',
                        min: 1,
                        message: '请选择线上付款模式',
                    },
                ],
                memberAccountSign: {
                    required: true,
                    message: '请输入会员账号标识',
                },
            },
            servantSelectApi: this.$api.Rs.Region,
            bankSelectApi: this.$api.Rs.Region,
            // 组织机构列表
            orgList: [],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Sv.Servant.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        initServant() {
            this.$api.Sv.Servant.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                res.orgId = res.orgId + '';
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
        initOrgList() {
            this.$api.Rs.Org.select().then(json => {
                const res = json.data.data;
                this.orgList = res;
            });
        },
    },
    computed: {
        onlinePayment() {
            return (this.formModel.paymentMode & 1) !== 0;
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        this.initOrgList();
        if (this.formModel.id) {
            this.initServant();
        }
    },
};
</script>

<style lang="scss">
</style>
