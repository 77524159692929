// import '@babel/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Utils, Vue } from 'vmf';
import './router';
import './store';
import Components from '@vmf/components';
import api from './api/install';

const { GlobalVar } = Utils;
Vue.prototype.$globalVar = GlobalVar;

Vue.use(Components);
Vue.use(api);
