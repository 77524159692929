<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="name"
                    label="部门名称"
                >
                    <el-input
                        v-model="queryFormModel.name"
                        placeholder="请输入部门名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="code"
                    label="部门代码"
                >
                    <el-input
                        v-model="queryFormModel.code"
                        placeholder="请输入部门代码"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="状态"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="启用"
                            value="1"
                        />
                        <el-option
                            label="禁用"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selectionx-change="onTableSelectionChange"
            >
                <el-table-column
                    prop="name"
                    label="部门名称"
                    min-width="100"
                />
                <el-table-column
                    prop="code"
                    label="部门代码"
                    min-width="100"
                />
                <el-table-column
                    prop="orderNum"
                    label="排序"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/pfom/set/smsTemplate/' + scope.row.id)"
                        >
                            短信模板
                        </el-button>
                        <!--<el-button
                            type="text"
                            size="small"
                            @click="$router.push('/pfom/set/orgCountryCode/' + scope.row.id)"
                        >
                            短信区域
                        </el-button>-->
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'SmsOrg',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                name: '', // 部门名称
                code: '', // 部门代码
                isAvailable: '', // 状态
            },
            // 表格数据
            tableData: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Rs.Org.allOrgList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
    },
};
</script>

<style lang="scss">
</style>
