import { Ex } from '@/api/ex/Ex';

class RegisterForm extends Ex {
    constructor() {
        super();
        this.baseUrl += '/registerForm';
    }

    data({
        formBatchId,
        fullName,
        sex,
        tel,
        idCardNo,
        isCertificate,
        isMakeUp,
        companyName,
        workRegionName,
        workStatus,
        classDate,
        isWarning,
        currentPage,
        pageSize,
    }) {
        let classDateBegin = null;
        let classDateEnd = null;
        if (classDate != null && classDate.length === 2) {
            classDateBegin = classDate[0];
            classDateEnd = classDate[1];
        }
        return super.get('/data', {
            formBatchId,
            fullName,
            sex,
            tel,
            idCardNo,
            isCertificate,
            isMakeUp,
            companyName,
            workRegionName,
            workStatus,
            classDateBegin,
            classDateEnd,
            isWarning,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const data = json.data.data;
            const { fileBasePath } = data;
            const { avatar } = data;
            if (!avatar) {
                data.avatar = [];
            } else {
                data.avatar = avatar.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const { idCardFront } = data;
            if (!idCardFront) {
                data.idCardFront = [];
            } else {
                data.idCardFront = idCardFront.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const { idCardBack } = data;
            if (!idCardBack) {
                data.idCardBack = [];
            } else {
                data.idCardBack = idCardBack.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const { commitmentImage } = data;
            if (!commitmentImage) {
                data.commitmentImage = [];
            } else {
                data.commitmentImage = commitmentImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const { employmentImage } = data;
            if (!employmentImage) {
                data.employmentImage = [];
            } else {
                data.employmentImage = employmentImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const { xuexinImage } = data;
            if (!xuexinImage) {
                data.xuexinImage = [];
            } else {
                data.xuexinImage = xuexinImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            const { graduationImage } = data;
            if (!graduationImage) {
                data.graduationImage = [];
            } else {
                data.graduationImage = graduationImage.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            return json;
        });
    }

    save({
        id,
        registerFormBatchId,
        classDate,
        fullName,
        registerMaterials,
        appointmentNumber,
        applicationDirection,
        sex,
        education,
        graduatedSchool,
        tel,
        idCardNo,
        idCardAddress,
        address,
        companyName,
        amount,
        billingMethod,
        paymentMethod,
        billingName,
        recommender,
        isCertificate,
        changeShiftName,
        workRegionName,
        workStatus,
        certLevel,
        theoryScore,
        practicalScore,
        isMakeUp,
        certSn,
        certDate,
        avatar,
        idCardFront,
        idCardBack,
        commitmentImage,
        employmentImage,
        xuexinImage,
        graduationImage,
    }) {
        let avatarStr = '';
        if (avatar) {
            avatarStr = avatar
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        let idCardFrontStr = '';
        if (idCardFront) {
            idCardFrontStr = idCardFront
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        let idCardBackStr = '';
        if (idCardBack) {
            idCardBackStr = idCardBack
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        let commitmentImageStr = '';
        if (commitmentImage) {
            commitmentImageStr = commitmentImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        let employmentImageStr = '';
        if (employmentImage) {
            employmentImageStr = employmentImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        let xuexinImageStr = '';
        if (xuexinImage) {
            xuexinImageStr = xuexinImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        let graduationImageStr = '';
        if (graduationImage) {
            graduationImageStr = graduationImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        return super.post('/save', {
            id,
            registerFormBatchId,
            classDate,
            fullName,
            registerMaterials,
            appointmentNumber,
            applicationDirection,
            sex,
            education,
            graduatedSchool,
            tel,
            idCardNo,
            idCardAddress,
            address,
            companyName,
            amount,
            billingMethod,
            paymentMethod,
            billingName,
            recommender,
            isCertificate,
            changeShiftName,
            workRegionName,
            workStatus,
            certLevel,
            theoryScore,
            practicalScore,
            isMakeUp,
            certSn,
            certDate,
            avatar: avatarStr,
            idCardFront: idCardFrontStr,
            idCardBack: idCardBackStr,
            commitmentImage: commitmentImageStr,
            employmentImage: employmentImageStr,
            xuexinImage: xuexinImageStr,
            graduationImage: graduationImageStr,
        });
    }

    delete({ ids, formBatchId }) {
        return super.post('/delete', {
            ids,
            formBatchId,
        });
    }

    existIdCardNo({ formBatchId, id, idCardNo }) {
        return super.get('/existIdCardNo', {
            formBatchId,
            id,
            idCardNo,
        });
    }
}
export default new RegisterForm();
export { RegisterForm };
