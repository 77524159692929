/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Sv } from './Sv';
import { GlobalVar } from '@vmf/shared';

class Servant extends Sv {
    constructor() {
        super();
        this.baseUrl += '/servant';
    }

    data({ servantName, servantStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            servantName,
            servantStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id }) {
        return super.get('/get', { id }).then(json => {
            const servantLogo = json.data.data.servantLogo;
            if (!servantLogo) {
                json.data.data.servantLogo = [];
            } else {
                json.data.data.servantLogo = servantLogo.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (json.data.data.interfaceConfig) {
                json.data.data.payEffectiveTime = json.data.data.interfaceConfig.payEffectiveTime;
                json.data.data.examNotes = json.data.data.interfaceConfig.examNotes;
                json.data.data.testMemberIds = json.data.data.interfaceConfig.testMemberIds;
                json.data.data.courseHourMinute = json.data.data.interfaceConfig.courseHourMinute;
                json.data.data.learnBaseNum = json.data.data.interfaceConfig.learnBaseNum;
                json.data.data.isJobRecruitAudit = json.data.data.interfaceConfig.isJobRecruitAudit;
                json.data.data.isJobWantedAudit = json.data.data.interfaceConfig.isJobWantedAudit;
            }
            return json;
        });
    }

    save({
        id,
        orgId,
        servantName,
        servantSn,
        servantWebsite,
        servantLogo,
        servantRegionId,
        servantAddress,
        servantHouseNumber,
        servantPostcode,
        servantEmail,
        principalName,
        principalTel,
        memberRegisterMode,
        paymentMode,
        onlinePaymentType,
        remarks,
        servantStatus,
        memberAccountSign,
        appid,
        secret,
        token,
        aeskey,
        payEffectiveTime,
        examNotes,
        testMemberIds,
        courseHourMinute,
        learnBaseNum,
        isJobRecruitAudit,
        isJobWantedAudit,
    } = {}) {
        const servantLogoStr = servantLogo
            .reduce((prev, curr) => {
                prev.push(curr.name);
                return prev;
            }, [])
            .join(',');
        const interfaceConfig = {
            payEffectiveTime: payEffectiveTime,
            examNotes: examNotes,
            testMemberIds: testMemberIds,
            courseHourMinute: courseHourMinute,
            learnBaseNum: learnBaseNum,
            isJobRecruitAudit: isJobRecruitAudit,
            isJobWantedAudit: isJobWantedAudit,
        };
        return super.post('/save', {
            id,
            orgId,
            servantName,
            servantSn,
            servantWebsite,
            servantLogo: servantLogoStr,
            servantRegionId,
            servantAddress,
            servantHouseNumber,
            servantPostcode,
            servantEmail,
            principalName,
            principalTel,
            memberRegisterMode,
            paymentMode,
            onlinePaymentType,
            remarks,
            servantStatus,
            memberAccountSign,
            appid,
            secret,
            token,
            aeskey,
            interfaceConfig: JSON.stringify(interfaceConfig),
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }

    getServantInfo() {
        return super.get('/getServantInfo').then(json => {
            const servantLogo = json.data.data.servantLogo;
            if (!servantLogo) {
                json.data.data.servantLogo = [];
            } else {
                json.data.data.servantLogo = servantLogo.split(',').reduce((prev, curr) => {
                    prev.push({
                        name: curr,
                        address: GlobalVar.fileBasePath + curr,
                    });
                    return prev;
                }, []);
            }
            if (json.data.data.interfaceConfig) {
                json.data.data.payEffectiveTime = json.data.data.interfaceConfig.payEffectiveTime;
                json.data.data.examNotes = json.data.data.interfaceConfig.examNotes;
                json.data.data.testMemberIds = json.data.data.interfaceConfig.testMemberIds;
                json.data.data.courseHourMinute = json.data.data.interfaceConfig.courseHourMinute;
                json.data.data.learnBaseNum = json.data.data.interfaceConfig.learnBaseNum;
                json.data.data.isJobRecruitAudit = json.data.data.interfaceConfig.isJobRecruitAudit;
                json.data.data.isJobWantedAudit = json.data.data.interfaceConfig.isJobWantedAudit;
            }
            return json;
        });
    }
}

export default new Servant();
export { Servant };
