<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="fullName"
                    label="姓名"
                >
                    <el-input
                        v-model="queryFormModel.fullName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="电话"
                >
                    <el-input
                        v-model="queryFormModel.mobile"
                        placeholder="请输入电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="idCard"
                    label="身份证"
                >
                    <el-input
                        v-model="queryFormModel.idCard"
                        placeholder="请输入身份证"
                    />
                </el-form-item>
                <el-form-item
                    prop="examOrgId"
                    label="组织机构"
                >
                    <CascaderPicker
                        check-strictly
                        :api-class="selectApi"
                        v-model="queryFormModel.examOrgId"
                        :query-params="cascaderPickerQueryParams"
                    />
                </el-form-item>
                <el-form-item
                    prop="orgMemberCatId"
                    label="人员分类"
                >
                    <CascaderPicker
                        check-strictly
                        :api-class="selectApi1"
                        v-model="queryFormModel.orgMemberCatId"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        新增
                    </el-button>
                    <el-button
                        class="ma-l"
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        导出
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="$http.href('/static/excel/人员名单模板.xlsx')"
                    >
                        下载模板
                    </el-button>
                    <el-upload
                        class="upload-demo dp-ib"
                        action="/ex/orgMember/uploadOrgMemberByExcel"
                        :show-file-list="false"
                        accept=".xls,.xlsx"
                        :on-success="onOrderImportSuccess"
                        :on-error="onOrderImportError"
                        :data="{type: 1}"
                    >
                        <el-button
                            type="success"
                            icon="el-icon-upload2"
                            size="small"
                        >
                            覆盖导入名单
                        </el-button>
                    </el-upload>
                    <el-upload
                        class="upload-demo dp-ib"
                        action="/ex/orgMember/uploadOrgMemberByExcel"
                        :show-file-list="false"
                        accept=".xls,.xlsx"
                        :on-success="onOrderImportSuccess"
                        :on-error="onOrderImportError"
                        :data="{type: 2}"
                    >
                        <el-button
                            type="success"
                            icon="el-icon-upload2"
                            size="small"
                        >
                            新增导入名单
                        </el-button>
                    </el-upload>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="mobile"
                    label="手机"
                    width="100"
                />
                <el-table-column
                    prop="fullName"
                    label="姓名"
                    width="70"
                />
                <el-table-column
                    prop="idCard"
                    label="身份证"
                    width="150"
                />
                <el-table-column
                    prop="examOrgNamePath"
                    label="组织机构"
                    min-width="100"
                />
                <el-table-column
                    prop="orgMemberNamePath"
                    label="人员分类"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onResetPwdClick(scope.row)"
                        >
                            重置密码
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="600px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="mobile"
                    label="手机号"
                    label-width="6em"
                >
                    <el-input
                        v-model="addDialog.formModel.mobile"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="fullName"
                    label="姓名"
                    label-width="6em"
                >
                    <el-input
                        v-model="addDialog.formModel.fullName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="idCard"
                    label="身份证"
                    label-width="6em"
                >
                    <el-input
                        v-model="addDialog.formModel.idCard"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="examOrgId"
                    label="组织机构"
                    label-width="6em"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="addDialog.formModel.examOrgId"
                        :query-params="cascaderPickerQueryParams"
                        v-if="addDialog.isVisible"
                    />
                </el-form-item>
                <el-form-item
                    prop="orgMemberCatId"
                    label="标签"
                    label-width="6em"
                >
                    <CascaderPicker
                        :api-class="selectApi1"
                        v-model="addDialog.formModel.orgMemberCatId"
                        v-if="addDialog.isVisible"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'OrgMember',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                fullName: '',
                mobile: '',
                idCard: '',
                examOrgId: '',
                orgMemberCatId: '',
            },
            // 表格数据
            tableData: [],
            selectApi: this.$api.Rs.Org,
            selectApi1: this.$api.Ex.OrgMemberCat,
            cascaderPickerQueryParams: {
                isHideTop: 1,
                isFilterAuth: 1,
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    mobile: '',
                    fullName: '',
                    idCard: '',
                    examOrgId: '',
                    orgMemberCatId: '',
                },
                // 表单校验规则
                formRules: {
                    mobile: {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur',
                    },
                    fullName: {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur',
                    },
                    idCard: {
                        required: true,
                        message: '请输入身份证',
                        trigger: 'blur',
                    },
                    examOrgId: {
                        required: true,
                        message: '请选择组织机构',
                    },
                    orgMemberCatId: {
                        required: true,
                        message: '请选择标签',
                    },
                },
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.OrgMember.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onOrderImportSuccess(response) {
            if (response.success) {
                this.$message({
                    message: response.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
            } else {
                this.$alert(response.msg, '错误提示', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                });
            }
        },
        onOrderImportError(err) {
            this.$message({
                message: err,
                type: 'error',
            });
        },
        exportExcel() {
            this.$http.href('/ex/orgMember/exportExcel', {
                ...this.queryFormModel,
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Ex.OrgMember.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
            });
            this.addDialog.isVisible = true;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.OrgMember.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Ex.OrgMember.save({
                    ...this.addDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        onResetPwdClick(row) {
            this.$confirm('是否重置该用户密码！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Rs.User.resetPwd({
                    id: row.memberUserId,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
