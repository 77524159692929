/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Ex } from './Ex';

class ExamApplyPaper extends Ex {
    constructor() {
        super();
        this.baseUrl += '/examApplyPaper';
    }

    examApplyPaperList({ examApplyId } = {}) {
        return super.get('/examApplyPaperList', {
            examApplyId,
        });
    }

    getExamApplyPaperDetail({ examApplyPaperId } = {}) {
        return super.get('/getExamApplyPaperDetail', {
            examApplyPaperId,
        });
    }

    editExamApplyPaper({ examApplyPaperId, questionJson } = {}) {
        return super.post('/editExamApplyPaper', {
            examApplyPaperId,
            questionJson,
        });
    }
}

export default new ExamApplyPaper();
export { ExamApplyPaper };
