import { Mb } from '@/api/mb/Mb';

class JobRecruit extends Mb {
    constructor() {
        super();
        this.baseUrl += '/jobRecruit';
    }

    data({
        isAdministrator,
        memberName,
        jobTitle,
        workPlace,
        workingYears,
        education,
        salary,
        companyName,
        jobRecruitStatus,
        regionId,
        currentPage,
        pageSize,
    } = {}) {
        return super.get('/data', {
            isAdministrator,
            memberName,
            jobTitle,
            workPlace,
            workingYears,
            education,
            salary,
            companyName,
            jobRecruitStatus,
            regionId,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id, isLog }) {
        return super.get('/get', {
            id,
            isLog,
        });
    }

    save({
        id,
        jobTitle,
        workPlace,
        workingYears,
        education,
        salary,
        companyName,
        companyBenefits,
        companyProfile,
        companyAddress,
        tel,
        regionId,
        isSubmit,
    }) {
        return super.post('/save', {
            id,
            jobTitle,
            workPlace,
            workingYears,
            education,
            salary,
            companyName,
            companyBenefits,
            companyProfile,
            companyAddress,
            tel,
            regionId,
            isSubmit,
        });
    }

    auditJobRecruit({ id, auditStatus, auditRemarks }) {
        return super.post('/auditJobRecruit', {
            id,
            auditStatus,
            auditRemarks,
        });
    }

    onShelf({ id }) {
        return super.post('/onShelf', { id });
    }

    offShelf({ id }) {
        return super.post('/offShelf', { id });
    }

    delete({ id }) {
        return super.post('/delete', { id });
    }
}
export default new JobRecruit();
export { JobRecruit };
