<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <el-form
            ref="addForm"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-form-item
                prop="messageCase"
                label="消息场景"
                label-width="10em"
            >
                <dictionaries-picker
                    type="messageCase"
                    v-model="formModel.messageCase"
                />
            </el-form-item>
            <el-form-item
                prop="messageTitle"
                label="消息标题"
                label-width="10em"
            >
                <el-input
                    v-model="formModel.messageTitle"
                    auto-complete="off"
                />
            </el-form-item>
            <el-form-item
                prop="messageContent"
                label="消息内容"
                label-width="10em"
            >
                <el-input
                    type="textarea"
                    v-model="formModel.messageContent"
                    auto-complete="off"
                />
            </el-form-item>
            <el-form-item
                prop="remarks"
                label="备注"
                label-width="10em"
            >
                <el-input
                    type="textarea"
                    v-model="formModel.remarks"
                    auto-complete="off"
                />
            </el-form-item>
            <el-form-item
                prop="userMessageSendType"
                label="消息发送对象"
                label-width="10em"
            >
                <dictionaries-picker
                    type="userMessageSendType"
                    v-model="formModel.userMessageSendType"
                />
            </el-form-item>
            <el-form-item
                label="指定会员"
                label-width="10em"
                v-if="formModel.userMessageSendType == 2"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddSelect"
                >
                    添加用户
                </el-button>
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectList"
                    style="width: 100%"
                    class="ma-t"
                >
                    <el-table-column
                        prop="memberLevelName"
                        label="会员等级"
                        min-width="100"
                    />
                    <el-table-column
                        prop="nickName"
                        label="昵称"
                        min-width="100"
                    />
                    <el-table-column
                        label="头像"
                        width="120"
                    >
                        <template slot-scope="scope">
                            <ImageList
                                :data="scope.row.userAvatarUrl || scope.row.avatar || ''"
                                :thumbnail-width="0"
                                :preview-width="0"
                                background-size="auto"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="mobile"
                        label="电话"
                        min-width="100"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.mobile }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="注册时间"
                        min-width="150"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="memberStatusName"
                        label="用户状态"
                        min-width="100"
                    />
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="45"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="onDeleteSelect(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>

            <el-form-item
                label-width="10em"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onConfirm"
                >
                    保 存
                </el-button>
                <el-button
                    size="small"
                    @click="$router.back()"
                >
                    返 回
                </el-button>
            </el-form-item>
        </el-form>

        <el-dialog
            title="选择用户"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="nickName"
                    label="昵称"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.nickName"
                        placeholder="请输入昵称"
                    />
                </el-form-item>
                <el-form-item
                    prop="mobile"
                    label="电话"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.mobile"
                        placeholder="请输入电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="memberStatus"
                    label="用户状态"
                >
                    <dictionaries-picker
                        type="memberStatus"
                        v-model="selectDialog.queryFormModel.memberStatus"
                    />
                </el-form-item>
                <el-form-item
                    prop="memberLevelId"
                    label="会员等级"
                >
                    <el-select
                        v-model="selectDialog.queryFormModel.memberLevelId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in memberLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="insertTime"
                    label="注册时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="selectDialog.queryFormModel.insertTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="memberLevelName"
                        label="会员等级"
                        min-width="100"
                    />
                    <el-table-column
                        prop="nickName"
                        label="昵称"
                        min-width="100"
                    />
                    <el-table-column
                        label="头像"
                        width="120"
                    >
                        <template slot-scope="scope">
                            <ImageList
                                :data="scope.row.userAvatarUrl || scope.row.avatar || ''"
                                :thumbnail-width="0"
                                :preview-width="0"
                                background-size="auto"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="mobile"
                        label="电话"
                        min-width="100"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.mobile }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="注册时间"
                        min-width="150"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="memberStatusName"
                        label="用户状态"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'UserMessageSend',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                messageCase: '',
                messageTitle: '',
                messageContent: '',
                userMessageSendType: '',
            },
            // 表单校验规则
            formRules: {
                messageCase: {
                    required: true,
                    message: '请选择消息场景',
                },
                userMessageSendType: {
                    required: true,
                    message: '请选择消息发送对象',
                },
                messageTitle: {
                    required: true,
                    message: '请输入消息标题',
                    trigger: 'blur',
                },
                messageContent: {
                    required: true,
                    message: '请输入消息内容',
                    trigger: 'blur',
                },
            },
            selectList: [],
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    nickName: '', // 昵称
                    mobile: '', // 电话
                    memberStatus: '', // 会员状态
                    memberLevelId: '', //会员等级
                    insertTime: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            memberLevelList: [],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                const idList = [];
                this.selectList.forEach(item => {
                    idList.push(item.id);
                });
                this.$api.Ms.UserMessageSend.save({
                    ...this.formModel,
                    userIds: idList.join(','),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.$router.back();
                });
            });
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
                // 初始化已选择数据
                if (this.$refs.selectDialogTable) {
                    this.$refs.selectDialogTable.clearSelection();
                }
                this.selectList.forEach(item => {
                    this.$refs.selectDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Mb.Member.data({
                ...this.selectDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            // 插入新数据
            const list = [];
            this.selectDialog.currentTableSelect.forEach(item => {
                let isHave = false;
                this.selectList.forEach(oldItem => {
                    if (item.id === oldItem.id) {
                        list.push({ ...oldItem });
                        isHave = true;
                    }
                });
                if (!isHave) {
                    //添加新数据
                    list.push({ ...item });
                }
            });
            this.selectList = [...list];
            this.selectDialog.isVisible = false;
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteSelect(row) {
            this.selectList.splice(this.selectList.indexOf(row), 1);
        },
        initLevelList() {
            this.$api.Mb.MemberLevel.select().then(json => {
                const res = json.data.data;
                this.memberLevelList = res;
            });
        },
    },
    created() {
        this.initLevelList();
    },
};
</script>

<style lang="scss">
</style>
