<template>
    <page back>
        <template slot="body">
            <el-row>
                <el-col :span="24">
                    <el-form
                        size="small"
                    >
                        <el-card
                            size="small"
                            shadow="never"
                        >
                            <div
                                slot="header"
                                class="clearfix"
                            >
                                <span />
                            </div>
                            <div class="item">
                                <el-col :span="12">
                                    <el-form-item
                                        label="批次名称:"
                                        label-width="9em"
                                    >
                                        {{ detail.batchName }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="批次编码:"
                                        label-width="9em"
                                    >
                                        {{ detail.batchSn }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="开班日期:"
                                        label-width="9em"
                                    >
                                        {{ detail.classDate | moment('YYYY-MM-DD HH:mm:ss') }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="报名材料:"
                                        label-width="9em"
                                    >
                                        {{ detail.registerMaterials }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="报考方向:"
                                        label-width="9em"
                                    >
                                        {{ detail.applicationDirection }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="预约考号:"
                                        label-width="9em"
                                    >
                                        {{ detail.appointmentNumber }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="推荐人:"
                                        label-width="9em"
                                    >
                                        {{ detail.recommender }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="换人班次名字:"
                                        label-width="9em"
                                    >
                                        {{ detail.changeShiftName }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="证书等级:"
                                        label-width="9em"
                                    >
                                        {{ detail.certLevel }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="创建时间:"
                                        label-width="9em"
                                    >
                                        {{ detail.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="更新时间:"
                                        label-width="9em"
                                    >
                                        {{ detail.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                    </el-form-item>
                                </el-col>
                            </div>
                        </el-card>
                        <el-card
                            class="ma-t"
                            size="small"
                            shadow="never"
                        >
                            <div
                                slot="header"
                                class="clearfix"
                            >
                                <span />
                            </div>
                            <div class="item">
                                <el-col :span="12">
                                    <el-form-item
                                        label="姓名:"
                                        label-width="9em"
                                    >
                                        {{ detail.fullName }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="性别:"
                                        label-width="9em"
                                    >
                                        {{ detail.sexName }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="学历:"
                                        label-width="9em"
                                    >
                                        {{ detail.education }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="毕业学校:"
                                        label-width="9em"
                                    >
                                        {{ detail.graduatedSchool }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="电话号码:"
                                        label-width="9em"
                                    >
                                        {{ detail.tel }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="身份证号码:"
                                        label-width="9em"
                                    >
                                        {{ detail.idCardNo }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="身份证地址:"
                                        label-width="9em"
                                    >
                                        {{ detail.idCardAddress }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item
                                        label="常用地址:"
                                        label-width="9em"
                                    >
                                        {{ detail.address }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="单位名称:"
                                        label-width="9em"
                                    >
                                        {{ detail.companyName }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="现就业地市:"
                                        label-width="9em"
                                    >
                                        {{ detail.workRegionName }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="就业状态:"
                                        label-width="9em"
                                    >
                                        {{ detail.workStatus }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="理论考试成绩:"
                                        label-width="9em"
                                    >
                                        {{ detail.theoryScore }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="实操考试成绩:"
                                        label-width="9em"
                                    >
                                        {{ detail.practicalScore }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="是否持证:"
                                        label-width="9em"
                                    >
                                        {{ detail.isCertificateName }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="证书编号:"
                                        label-width="9em"
                                    >
                                        {{ detail.certSn }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="证书日期:"
                                        label-width="9em"
                                    >
                                        {{ detail.certDate | moment('YYYY-MM-DD HH:mm:ss') }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="是否补考:"
                                        label-width="9em"
                                    >
                                        {{ detail.isMakeUpName }}
                                    </el-form-item>
                                </el-col>
                            </div>
                        </el-card>
                        <el-card
                            class="ma-t"
                            size="small"
                            shadow="never"
                        >
                            <div
                                slot="header"
                                class="clearfix ma-l"
                            >
                                <span style="font-size: large">
                                    附件
                                </span>
                            </div>
                            <div class="item">
                                <el-col :span="12">
                                    <el-form-item
                                        label="头像:"
                                        label-width="9em"
                                    >
                                        <ImageList
                                            :data="detail.avatarUrl"
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="身份证正面:"
                                        label-width="9em"
                                    >
                                        <ImageList
                                            :data="detail.idCardFrontUrl"
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="身份证反面:"
                                        label-width="9em"
                                    >
                                        <ImageList
                                            :data="detail.idCardBackUrl"
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="承诺书:"
                                        label-width="9em"
                                    >
                                        <ImageList
                                            :data="detail.commitmentImageUrl"
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="在职或待业证明:"
                                        label-width="9em"
                                    >
                                        <ImageList
                                            :data="detail.employmentImageUrl"
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="学信网图片:"
                                        label-width="9em"
                                    >
                                        <ImageList
                                            :data="detail.xuexinImageUrl"
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="结业证:"
                                        label-width="9em"
                                    >
                                        <ImageList
                                            :data="detail.graduationImageUrl"
                                        />
                                    </el-form-item>
                                </el-col>
                            </div>
                        </el-card>
                        <el-card
                            class="ma-t"
                            size="small"
                            shadow="never"
                        >
                            <div
                                slot="header"
                                class="clearfix"
                            >
                                <span />
                            </div>
                            <div class="item">
                                <el-col :span="12">
                                    <el-form-item
                                        label="金额:"
                                        label-width="9em"
                                    >
                                        {{ detail.amount }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="开票方式:"
                                        label-width="9em"
                                    >
                                        {{ detail.billingMethod }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        label="付款方式:"
                                        label-width="9em"
                                    >
                                        {{ detail.paymentMethod }}
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item
                                        label="开票名称:"
                                        label-width="9em"
                                    >
                                        <div
                                            class="editor-content"
                                            v-html="detail.billingName"
                                        />
                                    </el-form-item>
                                </el-col>
                            </div>
                        </el-card>
                    </el-form>
                    <div class="pa-a ta-c">
                        <el-button
                            size="small"
                            type="primary"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </div>
                </el-col>
            </el-row>
        </template>
    </page>
</template>

<script>
export default {
    name: 'RegisterFormView',
    data() {
        return {
            id: '',
            detail: {},
        };
    },
    methods: {
        init() {
            this.$api.Ex.RegisterForm.getDetail({ id: this.id }).then(json => {
                const res = json.data;
                this.detail = res.data ? res.data : {};
                if (this.detail) {
                    if (this.detail.billingName) {
                        this.detail.billingName = this.detail.billingName.replace(/\n/g, '<br>');
                    }
                }
            });
        },
    },
    created() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
