<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="departmentId"
                    label="组织机构"
                >
                    <CascaderPicker
                        check-strictly
                        class="dp-b"
                        :api-class="selectApi"
                        v-model="queryFormModel.departmentId"
                    />
                </el-form-item>
                <el-form-item
                    prop="employeeSn"
                    label="员工编码"
                >
                    <el-input
                        v-model="queryFormModel.employeeSn"
                        placeholder="请输入员工编码"
                    />
                </el-form-item>
                <el-form-item
                    prop="employeeName"
                    label="员工姓名"
                >
                    <el-input
                        v-model="queryFormModel.employeeName"
                        placeholder="请输入员工姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="employeeType"
                    label="员工类型"
                >
                    <dictionaries-picker
                        type="employeeType"
                        v-model="queryFormModel.employeeType"
                    />
                </el-form-item>
                <el-form-item
                    prop="employeeStatus"
                    label="员工状态"
                >
                    <dictionaries-picker
                        type="employeeStatus"
                        v-model="queryFormModel.employeeStatus"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        新增
                    </el-button>
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="small"
                        @click="onDelete"
                        :disabled="!currentTableSelect.length"
                    >
                        删除
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="39"
                />
                <el-table-column
                    prop="orgName"
                    label="组织机构"
                    min-width="100"
                />
                <el-table-column
                    prop="employeeSn"
                    label="员工编码"
                    min-width="100"
                />
                <el-table-column
                    prop="employeeName"
                    label="员工姓名"
                    min-width="100"
                />
                <el-table-column
                    prop="genderName"
                    label="员工性别"
                    min-width="100"
                />
                <el-table-column
                    prop="employeeTel"
                    label="联系电话"
                    min-width="100"
                />
                <el-table-column
                    prop="employeeTypeName"
                    label="员工类型"
                    min-width="100"
                />
                <el-table-column
                    prop="employeeStatusName"
                    label="员工状态"
                    min-width="100"
                />
                <el-table-column
                    prop="remarks"
                    label="备注"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="900px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="departmentId"
                    label="组织机构"
                    label-width="8em"
                >
                    <CascaderPicker
                        class="dp-b"
                        :api-class="selectApi"
                        v-model="addDialog.formModel.departmentId"
                    />
                </el-form-item>
                <el-form-item
                    prop="employeeSn"
                    label="员工编码"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.employeeSn"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="employeeName"
                    label="员工姓名"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.employeeName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="employeeGender"
                    label="员工性别"
                    label-width="8em"
                >
                    <dictionaries-picker
                        type="gender"
                        v-model="addDialog.formModel.employeeGender"
                    />
                </el-form-item>
                <el-form-item
                    prop="employeeTel"
                    label="联系电话"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.employeeTel"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="employeeType"
                    label="员工类型"
                    label-width="8em"
                >
                    <dictionaries-picker
                        type="employeeType"
                        v-model="addDialog.formModel.employeeType"
                    />
                </el-form-item>
                <el-form-item
                    prop="employeeStatus"
                    label="员工状态"
                    label-width="8em"
                >
                    <dictionaries-picker
                        type="employeeStatus"
                        v-model="addDialog.formModel.employeeStatus"
                    />
                </el-form-item>
                <el-form-item
                    prop="remarks"
                    label="备注"
                    label-width="8em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.remarks"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    label="巡检区域"
                    label-width="8em"
                >
                    <div
                        class="ma-b"
                    >
                        <el-button-group>
                            <el-button
                                type="primary"
                                size="small"
                                @click="onSelectParkingArea()"
                            >
                                添加
                            </el-button>
                        </el-button-group>
                    </div>

                    <!-- 表格 -->
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="inspectorAreaList"
                    >
                        <el-table-column
                            fixed="left"
                            label="操作"
                            width="45"
                        >
                            <template slot-scope="scope">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="onParkingAreaDelete(scope.row)"
                                >
                                    移除
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="parkingSn"
                            label="停车场编号"
                            min-width="100"
                        />
                        <el-table-column
                            prop="parkingName"
                            label="停车场名称"
                            min-width="100"
                        />
                        <el-table-column
                            prop="areaSn"
                            label="停车场分区编号"
                            min-width="100"
                        />
                        <el-table-column
                            prop="areaName"
                            label="停车场分区名称"
                            min-width="100"
                        />
                    </el-table>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm()"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!--选择巡检区域-->
        <el-dialog
            title="选择巡检区域"
            center
            width="600px"
            :visible.sync="selectParkingAreaDialog.isVisible"
            @closed="onSelectParkingAreaCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectParkingAreaDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectParkingAreaDialog.queryFormModel"
            >
                <el-form-item
                    prop="parkingId"
                    label="停车场"
                >
                    <RemoteSelect
                        filterable
                        clearable
                        remote="/sv/servantParking/select"
                        placeholder="请选择停车场"
                        :props="{id:'id',name:'parkingName'}"
                        v-model="selectParkingAreaDialog.queryFormModel.parkingId"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectParkingAreaDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectParkingAreaDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectParkingAreaDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="parkingAreaId"
                    ref="selectParkingAreaDialogTable"
                    @selection-change="onSelectParkingAreaSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        :reserve-selection="true"
                        width="36"
                    />
                    <el-table-column
                        prop="parkingSn"
                        label="停车场编号"
                        min-width="100"
                    />
                    <el-table-column
                        prop="parkingName"
                        label="停车场名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="areaSn"
                        label="停车场分区编号"
                        min-width="100"
                    />
                    <el-table-column
                        prop="areaName"
                        label="停车场分区名称"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectParkingAreaDialog.pagination"
                        @input="onSelectParkingAreaDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectParkingAreaConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectParkingAreaCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Employee',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                departmentId: '', // 组织机构
                employeeSn: '', // 员工编码
                employeeName: '', // 员工姓名
                employeeType: '', // 员工类型
                employeeStatus: '', // 员工状态
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    departmentId: '',
                    employeeSn: '',
                    employeeName: '',
                    employeeGender: '',
                    employeeTel: '',
                    employeeType: '',
                    employeeStatus: '',
                    remarks: '',
                },
                // 表单校验规则
                formRules: {
                    departmentId: {
                        required: true,
                        message: '请选择组织机构',
                    },
                    employeeSn: [
                        {
                            required: true,
                            message: '请输入员工编码',
                            trigger: 'blur',
                        },
                        {
                            validator: this.validatorEmployeeSn,
                            message: '员工编码不能重复',
                            trigger: 'blur',
                        },
                    ],
                    employeeName: [
                        {
                            required: true,
                            message: '请输入员工姓名',
                            trigger: 'blur',
                        },
                        {
                            validator: this.validatorEmployeeName,
                            message: '员工姓名不能重复',
                            trigger: 'blur',
                        },
                    ],
                    employeeGender: {
                        required: true,
                        message: '请输入员工性别',
                    },
                    employeeType: {
                        required: true,
                        message: '请输入员工类型',
                    },
                    employeeStatus: {
                        required: true,
                        message: '请输入员工状态',
                    },
                },
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            // 组织机构
            selectApi: this.$api.Rs.Org,
            selectParkingAreaDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    parkingId: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            inspectorAreaList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Rs.Employee.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.inspectorAreaList = [];
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Rs.Employee.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res.employee);
                this.inspectorAreaList = res.inspectorAreaList || [];
            });
            this.addDialog.isVisible = true;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Rs.Employee.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Rs.Employee.save({
                    ...this.addDialog.formModel,
                    inspectorAreaJson: JSON.stringify(this.inspectorAreaList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        /**
         * 自定义校验
         * @param rule
         * @param value
         * @param callback
         */
        validatorEmployeeSn(rule, value, callback) {
            this.$api.Rs.Employee.exists({
                id: this.addDialog.formModel.id,
                departmentId: this.addDialog.formModel.departmentId,
                employeeSn: this.addDialog.formModel.employeeSn,
            }).then(res => {
                if (res.data.data) {
                    callback(rule.message);
                } else {
                    callback();
                }
            });
        },
        validatorEmployeeName(rule, value, callback) {
            this.$api.Rs.Employee.validatorEmployeeName({
                id: this.addDialog.formModel.id,
                departmentId: this.addDialog.formModel.departmentId,
                employeeName: this.addDialog.formModel.employeeName,
            }).then(res => {
                if (res.data.data) {
                    callback(rule.message);
                } else {
                    callback();
                }
            });
        },
        //选择巡检区域
        onSelectParkingArea() {
            this.selectParkingAreaDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectParkingAreaDialogQuery();

                // 初始化已选择数据
                if (this.$refs.selectParkingAreaDialogTable) {
                    this.$refs.selectParkingAreaDialogTable.clearSelection();
                }
                this.inspectorAreaList.forEach(item => {
                    this.$refs.selectParkingAreaDialogTable.toggleRowSelection(item, true);
                });
            });
        },
        onSelectParkingAreaDialogQuery(pagination) {
            return this.$api.Sv.ServantParkingArea.selectByPage({
                ...this.selectParkingAreaDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                res.data.forEach(item => {
                    item.parkingAreaId = item.id;
                    item.id = null;
                });
                this.selectParkingAreaDialog.tableData = res.data;
                this.selectParkingAreaDialog.pagination = res.pagination;
            });
        },
        onResetSelectParkingAreaDialog() {
            this.$refs.selectParkingAreaDialogQueryForm.resetFields();
        },
        onSelectParkingAreaConfirm() {
            const list = [];
            this.selectParkingAreaDialog.currentTableSelect.forEach(item => {
                let isHave = false;
                this.inspectorAreaList.forEach(oldItem => {
                    if (item.parkingAreaId === oldItem.parkingAreaId) {
                        list.push({ ...oldItem });
                        isHave = true;
                    }
                });
                if (!isHave) {
                    //添加新数据
                    list.push({ ...item });
                }
            });
            this.inspectorAreaList = [...list];
            this.selectParkingAreaDialog.isVisible = false;
        },
        onSelectParkingAreaCancel() {
            this.selectParkingAreaDialog.isVisible = false;
        },
        onParkingAreaDelete(row) {
            this.inspectorAreaList.splice(this.inspectorAreaList.indexOf(row), 1);
        },
        onSelectParkingAreaSelectionChange(val) {
            this.selectParkingAreaDialog.currentTableSelect = val;
        },
    },
};
</script>

<style lang="scss">
</style>
