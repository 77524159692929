/**
 * Created by henian.xu on 2018/7/18.
 *
 */

import { Pt } from './Pt';

class CmsArticleComment extends Pt {
    constructor() {
        super();
        this.baseUrl += '/cmsArticleComment';
    }

    data({ cmsArticleId, fullName, mobile, comments, currentPage, pageSize } = {}) {
        return super.get('/data', {
            cmsArticleId,
            fullName,
            mobile,
            comments,
            currentPage,
            pageSize,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}

export default new CmsArticleComment();
export { CmsArticleComment };
